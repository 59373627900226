// Get Total Areas by Consultant
export function areaRequestConsultant(id, filter) {
    return {
        type: '@area/AREA_CONSULTANT_REQUEST',
        payload: { id, filter },
    };
}

// Get NDVI
export function areaRequestNDVI(id) {
    return {
        type: '@area/AREA_REQUEST_NDVI',
        payload: { id },
    };
}

export function areaByIdNDVISuccess(ndvi) {
    return {
        type: '@area/AREA_BY_ID_NDVI_SUCCESS',
        payload: { ndvi },
    };
}

// Get Total Areas by Customer
export function areaRequestCustomer(id) {
    return {
        type: '@area/AREA_CUSTOMER_REQUEST',
        payload: { id },
    };
}

export function areaSuccess(data) {
    return {
        type: '@area/AREA_SUCCESS',
        payload: { data },
    };
}

export function areasMapStartRequest() {
    return {
        type: '@area/AREA_START_REQUEST_MAP',
    };
}

export function areasMapEndRequest() {
    return {
        type: '@area/AREA_END_REQUEST_MAP',
    };
}

export function areasMapRequest(id) {
    return {
        type: '@area/AREA_REQUEST_MAP',
        payload: { id },
    };
}

export function areasMapSuccess(data) {
    return {
        type: '@area/AREAS_MAP_SUCCESS',
        payload: { data },
    };
}

// Get By Id Area
export function areaById(id) {
    return {
        type: '@area/AREA_BY_ID',
        payload: { id },
    };
}

export function successPagedAreaByUserId(dataArea) {
    return {
        type: '@area/PAGED_AREA_BY_USER_SUCCESS',
        payload: { dataArea },
    };
}

// Pagination with filter
export function requestPagedAreaWithFilterByUserId(user_id, current_page, filter, ordination, newFilter) {
    return {
        type: '@area/PAGED_AREA_WITH_FILTER_BY_USER_REQUEST',
        payload: {
            user_id, filter, current_page, newFilter, ordination,
        },
    };
}

export function requestPagedAreaBHById(area_id, current_page, new_filter) {
    return {
        type: '@area/REQUEST_AREA_BH_BY_ID_REPORTS',
        payload: {
            area_id, current_page, new_filter,
        },
    };
}

export function setLoadingBHData(loading) {
    return {
        type: '@area/SET_LOADING_BH_DATA',
        payload: loading,
    };
}

export function areaBHReportsByIdSuccess(BHData) {
    return {
        type: '@area/AREA_BH_REPORTS_BY_ID_SUCCESS',
        payload: { BHData },
    };
}

export function setEmptyPagedBHReportsArea() {
    return {
        type: '@area/SET_PAGED_BH_REPORTS_AREA_STATES',
    };
}

export function successPagedAreaWithFilterByUserId(dataArea, newFilter, filter, ordination) {
    return {
        type: '@area/PAGED_AREA_WITH_FILTER_BY_USER_SUCCESS',
        payload: {
            dataArea, newFilter, filter, ordination,
        },
    };
}

// Pagination
export function requestPagedAreaByUserId(user_id, currentPage) {
    return {
        type: '@area/PAGED_AREA_BY_USER_REQUEST',
        payload: { user_id, currentPage },
    };
}

export function requestAreaSummaryByUserId(user_id, filter) {
    return {
        type: '@area/AREA_SUMMARY_BY_USER_ID',
        payload: { user_id, filter },
    };
}

export function successAreaSummaryByUserId(areas) {
    return {
        type: '@area/SUCCESS_AREA_SUMMARY_BY_USER_ID',
        payload: { areas },
    };
}

export function requestCustomerByUser(id) {
    return {
        type: '@area/REQUEST_CUSTOMER_BY_USER',
        payload: { id },
    };
}

export function successCustomerByUser(customers) {
    return {
        type: '@area/SUCCESS_CUSTOMER_BY_USER',
        payload: { customers },
    };
}

export function areaByIdBh(id) {
    return {
        type: '@area/AREA_BY_ID_BH',
        payload: { id },
    };
}

export function areaByIdAverage(id) {
    return {
        type: '@area/AREA_BY_ID_AVERAGE_TEMP_SINCE',
        payload: { id },
    };
}

export function areaByIdSuccess(area) {
    return {
        type: '@area/AREA_BY_ID_SUCCESS',
        payload: { area },
    };
}

export function areaByIdBHSuccess(dataBh) {
    return {
        type: '@area/AREA_BY_ID_BH_SUCCESS',
        payload: { dataBh },
    };
}

export function areaByIdAverageSuccess(averageTemp, averageTempSince) {
    return {
        type: '@area/AREA_BY_ID_AVERAGE_SUCCESS',
        payload: { averageTemp, averageTempSince },
    };
}

// Area Refresh
export function areaRefresh(id, userId) {
    return {
        type: '@area/AREA_REFRESH',
        payload: { id, userId },
    };
}

export function areaRefreshSuccess(area) {
    return {
        type: '@area/AREA_REFRESH_SUCCESS',
        payload: { area },
    };
}

// Archived Area
export function areaArchivedById(id, user, archived) {
    return {
        type: '@area/AREA_BY_ID_ARCHIVED',
        payload: { id, user, archived },
    };
}

// Remove Area
export function areaRemoveById(id, user) {
    return {
        type: '@area/AREA_REMOVE_BY_ID',
        payload: { id, user },
    };
}

export function areaRemoveSuccess(id) {
    return {
        type: '@area/AREA_REMOVE_BY_ID_SUCCESS',
        payload: { id },
    };
}

export function areaArchivedSuccess(id) {
    return {
        type: '@area/AREA_ARCHIVED_BY_ID_SUCCESS',
        payload: { id },
    };
}

// Filter Area
export function areaFilter(filter) {
    return {
        type: '@area/AREAS_FILTER',
        payload: { filter },
    };
}

// Defaults
export function areaStartRequest() {
    return {
        type: '@area/AREA_START_REQUEST',
    };
}

export function areaStartRequestById() {
    return {
        type: '@area/AREA_START_REQUEST_BY_ID',
    };
}

export function areaEndRequest() {
    return {
        type: '@area/AREA_END_REQUEST',
    };
}

export function areaByIdEndRequest() {
    return {
        type: '@area/AREA_BY_ID_END_REQUEST',
    };
}

export function clearDataArea() {
    return {
        type: '@area/CLEAR_DATA_AREA',
    };
}

export function areaError(message) {
    return {
        type: '@area/AREA_ERROR',
        payload: {
            message,
        },
    };
}

export function areaIrrigationAdd(rain, irrigation, _areaId, _createdBy, _createdAt) {
    return {
        type: '@area/AREA_IRRIGATION_ADD',
        payload: {
            rain,
            irrigation,
            _areaId,
            _createdBy,
            _createdAt,
        },
    };
}

export function areaIrrigationAddAreas(rain, irrigation, areas, _createdBy, _createdAt) {
    return {
        type: '@area/AREA_IRRIGATION_ADD_AREAS',
        payload: {
            rain,
            irrigation,
            areas,
            _createdBy,
            _createdAt,
        },
    };
}

export function areaIrrigationMessage(message) {
    return {
        type: '@area/AREA_IRRIGATION_MESSAGE',
        payload: { message },
    };
}

export function areaAdd(
    user,
    name,
    farm_id,
    soil,
    area_irrigation_system,
    area_crops,
    kl,
    ks,
    pas,
    pam,
    boundary,
    size,
    time_back_to_one_hundred_percent,
) {
    return {
        type: '@area/AREA_ADD',
        payload: {
            user,
            name,
            farm_id,
            soil,
            area_irrigation_system,
            area_crops,
            kl,
            ks,
            pas,
            pam,
            boundary,
            size,
            time_back_to_one_hundred_percent,
        },
    };
}

export function areaUpdate(
    user,
    id,
    name,
    farm_id,
    boundary,
    soil,
    area_irrigation_system,
    area_crops,
    kl,
    ks,
    pas,
    pam,
    size,
    time_back_to_one_hundred_percent,
) {
    return {
        type: '@area/AREA_UPDATE',
        payload: {
            user,
            id,
            name,
            farm_id,
            boundary,
            soil,
            area_irrigation_system,
            area_crops,
            kl,
            ks,
            pas,
            pam,
            size,
            time_back_to_one_hundred_percent,
        },
    };
}
export function areaAddSuccess(areaAdd) {
    return {
        type: '@area/AREA_ADD_SUCCESS',
        payload: { areaAdd },
    };
}

export function areaUpdateSuccess() {
    return {
        type: '@area/AREA_UPDATE_SUCCESS',
    };
}

export function requestCalculateBh(areaId, valueInitial, valueEnd) {
    return {
        type: '@area/REQUEST_CALCULATE_ABH',
        payload: { areaId, filters: { rangeDate: { start_date: valueInitial, end_date: valueEnd } } },
    };
}

export function requestCalculateBhSuccess(data) {
    return {
        type: '@area/REQUEST_CALCULATE_ABH_SUCCESS',
        payload: { data },
    };
}

export function requestCalculateBhError(message) {
    return {
        type: '@area/REQUEST_CALCULATE_ABH_ERROR',
        payload: { message },
    };
}

// Get Crops
export function cropsStartRequest() {
    return {
        type: '@area/AREA_CROPS_START_REQUEST',
    };
}

export function cropsRequestSuccess(data) {
    return {
        type: '@area/AREA_CROPS_REQUEST_SUCCESS',
        payload: {
            data,
        },
    };
}

export function cropsRequestError(message) {
    return {
        type: '@area/AREA_CROPS_REQUEST_ERROR',
        payload: {
            message,
        },
    };
}

export function cropsEndRequest() {
    return {
        type: '@area/AREA_CROPS_END_REQUEST',
    };
}
