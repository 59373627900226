import {
    all, takeLatest, call, put,
} from 'redux-saga/effects';
import {
    parametersError, parametersSuccess,
} from './actions';
import {
    requestParameters,
} from './utils';

export function* request() {
    const res = yield call(requestParameters);
    if (!res.ok) {
        yield put(parametersError(res.response.errors[0].message));
    } else {
        const { parameters } = res;
        yield put(parametersSuccess(parameters));
    }
}

export default all([
    takeLatest('@parameters/PARAMETERS_LIST_REQUEST', request),
]);
