import produce from 'immer';

const INITIAL_STATE = {
    data: [],
    message: null,
    loading: false,
};

export default function parameters(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@parameters/PARAMETERS_LIST_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
        });
    case '@parameters/PARAMETERS_LIST_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.data = action.payload.data;
        });
    case '@parameters/PARAMETERS_LIST_ERROR':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.message = action.payload.message;
        });
    default:
        return state;
    }
}
