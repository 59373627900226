import React from 'react';
import TableReport from '../../components/TableReport';
import Layout from '../../components/Layout';
import { WrapperGraphs } from './styles';

const Reports = () => (
    <Layout>
        <WrapperGraphs>
            <TableReport />
        </WrapperGraphs>
    </Layout>
);

export default Reports;
