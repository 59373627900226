import { FRAGMENT_GET_AREA_SUMMARY } from '../../fragments/area';

const GET_AREA_SUMMARY = /* GraphQL */ `
query GET_AREA_SUMMARY_BY_USER_ID($id:ID, $filters:UserFilterInput){
    users(id:$id, filters:$filters){
      areas {
        ${FRAGMENT_GET_AREA_SUMMARY}
      }
  }
}
`;

export default GET_AREA_SUMMARY;
