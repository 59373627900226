import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display:grid;
    grid-template-columns:1fr;
    padding:${theme.spacings.xsmall};
    column-gap:3rem;
    width:fit-content;
    background:#eee;
    margin-right:${theme.spacings.xsmall};

    @media (max-width: 600px) {
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 2rem;
      width: 100%;
    }
  `}
`;

export const Label = styled.p`
    ${({ theme }) => css`
        display:flex;
        font-size:${theme.font.sizes.xxsmall};
        margin-bottom:${theme.spacings.xxsmall};
        flex-direction:column;
        font-weight:bold;
        color:${theme.colors.black};

        &.irrigation {
          color: #32b0f4;
          & span { color: #32b0f4 !important; }
      }
    `}
`;

export const Value = styled.span`
    ${({ theme }) => css`
        color:${theme.colors.green};
        font-size:${theme.font.sizes.xxsmall};
        font-weight:bold;
    `}
`;
