import styled, { css } from 'styled-components';

export const ListArea = styled.div`
    ${({ theme }) => css`
        width: 100%;
        height: max-content;
        padding:${theme.spacings.xsmall};
        display:grid;
        gap: ${theme.spacings.medium};
        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    `}
`;
