import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        background:${theme.colors.white};
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
        padding:${theme.spacings.small};
        border-radius:${theme.border.radius};
    `}
`;

export const Header = styled.div`
    ${({ theme }) => css`
        display:flex;
        flex-direction:row;
        align-items:center;
        padding:${theme.spacings.small} 0;
        margin-top:-5rem;
        img {
            width: 80px;
            height: 80px;
            box-shadow:2px 2px 5px rgba(0,89,107,0.5);
            margin-top:-2rem;
            background:${theme.colors.primary};
            padding:${theme.spacings.small};
            border-radius:${theme.border.radius};
        }
    `}
`;

export const Title = styled.p`
    ${({ theme }) => css`
        margin-left: ${theme.spacings.small};
        color:${theme.colors.gray};
        font-size:${theme.font.sizes.medium};
    `}
`;

export const Body = styled.div`
    @media (max-width: 600px) {
        overflow-x: auto;
    }
    
`;
