import { FRAGMENT_GET_USER_FARMS } from '../../fragments/farms';

const GET_USER_FARMS = /* GraphQL */ `
    query GET_USER_FARMS($userId:ID!){
        users(id:$userId){
            ${FRAGMENT_GET_USER_FARMS}
        }
    }
`;

export default GET_USER_FARMS;
