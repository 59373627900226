import moment from 'moment';

export const lastDays = (days, future = false, test = false) => {
    const dates = [];

    let dateEnd;

    if (future) {
        dateEnd = moment().add(7, 'days');
    } else {
        dateEnd = moment();
    }

    const dateStart = moment(dateEnd).subtract(days, 'days');

    while (dateStart.format('DD/MM') !== dateEnd.format('DD/MM')) {
        if (test) { dates.push([dateStart.format('DD/MM'), 'test']); } else { dates.push(dateStart.format('DD/MM')); }
        dateStart.add(1, 'days');
    }

    return dates;
};

export const lastDaysMap = (days, future = false) => {
    const dates = {};

    let dateEnd;

    if (future) {
        dateEnd = moment().add(7, 'days');
    } else {
        dateEnd = moment();
    }

    const dateStart = moment(dateEnd).subtract(days, 'days');

    while (dateStart.format('DD/MM') !== dateEnd.format('DD/MM')) {
        dates[dateStart.format('DD/MM')] = dateStart.format('DD/MM/YYYY');
        dateStart.add(1, 'days');
    }

    return dates;
};
