import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    background:#fff;
    color: black;   
    padding:2rem;
    width:100%;

    .MuiDataGrid-root {
        font-weight: 400;

        .MuiDataGrid-main {
            font-size: 1.8rem;
            color: black;
            border-color: #e0e0e0 !important;
            letter-spacing: normal;

            .MuiDataGrid-columnsContainer {
                min-height: 84px !important;
                max-height: 84px !important;
                line-height: 83px !important;
                border-radius: 4px;

                .MuiDataGrid-columnHeaderWrapper {
                    min-height: 84px !important;
                    max-height: 84px !important;
                    line-height: 83px !important;
                    background: #fafafa;

                    .MuiDataGrid-columnHeaderTitle {
                        font-weight: bold;
                    }
                }
            }

            .MuiDataGrid-window {
                top: 84px !important;

                .MuiDataGrid-row {

                    :nth-child(n+5) {
                        background-color: #EBF2F3;
                    }

                    :nth-child(n+8) {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .MuiButton-root {
        font-size: 1.4rem;
        padding: 20px;
    }

    .MuiInputBase-input {
        font-size: 1.8rem;
    }
`;

export const Separator = styled.div`
    ${({ theme }) => css`
        margin:${theme.spacings.large} 0;
    
    `}
`;

export const Grid = styled.div`
    ${({ theme }) => css`
        display:grid;
        grid-template-columns: 1.2fr 1fr;
        gap:${theme.spacings.large};
    `}
`;

export const Footer = styled.div`
    ${({ theme, add }) => css`
        display:flex;
        justify-content:flex-end;
        padding:${theme.spacings.medium};

        .add {
            background:${'transparent'};
            color:${theme.colors.primary};

        }
        button {
            background:${add ? 'transparent' : theme.colors.primary};
            border-radius:${theme.border.radius};
            padding:${theme.spacings.xsmall} ${theme.spacings.medium};
            color:${add ? theme.colors.primary : theme.colors.white};
            outline:none;
        }
    `}
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1280px;
    margin-inline: auto;
`;

export const ContainerGraphs = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: ${theme.spacings.small};
        margin-bottom: ${theme.spacings.xxlarge};
        
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        label {
            color: #000;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 21px;
            display: flex;
            align-self: center;
        }

        @media screen and (max-width: 600px) {
            display: block;
        }
    `}
`;

export const Table = styled.table`
   ${({ theme }) => css`
        border-spacing: 0;
        border: 1px solid ${theme.colors.lightGray};
        font-size: ${theme.font.sizes.xxsmall};
        color: ${theme.colors.gray};
        width: 100%;
        
        th { font-size: ${theme.font.sizes.xsmall}; }
        th, td {
            padding: .6rem 1rem;
            height: 52px;
            vertical-align: middle;
            text-align: left;
            border-bottom: 1px solid ${theme.colors.lightGray};
        }

        & .id-container {
            display: flex;
            align-items: center;
            width: 54px !important;
        }

        & .actions-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: 120px !important; 
            max-width: 120px !important; 
            width: 120px !important;

            & > .action {
                height: 24px !important;
                margin: 4px;

                &.add { background-color: ${theme.colors.green}; }
                &.person { background-color: ${theme.colors.primary}; }
                &.delete { background-color: ${theme.colors.danger}; }
            }
        }
    `}
`;

export const ItemList = styled.div`
    width: 100%;
    min-width: 490px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    max-width: 1280px;

    @media screen and (max-width: 600px) {
        font-size: 1.4rem;
    }
`;

export const Items = styled.div`
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 56px;
    line-height: 56px;
    margin-inline: auto;

    :first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    :last-child {
        border: 0;
    }
`;

export const ItemDetails = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px; 
`;

export const Item = styled.div`
    padding-inline: 5px;
    width: 20%;
    min-width: 93px;
    text-align: center;
    line-height: 1.43;

    :nth-child(1) {
        width: 30px;
        min-width: 30px;
    }

    :nth-child(6) {
        width: 80px;
        min-width: 80px;

        @media screen and (max-width: 600px) {
            width: 60px;
            min-width: 60px;
        }
    }
`;

export const ItemEdit = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;

    @media screen and (max-width: 600px) {
        width: 30px;
    }
`;

export const ContainerTable = styled.div`
    height: 600px;
    width: 100%;
    max-width: 1280px;
    margin-inline: auto;
`;

export const Button = styled.button`
    ${({ theme }) => css`
        outline:none;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:${theme.border.radius};
        padding:${theme.spacings.xsmall};
        background-color:${theme.colors.primary};
        margin-bottom:${theme.spacings.small};
        color:${theme.colors.white};
        font-size:${theme.font.sizes.xxsmall};

        &:hover {
            transition:.5s;
            opacity: 0.8;
        }
    `}
`;

export const TimeDurationForm = styled.form`
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 100%;

    font-size: 1.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    letter-spacing: normal;

    input {
        width: 22px;
        height: 100%;

        appearance: none !important;
        background-color: #EBF2F3;
        font-size: 1.8rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        letter-spacing: normal;
        margin-top: -0.5px;
        margin-right: 0.5px;
        text-align: right;

        :first-child {
            width: auto;
        }

        :last-child {
            ${({ minutesLength }) => css`
                width:${minutesLength}px;
            
            `}
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
`;
