import React from 'react';
import { Wrapper, Container, PWAButton } from './styles';
import Menu from '../Menu';

function Layout({ showInstallPwaButton, installApp, children }) {
    return (
        <Wrapper>
            {showInstallPwaButton && (
                <PWAButton type="button" onClick={() => installApp()}>Clique aqui e instale o app</PWAButton>
            )}
            <Menu />
            <Container>
                {children}
            </Container>
        </Wrapper>
    );
}

export default Layout;
