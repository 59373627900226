import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Wrapper, Container } from './styles';

const ContainerTableSkeleton = () => (
    <Wrapper>
        <Container>
            <Skeleton height={600} />
        </Container>
    </Wrapper>
);

export default ContainerTableSkeleton;
