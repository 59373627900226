import client from '../../../graphql/client';
import GET_AREA_PARAMETERS from '../../../graphql/queries/parameters/getAreaParametersById';
import ADD_PARAMETERS from '../../../graphql/mutations/parameters/addParameters';
import REMOVE_PARAMETERS from '../../../graphql/mutations/parameters/removeParameters';

import { handleSuccess, handleError } from '../../../utils/functions';

export async function requestAreaParameters(payload) {
    const { payload: { id } } = payload;
    try {
        const data = await client.request(GET_AREA_PARAMETERS, { id: parseInt(id) });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestParameterAdd({ payload }) {
    const { area_ids, parameters } = payload;
    try {
        const data = await client.request(ADD_PARAMETERS, { fields: { area_ids, parameters } });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestParameterRemove({ payload }) {
    const { id } = payload;
    try {
        const data = await client.request(REMOVE_PARAMETERS, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}
