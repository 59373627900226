import produce from 'immer';

const INITIAL_STATE = {
    data: [],
    weatherData: [],
    employees: null,
    message: null,
    loading: false,
    userFarms: [],
};

export default function farm(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@farm/FARMS_CONSULTANT_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
        });
    case '@farm/FARMS_CUSTOMER_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
        });
    case '@farm/FARMS_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.data = action.payload.data;
        });
    case '@farm/FARMS_ERROR':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.message = action.payload.message;
        });
    case '@farm/FARMS_DISABLE':
        return produce(state, (draft) => {
            draft.loading = true;
        });

    case '@farm/FARM_REQUEST_EMPLOYEES_SUCCESS':
        return produce(state, (draft) => {
            draft.employees = action.payload.employees;
            draft.loading = false;
        });
    case '@farm/FARM_REQUEST_EMPLOYEES_ERROR':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.employees = null;
            draft.message = action.payload.message;
        });
    case '@farm/FARM_ADD':
    case '@farm/FARM_UPDATE':
        return produce(state, (draft) => {
            draft.loading = true;
        });
    case '@farm/FARM_ADD_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
        });

    case '@farm/FARM_GET_WEATHER':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.weatherData = action.payload;
        });

    case '@farm/SET_EMPTY_WEATHER_DATA':
        return produce(state, (draft) => {
            draft.weatherData = [];
        });

    case '@farm/GET_USER_FARMS_BY_ID':
        return produce(state, (draft) => {
            draft.userFarms = action.payload;
        });

    default:
        return state;
    }
}
