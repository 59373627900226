import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Map, Marker, Polygon } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useSelector } from 'react-redux';

import * as L from 'leaflet';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import * as Convert from 'xml-js';

import ReactLeafletKml from 'react-leaflet-kml';
import { checkForHarvest } from '../../../utils/helpers/tools';
import Control from './Control';
import CustomMarker from './CustomMarker';
import IconSvg from '../../IconSvg';

import Geo from '../../../utils/helpers/geo';

import * as Styles from './styles';
import 'leaflet/dist/leaflet.css';

import iconUpload from '../../../assets/svgs/icon_upload.svg';
import iconMove from '../../../assets/svgs/icon_move.svg';
import iconDelete from '../../../assets/svgs/icon_delete.svg';
import IconClose from '../../../assets/svgs/icon_close.svg';
import SubtitleHumidity from './Subtitles/Humidity';

const ModalMap = ({
    valueSuccess,
    mapSuccess,
    hasMainPage = false,
    sendDataMap,
    styleMap,
    customHeight = 450,
    showOne = false,
    createArea = false,
    showControl = true,
    imageNDVI = null,
    handleSetImageNDVI = null,
    closedNDVI = false,
}) => {
    const editingStart = useRef(0);
    const area = useSelector((state) => state.area);
    const { dataArea } = useSelector((state) => state.area);
    const modal = useSelector((state) => state.modal);

    const customMarker = L.icon({
        iconUrl: require('../../../assets/images/icon_marker.png'),
        iconAnchor: [10.0, 20.5],
    });

    const [hasGeoLocation, setHasGeoLocation] = useState(false);
    const [hideLegend, setHideLegend] = useState(false);
    const mapRef = useRef(null);
    const [markersByArea, setMarkersByArea] = useState([]);
    const [showAlert, setShowAlert] = useState(true);
    const [success, setSuccess] = useState(valueSuccess);
    const [initialCoords, setInitialCoords] = useState([51.505, -0.09]);
    const [markers, setMarkers] = useState([]);
    const [polygonArea, setPolygonArea] = useState(0);
    const [centerMarker, setCenterMarker] = useState(null);
    const [box, setBox] = useState([]);
    const [extendedButtonUpload, setExtendedButtonUpload] = useState(false);
    const [auxFileaccepted, setAuxFileAccepted] = useState(false);
    const [modalUpload, setModalUpload] = useState(false);
    const [imageLayer, setImageLayer] = useState(false);

    const [mapConf, setMapConf] = useState({
        zoom: hasMainPage ? 10 : 15,
        center: [51.505, -0.09],
        zoomControl: true,
        doubleClickZoom: false,
    });

    const dataSend = {
        boundary: markers,
        square: box,
        center: centerMarker,
        size: polygonArea,
    };

    useEffect(() => {
        const map = mapRef.current.leafletElement;
        // Adicionar um NDVI e remover um existente
        if (imageNDVI?.show && imageNDVI?.coords.length && !closedNDVI) {
            const bounds = [[imageNDVI.coords[0].latitude, imageNDVI.coords[0].longitude], [imageNDVI.coords[1].latitude, imageNDVI.coords[1].longitude]];
            const image = L.imageOverlay(imageNDVI.image.url, bounds);
            if (imageLayer) {
                map.removeLayer(imageLayer);
            }
            image.addTo(map);
            setImageLayer(image);
        }

        // Remove NDVI e reseta dados
        if (!imageNDVI?.show && imageNDVI?.coords.length && !closedNDVI) {
            map.removeLayer(imageLayer);
            handleSetImageNDVI({
                show: false,
                coords: [],
                image: null,
            });
            setImageLayer(null);
        }

        // Remove NDVI ao clicar em Remover NDVI e resetar dados
        if (closedNDVI && imageLayer) {
            map.removeLayer(imageLayer);
            handleSetImageNDVI({
                show: false,
                coords: [],
                image: null,
            });
            setImageLayer(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageNDVI, closedNDVI]);

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    }, [showAlert]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            setInitialCoords([coords.latitude, coords.longitude]);
            setHasGeoLocation(true);
        });
    }, []);

    const polygonColor = useCallback((atd = null, cadRoot) => {
        const ninetyPercent = cadRoot * (90 / 100);
        const seventyFivePercent = cadRoot * (75 / 100);
        const fiftyPercent = cadRoot * (50 / 100);

        if (atd == null || atd === undefined) return '#444';

        if (atd > ninetyPercent) return '#62EDFF';

        if (atd >= seventyFivePercent && atd <= ninetyPercent) return '#55F15C';

        if (atd >= fiftyPercent && atd <= seventyFivePercent) return 'yellow';

        if (atd < fiftyPercent) return '#F44336';

        return '#444';
    }, []);

    // Center the map and draw the markers according to the area's coordinates.
    const handleEditMap = useCallback(() => {
        const { boundary } = dataArea;
        const coordinate = boundary?.data?.map((coordinates) => ({
            latitude: Number(coordinates.latitude),
            longitude: Number(coordinates.longitude),
            color: polygonColor(dataArea?.bh_today?.ATD, dataArea?.bh_today?.cadRoot),
        }));

        // eslint-disable-next-line no-unused-expressions
        if (coordinate) {
            setMarkers([...coordinate]);
            setCenterMarker(Geo.getPolygonCenter([...coordinate]));
            setBox(Geo.getBoudingBox([...coordinate]));
            setInitialCoords([coordinate[0].latitude, coordinate[0].longitude]);
            setPolygonArea(Geo.getPolygonArea([...coordinate]));
            setSuccess(true);
        }
    }, [dataArea, polygonColor]);

    useEffect(() => {
        if ((showOne || modal.props.isEdit) && !hasMainPage) {
            const areasById = [];

            // Responsible for assembling the array that draws the polygons of the areas with the pop-up (except the one being edited).
            area.dataMap.forEach((areas) => {
                if (areas.id !== dataArea.id) {
                    areasById.push({
                        id: areas.id,
                        data: areas.boundary.data,
                        name: areas.name,
                        harvestYear: checkForHarvest(areas.area_crops.sowing_date),
                        sowingDays: areas.area_crops.sowing_days,
                        memoSowingDays: moment(areas.area_crops.sowing_date).format('DD/MM/YYYY'),
                        cycleDays: areas.area_crops.cycle_days,
                        sizeArea: `≅ ${(Number(areas.size) * 100).toFixed(2)} ha`,
                        memoIrrigation: `${areas?.bh_today?.complementarWater.toFixed(3)} mm`,
                        cultureType: areas.area_crops?.crop?.culture_name,
                        soilType: areas.soil.soil_type.name,
                        color: areas?.bh_today == null ? 'gray' : polygonColor(areas?.bh_today?.ATD, areas?.bh_today?.cadRoot),
                    });
                }
            });
            setMarkersByArea(areasById);
            handleEditMap();
        } else {
            const areasById = [];

            area.dataMap.forEach((areas) => areasById.push({
                id: areas.id,
                active: areas.active,
                data: areas.boundary.data,
                name: areas.name,
                harvestYear: checkForHarvest(areas.area_crops.sowing_date),
                sowingDays: areas.area_crops.sowing_days,
                memoSowingDays: moment(areas.area_crops.sowing_date).format('DD/MM/YYYY'),
                cycleDays: areas.area_crops.cycle_days,
                sizeArea: `≅ ${(Number(areas.size) * 100).toFixed(2)} ha`,
                memoIrrigation: `${areas?.bh_today?.complementarWater.toFixed(3)} mm`,
                cultureType: areas.area_crops?.crop?.culture_name,
                soilType: areas.soil.soil_type.name,
                color: areas?.bh_today == null ? 'gray' : polygonColor(areas?.bh_today?.ATD, areas?.bh_today?.cadRoot),
            }));
            setMarkersByArea(areasById.filter((areas) => {
                if (createArea) return areas;
                return areas.active && areas;
            }));

            if (areasById.length) {
                const firstArea = areasById[0];

                if (firstArea !== undefined && !hasGeoLocation) {
                    setInitialCoords([
                        firstArea?.data[0]?.latitude,
                        firstArea?.data[0]?.longitude,
                    ]);

                    setMapConf({
                        ...mapConf,
                        zoom: 15,
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [area.dataMap, dataArea, modal.props.isEdit, showOne, hasMainPage, handleEditMap, hasGeoLocation]);

    useEffect(() => {
        if (!modal.props.isEdit) {
            navigator.geolocation.getCurrentPosition(({ coords }) => {
                setInitialCoords([coords.latitude, coords.longitude]);
            });
        }
    }, [modal.props.isEdit]);

    const handleClearKml = () => {
        setAuxFileAccepted(false);
        setMarkers([]);
        setBox([]);
        setCenterMarker([]);
        setPolygonArea([]);
        mapSuccess(false);
    };

    const handleFileReader = useCallback((file) => {
        const reader = new FileReader();
        reader.readAsText(file[0]);
        reader.onload = () => {
            const res = reader.result;
            const json = JSON.parse(Convert.xml2json(res, { compact: true, spaces: 10 }));

            let coordinatesJSON = json.kml?.Document?.Folder?.Placemark?.Polygon?.outerBoundaryIs?.LinearRing?.coordinates?._text;
            if (!coordinatesJSON) { coordinatesJSON = json.kml?.Document?.Placemark?.Polygon?.outerBoundaryIs?.LinearRing?.coordinates?._text; }
            if (!coordinatesJSON) {
                Swal.fire('Ops!', 'Erro ao ler o arquivo', 'error');
                return false;
            }

            const result = coordinatesJSON.split(',0').filter((text) => text && text.trim());
            const coordinate = result.map((polygon) => {
                const [long, lat] = polygon.split(',');
                return {
                    latitude: Number(lat),
                    longitude: Number(long.trim()),
                };
            });

            setMarkers([...markers, ...coordinate]);
            setCenterMarker(Geo.getPolygonCenter([...markers, ...coordinate]));
            setBox(Geo.getBoudingBox([...markers, ...coordinate]));
            setInitialCoords([coordinate[0].latitude, coordinate[0].longitude]);
            setPolygonArea(Geo.getPolygonArea([...markers, ...coordinate]));
            setSuccess(true);

            return true;
        };
    }, [markers]);

    const onDrop = useCallback((acceptedFiles) => {
        if (!acceptedFiles.length) {
            Swal.fire('Ops!', 'Somente é permitido upload de um arquivo.', 'error');
        } else if (!acceptedFiles[0].name.endsWith('.kml')) {
            Swal.fire('Ops!', 'Somente arquivo com extensão kml é permitido.', 'error');
        } else {
            handleFileReader(acceptedFiles);
            setAuxFileAccepted(acceptedFiles);
            setModalUpload(false);
        }
    }, [handleFileReader]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 });

    const handleNext = useCallback(() => {
        mapSuccess(true);
        sendDataMap(dataSend);
        setSuccess(false);
    }, [mapSuccess, sendDataMap, dataSend]);

    useEffect(() => {
        if (markers.length >= 3 && box.length && centerMarker != null && polygonArea > 0 && success) {
            handleNext();
        }
    }, [markers, box, centerMarker, polygonArea, success, handleNext]);

    const handleSearchGeo = () => {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            setInitialCoords([coords.latitude, coords.longitude]);
        });
    };

    const memoSizeArea = useMemo(() => {
        const size = Math.round((polygonArea * 100) * 100) / 100;
        return size;
    }, [polygonArea]);

    useEffect(() => {
        if (markers.length >= 3 && (memoSizeArea < 1 || memoSizeArea > 250)) {
            handleClearKml();
            setSuccess(false);
            mapSuccess(false);
            Swal.fire('Ops!', 'A área deve ter um tamanho minimo de 1 hectare e máximo de 250 hectares', 'error');
            return;
        }
        setSuccess(true);
        // eslint-disable-next-line
    }, [memoSizeArea]);

    const addPosition = useCallback((e, index = -1) => {
        if (hasMainPage || showOne) {
            return;
        }
        let coordinate;
        const tempMarkers = markers.slice(0);

        if (editingStart.current > Date.now()) return;
        editingStart.current = Date.now() + 300;

        if (index !== -1) {
            coordinate = {
                latitude: e.target.getLatLng().lat,
                longitude: e.target.getLatLng().lng,
            };
            tempMarkers[index] = coordinate;
            setPolygonArea(Geo.getPolygonArea(tempMarkers));
            setMarkers(tempMarkers);
            if (tempMarkers.length >= 3) {
                setCenterMarker(Geo.getPolygonCenter(tempMarkers));
                setBox(Geo.getBoudingBox(tempMarkers));
            }
        } else {
            coordinate = {
                latitude: e.latlng.lat,
                longitude: e.latlng.lng,
            };
            setPolygonArea(Geo.getPolygonArea([...tempMarkers, coordinate]));
            setMarkers([...tempMarkers, coordinate]);
            if ([...tempMarkers, coordinate].length >= 3) {
                setCenterMarker(Geo.getPolygonCenter([...tempMarkers, coordinate]));
                setBox(Geo.getBoudingBox([...tempMarkers, coordinate]));
            }
        }
    }, [markers, hasMainPage, showOne]);

    const undoMarker = useCallback(() => {
        if (markers.length === 0) {
            setPolygonArea(0);
            return;
        }

        setMarkers(markers.slice(0, markers.length - 1));
        setPolygonArea(Geo.getPolygonArea(markers.slice(0, markers.length - 1)));

        if (markers.length <= 3) {
            setSuccess(false);
            mapSuccess(false);
        }
        setSuccess(true);
    }, [markers, mapSuccess]);

    return (
        <Styles.MapContainer customHeight={customHeight} hasMainPage={hasMainPage}>
            <Map
                style={{
                    height: customHeight, width: '100%', marginBottom: 50, zIndex: 99, ...styleMap,
                }}
                {...mapConf}
                ref={mapRef}
                center={initialCoords}
                tap={false}
                onClick={() => setHideLegend(true)}
                ondblclick={(e) => addPosition(e, -1)}
            >
                {
                    // REACT_APP_MAP_KEY
                }
                <ReactLeafletGoogleLayer
                    googleMapsLoaderConf={{ KEY: 'AIzaSyB1KOV3VNfAcWyOPKpSvgxZ1JXunGZ_Oo4' }}
                    type="hybrid"
                />

                <ReactLeafletKml />

                <SubtitleHumidity value={hideLegend} handleLegend={(value) => setHideLegend(value)} />

                {
                    !showOne && markers.map((marker, index) => {
                        const _marker = [marker.latitude, marker.longitude];
                        return (
                            <Marker
                                key={index}
                                draggable={!showOne}
                                icon={customMarker}
                                ondragend={(e) => addPosition(e, index)}
                                position={_marker}
                            />
                        );
                    })
                }
                {!hasMainPage
                    ? (
                        <>
                            {!imageNDVI?.show
                                && (
                                    <Polygon
                                        positions={markers.map((marker) => [marker.latitude, marker.longitude])}
                                        fillOpacity={0.6}
                                        color={markers.length ? markers[0].color : 'yellow'}
                                    />
                                )}
                            {!imageNDVI?.show && markersByArea
                                .map((marker) => <CustomMarker key={marker.id} data={marker} handlePivot />)}

                            {imageNDVI?.show
                                && (
                                    <>
                                        <Polygon
                                            positions={imageNDVI?.coords?.map((coord) => [coord.latitude, coord.longitude])}
                                            fillOpacity={0.6}
                                            color="transparent"
                                        />

                                    </>
                                )}
                        </>
                    )
                    // Logic for main page
                    : markersByArea.map((marker) => <CustomMarker key={marker.id} data={marker} handlePivot />)}

                <Styles.Container
                    showAlert={showAlert && !hasMainPage && !showOne && !area.loadingMap}
                >
                    <span>Para selecionar uma região clique duas vezes sobre o mapa.</span>
                </Styles.Container>

                <Styles.Container
                    showAlert={area.loadingMap}
                >
                    <span>Carregando dados do mapa...</span>
                </Styles.Container>

                {!hasMainPage && showControl && (
                    <Control
                        sizeArea={`≈ ${memoSizeArea} ha`}
                        backClick={undoMarker}
                        nextClick={handleSearchGeo}
                    />
                )}
                {!hasMainPage && !auxFileaccepted && !showOne
                    && (
                        <Styles.ContainerButton
                            onClick={() => setModalUpload(true)}
                            onMouseOver={() => setExtendedButtonUpload(true)}
                            onMouseOut={() => setExtendedButtonUpload(false)}
                            className={extendedButtonUpload ? 'extended-button-upload' : ''}
                        >
                            <IconSvg svgPath={iconUpload} />
                            <p className="labels">Inserir arquivo</p>
                        </Styles.ContainerButton>
                    )}

                {auxFileaccepted
                    && (
                        <Styles.ContainerButton
                            className="extended-button-upload"
                        >
                            <p className="name-file">{auxFileaccepted[0].name}</p>
                            <div onClick={handleClearKml}>
                                <IconSvg svgPath={iconDelete} />
                            </div>
                        </Styles.ContainerButton>
                    )}
            </Map>
            {!hasMainPage && modalUpload
                && (

                    <Styles.Modal>
                        <Styles.Wrapper>
                            <Styles.Header>
                                <div onClick={() => setModalUpload(false)}>
                                    <IconSvg svgPath={IconClose} />
                                </div>
                            </Styles.Header>
                            <Styles.ContainerModal>
                                <Styles.Dropzone {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <IconSvg svgPath={iconMove} />
                                    <h3>Arraste e solte para adicionar algum arquivo ou clique para selecionar um arquivo do seu computador.</h3>
                                    <p>Apenas extensões .kml são suportadas.</p>

                                </Styles.Dropzone>
                            </Styles.ContainerModal>
                        </Styles.Wrapper>
                    </Styles.Modal>

                )}

        </Styles.MapContainer>
    );
};

export default ModalMap;
