import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import Input from '../../../../../Input';
import Select from '../../../../../Select';

import * as Styles from './styles';

import { getStates, getCities } from '../../../../../../store/modules/states/actions';

const FarmInfos = ({ form }) => {
    const dispatch = useDispatch();

    const StateRef = useRef();

    const { states, cities, loading } = useSelector((state) => state.states);
    const { data: user } = useSelector((state) => state.user);

    const [hasState, setHasState] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => { dispatch(getStates()); }, [dispatch]);

    useEffect(() => {
        const { farmEdit } = form.values;
        if (farmEdit) {
            setHasState(farmEdit?.city?.state);
            const value = JSON.stringify(farmEdit?.city?.state);
            form.setFieldValue('state', value);
            dispatch(getCities(farmEdit?.city?.state?.id));
            setIsEdit(true);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { farmEdit } = form.values;
        if (farmEdit && cities?.length > 0) {
            const value = JSON.stringify(cities.filter((city) => farmEdit?.city?.id === city.id)[0]);
            form.setFieldValue('city', value);
        }
    // eslint-disable-next-line
    }, [cities]);

    const handleChange = (event) => {
        setHasState(event.target.value);
        const state = JSON.parse(event.target.value);
        dispatch(getCities(state.id));
        form.handleChange(event);
        form.values.city = {};
    };

    const memoSelectedCustomers = useMemo(() => {
        const { selectedUsers } = form.values;
        const selectedCustomers = selectedUsers.filter((user) => user.role.name === 'Cliente');
        const inSelectedUsers = selectedUsers?.filter((user) => form.values?.farmEdit?.customer?.id === user.id)[0];
        let currentProducer = {};
        if (form?.values?.producer) currentProducer = JSON.parse(form?.values?.producer);
        if (isFirst && form.values?.farmEdit?.customer?.id !== currentProducer?.id && !inSelectedUsers && form.values?.farmEdit?.customer?.id) {
            const producer = { ...form.values?.farmEdit?.customer, role: 'Cliente' };
            const value = [...selectedCustomers, producer];
            form.setFieldValue('selectedUsers', value);
            return value;
        }
        return selectedCustomers;
    // eslint-disable-next-line
    }, [form.values.selectedUsers, isEdit]);

    const memoSelectedConsultants = useMemo(() => {
        if (user.role.id === '3') {
            return [user];
        }

        const { selectedUsers } = form.values;
        const selectedCustomers = selectedUsers.filter((user) => user.role.name === 'Consultor');

        const inSelectedUsers = selectedUsers?.filter((user) => form.values?.farmEdit?.consultant?.id === user.id)[0];
        let currentConsultant = {};
        if (form?.values?.consultant) currentConsultant = JSON.parse(form?.values?.consultant);
        if (isFirst && form.values?.farmEdit?.consultant?.id !== currentConsultant?.id && !inSelectedUsers && form.values?.farmEdit?.consultant?.id) {
            const consultant = { ...form.values?.farmEdit?.consultant, role: 'Consultor' };
            const value = [...selectedCustomers, consultant];
            form.setFieldValue('selectedUsers', value);
            return value;
        }
        return selectedCustomers;
    // eslint-disable-next-line
    }, [form.values.selectedUsers, isEdit]);

    const onClickProducerSelect = () => {
        if (!(memoSelectedCustomers.length > 0)) {
            Swal.fire({
                title: 'Selecione pelo menos um Cliente na tabela abaixo.',
                confirmButtonText: 'Entendi',
                confirmButtonColor: '#00586B',
                padding: 32,
            });
        }
    };

    const memoProducerOptions = useMemo(() => memoSelectedCustomers?.filter((customer) => customer.id !== form.values.farmEdit?.customer?.id).map((user, index) => (
        <option key={index} value={JSON.stringify(user)}>
            {user.name}
        </option>
        // eslint-disable-next-line
    )), [memoSelectedCustomers]);

    const memoConsultantOptions = useMemo(() => memoSelectedConsultants?.filter((consultant) => consultant.id !== form.values.farmEdit?.consultant?.id).map((user, index) => (
        <option key={index} value={JSON.stringify(user)}>
            {user.name}
        </option>
        // eslint-disable-next-line
    )), [memoSelectedConsultants]);

    useEffect(() => {
        const producer = memoSelectedCustomers?.filter((user) => form.values?.farmEdit?.customer?.id === user.id)[0];
        if (producer && isFirst) {
            setIsFirst(false);
            const producerValue = JSON.stringify({ ...producer, role: 'Cliente' });
            form.setFieldValue('producer', producerValue);
            const consultantValue = JSON.stringify(form.values?.farmEdit?.consultant ?? user);
            form.setFieldValue('consultant', consultantValue);
        }
    // eslint-disable-next-line
    }, [memoProducerOptions]);

    const handleChangeProducer = (event) => {
        form.setFieldValue('producer', event.target.value);
    };

    const handleChangeConsultant = (event) => {
        form.setFieldValue('consultant', event.target.value);
    };

    return (
        <Styles.Form>
            <Input
                value={form.values.name}
                name="name"
                placeholder="Nome da Fazenda"
                autoComplete="off"
                onChange={form.handleChange}
                error={form.errors.name}
                touched={form.touched.name}
            />
            <div className="wrapper">
                <Select
                    ref={StateRef}
                    value={form.values.state}
                    name="state"
                    onChange={handleChange}
                    error={form.errors.state}
                    touched={form.touched.state}
                    className={isEdit ? '' : form.values.state === '' ? 'no-selected' : ''}
                >
                    {isEdit
                        ? <option value="">{form.values.farmEdit.city.state.uf}</option>
                        : (
                            <>
                                <option value="" disabled>UF</option>
                                {states?.map((state, index) => <option key={index} value={JSON.stringify(state)}>{state.uf}</option>)}
                            </>
                        )}
                </Select>
                <Select
                    value={form.values.city}
                    disabled={loading || !hasState}
                    name="city"
                    onChange={form.handleChange}
                    error={form.errors.city}
                    touched={form.touched.city}
                    className={isEdit ? '' : form.values.city === '' ? 'no-selected' : ''}
                >
                    {loading
                        ? <option value="" disabled>Carregando...</option>
                        : (
                            <>
                                <option value="" disabled>Cidade</option>
                                {cities?.map((city, index) => <option key={index} value={JSON.stringify(city)}>{city.name}</option>)}
                            </>
                        )}
                </Select>
            </div>

            <Select
                name="consultant"
                onChange={handleChangeConsultant}
                value={form.values.consultant}
                error={form.errors.consultant}
                touched={form.touched.consultant}
                className={form.values.consultant === '' ? 'no-selected' : ''}
            >
                <option value="" disabled>Consultor</option>
                {isEdit && <option value={form.values.farmEdit.consultant.id}>{form.values.farmEdit.consultant.name}</option>}
                {memoConsultantOptions}

            </Select>

            <div onClick={() => onClickProducerSelect()}>
                <Select
                    name="producer"
                    disabled={!(form.values.selectedUsers.length > 0)}
                    onChange={handleChangeProducer}
                    value={form.values.producer}
                    error={form.errors.producer}
                    touched={form.touched.producer}
                    className={isEdit ? '' : form.values.producer === '' ? 'no-selected' : ''}
                >
                    <option value="" disabled>Produtor</option>
                    {isEdit && <option value={form.values.farmEdit.customer.id}>{form.values.farmEdit.customer.name}</option>}
                    {memoProducerOptions}

                </Select>
            </div>

        </Styles.Form>
    );
};

export default React.memo(FarmInfos);
