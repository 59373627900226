import React, {
    useEffect, useCallback, useState, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as SwalAlert from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import { useTable, useExpanded } from 'react-table';
import moment from 'moment-timezone';
import * as Styles from './styles';

import ButtonAction from '../../../components/ButtonAction';
import ContainerPlanting from '../../../components/ContainerPlanting';
import ContainerWeather from '../../../components/ContainerWeather';
import ContainerFarm from '../../../components/ContainerFarm';
import PagePivotSkeleton from '../../../components/Skeletons/PagePivotSkeleton';
import ButtonFloatPivot from '../../../components/ButtonFloatPivot';

import NDVI from '../../../components/NDVI';

import {
    getCultureIcon,
    getLastBhMetConditions,
    getLastIrrigations,
    getLastsCadRoot,
    getSoilIcon,
} from '../../../utils/functions/area';
import {
    areaById,
    areaRemoveById,
    areaArchivedById,
    areaRefresh,
} from '../../../store/modules/area/actions';
import {
    areaParametersById,
    parametersRemove,
} from '../../../store/modules/areaParameters/actions';
import { showModal } from '../../../store/modules/modal/actions';
import ModalMap from '../../../components/Modals/ModalMap';
import StackChart from '../../../components/StackChart';
import CombinedChart from '../../../components/Chart/Combined';
import ContainerGraphSkeleton from '../../../components/Skeletons/ContainerGraphSkeleton';
import Error404 from '../../../components/Error404';
import { lastDaysMap } from '../../../utils/helpers/dateUtils';

const Pivot = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
        dataArea, loadingArea, loadingAreaBh, error,
    } = useSelector(
        (state) => state.area,
    );

    const { loadingAreaParameters, areaParametersData } = useSelector(
        (state) => state.areaParameters,
    );

    const { data: user } = useSelector((state) => state.user);

    const [dropdown, setDropdown] = useState(false);
    const [imageNDVI, setImageNDVI] = useState({
        show: false,
        coords: [],
        image: null,
    });

    const [closedNDVI, setClosedNDVI] = useState(false);

    const handleRefresh = useCallback(() => {
        SwalAlert.fire({
            title: 'Você tem certeza?',
            text: 'Refaremos todos os cálculos do Balanço Hidrico!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                dispatch(areaRefresh(id, user.id));
            }
        });
    }, [id, dispatch, user.id]);

    const datesHashmap = useMemo(() => lastDaysMap(30, true), []);

    useEffect(() => {
        document.title = !loadingArea && dataArea != null
            ? `Smart Agri - Sistema Integrado de Manejo | ${dataArea.name}`
            : 'Smart Agri - Sistema Integrado de Manejo';
        return () => {
            document.title = 'Smart Agri - Sistema Integrado de Manejo';
        };
    }, [loadingArea, dataArea]);

    useEffect(() => {
        if (id != null && id !== 'main') {
            dispatch(areaById(id));
            dispatch(areaParametersById(id));
        }
    }, [id, dispatch]);

    const handleArchived = () => {
        if (!dataArea.active) {
            return SwalAlert.fire({
                title: 'Você tem certeza?',
                text: 'Após confirmar, sua área será desarquivada.',
                icon: 'warning',
                allowOutsideClick: false,
                showCloseButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Sim',
                confirmButtonColor: '#f1003b',
                reverseButtons: true,
                preConfirm: () => dispatch(areaArchivedById(id, user, !dataArea.active)),
            });
        }
        return SwalAlert.fire({
            title: 'Você tem certeza?',
            text: 'Após arquivar, sua área será armazenada em outro espaço.',
            icon: 'warning',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#f1003b',
            reverseButtons: true,
            padding: 32,
            preConfirm: () => dispatch(areaArchivedById(id, user, dataArea.active)),
        });
    };

    const handleDelete = () => {
        SwalAlert.fire({
            title: 'Você tem certeza?',
            text: 'Após deletar a área, será necessário cria-la novamente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#f1003b',
            cancelButtonText: 'Cancelar',
            preConfirm: () => dispatch(areaRemoveById(id, user)),
        });
    };

    const handleDeleteParameter = (row) => {
        SwalAlert.fire({
            title: 'Você tem certeza?',
            text: 'Após confirmar, o parâmetro será removido.',
            icon: 'warning',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#f1003b',
            reverseButtons: true,
            preConfirm: async () => {
                dispatch(parametersRemove(parseInt(row.original.id), id));
            },
        });
    };

    const handleEdit = () => dispatch(showModal('ModalArea', { isOpen: true, isEdit: true }));
    const calculateLatLong = () => {
        const lat = Math.round(dataArea?.boundary?.center?.latitude * 10000) / 10000;
        const long = Math.round(dataArea?.boundary?.center?.longitude * 10000) / 10000;

        return { lat, long };
    };

    if ((dataArea == null || loadingArea) && !error) return <PagePivotSkeleton />;

    if (error && dataArea == null) {
        return (
            <Error404 message="Área não foi encontrada, tente novamente com outra área." />
        );
    }
    const handleClickNDVI = (image) => {
        const array = [];

        dataArea.ndvi.bounds.forEach((item) => {
            array.push(
                {
                    latitude: item.miny,
                    longitude: item.minx,
                },
                {
                    latitude: item.maxy,
                    longitude: item.maxx,
                },
            );
        });

        setImageNDVI({
            image,
            coords: array,
            show: true,
        });
    };

    const handleSetImageNDVI = (data) => {
        setImageNDVI({
            ...data,
            show: false,
        });
        setClosedNDVI(false);
    };

    const onCloseNDVI = () => setClosedNDVI(true);

    const Parameters = (props) => {
        const { data } = props;
        return data?.length > 0 ? (
            <Table
                columns={[
                    {
                        Header: 'Id',
                        accessor: 'id',
                    },
                    {
                        Header: 'Parâmetro',
                        id: 'parameter',
                        Cell: ({ row }) => <span>{row?.original?.parameter?.name}</span>,
                    },
                    {
                        Header: 'Valor',
                        accessor: 'value',
                    },
                    {
                        Header: 'Data de inicio',
                        id: 'start_date',
                        Cell: ({ row }) => {
                            const date = moment(row?.original?.start_date)
                                .tz('Europe/London')
                                .format('DD/MM/YYYY');
                            return <span>{date}</span>;
                        },
                    },
                    {
                        Header: 'Data fim',
                        id: 'end_date',
                        Cell: ({ row }) => {
                            const date = moment(row?.original?.end_date)
                                .tz('Europe/London')
                                .format('DD/MM/YYYY');
                            return <span>{date}</span>;
                        },
                    },
                    {
                        Header: 'Ações',
                        id: 'actions',
                        Cell: ({ row }) => (
                            <ButtonAction
                                className="action delete"
                                onClick={() => handleDeleteParameter(row)}
                                iconName="clear"
                            />
                        ),
                    },
                ]}
                data={data || []}
            />
        ) : (
            <p style={{ color: 'black' }}>Nenhum parâmetro encontrado</p>
        );
    };

    const handleOnClickIrrigationPreviewButton = () => {
        dispatch(
            showModal('ModalIrrigationPreview', {
                isOpen: true,
                fullscreen: true,
                selected_area_id: parseInt(dataArea.id),
            }),
        );
    };

    function Table({ columns: userColumns, data, renderRowSubComponent }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            visibleColumns,
        } = useTable(
            {
                columns: userColumns,
                data,
            },
            useExpanded,
        );

        return (
            <Styles.Table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            // Use a React.Fragment here so the table markup is still valid
                            // <React.Fragment {...row.getRowProps()}>
                            <React.Fragment key={index}>
                                <tr>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                                {/*
                        If the row is in an expanded state, render a row with a
                        column that fills the entire length of the table.
                      */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>
                                            {/*
                              Inside it, call our renderRowSubComponent function. In reality,
                              you could pass whatever you want as props to
                              a component like this, including the entire
                              table instance. But for this example, we'll just
                              pass the row
                            */}
                                            {renderRowSubComponent({ row })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Styles.Table>
        );
    }

    return (
        <>
            <Styles.CustomContainer>
                <Styles.Header>
                    <Styles.InfosAreaContainer>
                        <Styles.InfosContainer>
                            <img
                                src={
                                    getCultureIcon(dataArea?.area_crops?.crop?.culture_name)?.icon
                                }
                                alt="icon"
                            />
                            <img
                                src={getSoilIcon(dataArea?.soil?.soil_type?.name)?.icon}
                                alt="icon"
                            />
                            <p>{`${dataArea?.name} - ${dataArea?.farm?.name}`}</p>

                            {user.role.name !== 'Cliente' && (
                                <Styles.GroupButton>
                                    <ButtonAction
                                        iconName="more_vert"
                                        iconColor="gray"
                                        style={{
                                            backgroundColor: dropdown ? '#eee' : 'transparent',
                                        }}
                                        iconSize={24}
                                        onClick={() => setDropdown(!dropdown)}
                                        hasBackground={false}
                                    />
                                    {dropdown && (
                                        <ul className="dropdown">
                                            <li>
                                                <ButtonAction
                                                    label="Atualizar"
                                                    iconName="refresh"
                                                    iconColor="gray"
                                                    iconSize={24}
                                                    onClick={handleRefresh}
                                                    hasBackground={false}
                                                />
                                            </li>

                                            <li>
                                                <ButtonAction
                                                    label={!dataArea.active ? 'Desarquivar' : 'Arquivar'}
                                                    iconName="move_to_inbox"
                                                    iconColor="gray"
                                                    iconSize={24}
                                                    onClick={handleArchived}
                                                    hasBackground={false}
                                                />
                                            </li>

                                            <li>
                                                <ButtonAction
                                                    label="Editar"
                                                    iconName="edit"
                                                    iconColor="gray"
                                                    iconSize={24}
                                                    onClick={handleEdit}
                                                    hasBackground={false}
                                                />
                                            </li>

                                            <li>
                                                <ButtonAction
                                                    label="Remover"
                                                    iconName="close"
                                                    iconSize={24}
                                                    onClick={handleDelete}
                                                />
                                            </li>
                                        </ul>
                                    )}
                                </Styles.GroupButton>
                            )}
                        </Styles.InfosContainer>
                        {!loadingAreaBh ? (
                            <ModalMap
                                showOne
                                customHeight="100%"
                                showControl={false}
                                mapSuccess={() => false}
                                sendDataMap={() => []}
                                imageNDVI={imageNDVI}
                                closedNDVI={closedNDVI}
                                handleSetImageNDVI={handleSetImageNDVI}
                            />
                        ) : (
                            <Skeleton width={600} height={600} style={{ marginTop: 50 }} />
                        )}
                        <span>
                            {`lat, lon (central): ${calculateLatLong().lat}, ${calculateLatLong().long
                            }`}

                        </span>
                        {dataArea.ndvi?.images?.length && (
                            <NDVI
                                NDVIS={dataArea.ndvi.images}
                                handleClickImage={handleClickNDVI}
                                onclose={onCloseNDVI}
                            />
                        )}

                        {imageNDVI?.show && dataArea?.ndvi?.labels && (
                            <img
                                alt="label"
                                className="labelNDVI"
                                src={dataArea?.ndvi?.labels.horizontal}
                            />
                        )}
                    </Styles.InfosAreaContainer>
                    <Styles.DescriptionContainer>
                        <ContainerWeather />

                        <Styles.ContainerInfos>
                            <ContainerPlanting />
                            <ContainerFarm />
                        </Styles.ContainerInfos>
                    </Styles.DescriptionContainer>
                </Styles.Header>

                <Styles.ContainerGraphs>
                    {loadingAreaBh ? (
                        <ContainerGraphSkeleton />
                    ) : (
                        <Styles.GraphRadZone>
                            <>
                                <label>Água disponível na zona radicular</label>
                                <StackChart
                                    bh={getLastsCadRoot(
                                        dataArea?.bh,
                                        dataArea?.area_crops?.sowing_date,
                                        13,
                                        true,
                                    )}
                                    useTitle
                                    height={300}
                                    width={1500}
                                    backgroundStyle={{ height: 'auto' }}
                                    fontSize={15}
                                    widthBar={100}
                                    padding={{
                                        top: 40,
                                        bottom: 110,
                                        left: 120,
                                        right: 70,
                                    }}
                                    datesHashmap={datesHashmap}
                                />
                            </>
                        </Styles.GraphRadZone>
                    )}

                    {loadingAreaBh ? (
                        <ContainerGraphSkeleton />
                    ) : (
                        <Styles.GraphMetZone>
                            <>
                                <label>Condições Meteorológicas</label>
                                <CombinedChart
                                    bh={getLastBhMetConditions(
                                        dataArea?.bh,
                                        dataArea?.area_crops?.sowing_date,
                                    )}
                                    irrigations={getLastIrrigations(dataArea?.water)}
                                    datesHashmap={datesHashmap}
                                    area_irrigation_system_efficiency={
                                        dataArea?.area_irrigation_system?.efficiency
                                    }
                                />
                            </>
                        </Styles.GraphMetZone>
                    )}
                </Styles.ContainerGraphs>

                <Styles.TitleContainer>
                    <h2 style={{ color: 'black' }}>Parâmetros da área</h2>
                    <Styles.Button
                        type="button"
                        onClick={handleOnClickIrrigationPreviewButton}
                    >
                        Planejamento de Irrigação
                    </Styles.Button>
                </Styles.TitleContainer>
                <Styles.ContainerGraphs scroll>
                    {loadingAreaParameters ? (
                        <ContainerGraphSkeleton />
                    ) : (
                        <Parameters data={areaParametersData} />
                    )}
                </Styles.ContainerGraphs>
            </Styles.CustomContainer>

            <ButtonFloatPivot
                rele={dataArea.area_irrigation_system.leaf}
                time_to_one_hundred={dataArea.time_back_to_one_hundred_percent}
            />
        </>
    );
};

export default Pivot;
