import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width:100%;
`;

export const Container = styled.div`
    ${({ theme }) => css`
        padding: ${theme.spacings.xmlarge} ${theme.spacings.small};

        @media(min-width: 601px) {
            margin-left: 5rem;
            padding: ${theme.spacings.small} ${theme.spacings.large};
        }
    `}
`;

export const PWAButton = styled.button`
    position: sticky;
    background: #49AA43;
    color: white;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    top: 0;
    left: 0;
    padding: 1px;
  
    width: 100%;
    z-index: 999;
`;
