import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
    Container, ImgPerfil, List, MenuItem, Divider, MobileMenu, Hamburguer,
} from './styles';
import IconSvg from '../IconSvg';

import iconHome from '../../assets/svgs/icon_home.svg';
import iconReports from '../../assets/svgs/icon_reports.svg';
import iconExit from '../../assets/svgs/icon_exit.svg';
import iconReportIrrigation from '../../assets/svgs/icon_report_irrigation.svg';
import { showModal } from '../../store/modules/modal/actions';

const Menu = () => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const [extendedSideMenu, setExtendedSideMenu] = useState(false);

    const imgStyles = {
        width: '40px',
        height: '40px',
        borderRadius: '20px',
    };

    const handleLogout = useCallback(async () => {
        Swal.fire({
            title: 'Sair',
            html: '<p style="padding: 1.6rem 0;">Você deseja sair?<p>',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Sair',
            confirmButtonColor: '#00586B',
            reverseButtons: true,
            padding: 32,
            preConfirm: () => {
                localStorage.clear();
                window.location.reload();
            },
        });
    }, []);

    const handleModalUser = useCallback(() => dispatch(showModal('ModalCustomer', { isOpen: true })), [dispatch]);

    return (
        <div>
            <Container
                className={extendedSideMenu ? 'extended-side-menu' : 'not-extended-side-menu'}
                onMouseOver={() => setExtendedSideMenu(true)}
                onMouseOut={() => setExtendedSideMenu(false)}
            >
                <ImgPerfil
                    className={extendedSideMenu ? 'extendedMarginImg' : ''}
                    style={{ ...imgStyles }}
                    onClick={handleModalUser}
                    src={require('../../assets/images/perfil.png')}
                />
                <p className="user-name">{user.name}</p>

                <List>
                    <MenuItem className={extendedSideMenu ? 'content-menu' : ''}>
                        <NavLink exact to="/" activeClassName="actived-link">
                            <IconSvg svgPath={iconHome} />
                            <p className="labels">Home</p>
                        </NavLink>

                    </MenuItem>

                    {user.role.name !== 'Cliente'
                    && (
                        <MenuItem className={extendedSideMenu ? 'content-menu' : ''}>
                            <NavLink to="/reports" activeClassName="actived-link">
                                <IconSvg svgPath={iconReports} />
                                <p className="labels">Gráficos</p>
                            </NavLink>

                            <NavLink to="/reportsbh" activeClassName="actived-link">
                                <IconSvg svgPath={iconReportIrrigation} />
                                <p className="labels">Tabelas</p>
                            </NavLink>
                        </MenuItem>
                    )}

                    <Divider className={extendedSideMenu ? 'content-divider' : ''} />
                    <MenuItem className={extendedSideMenu ? 'content-menu' : ''}>
                        <div onClick={handleLogout}>
                            <IconSvg svgPath={iconExit} />
                            <p className="labels">Logout</p>
                        </div>
                    </MenuItem>
                </List>
            </Container>
            <MobileMenu>
                <Hamburguer onClick={() => setExtendedSideMenu(!extendedSideMenu)}>
                    <span className="line line1" />
                    <span className="line line2" />
                    <span className="line line3" />
                </Hamburguer>
            </MobileMenu>
        </div>

    );
};

export default Menu;
