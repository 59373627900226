import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Skeleton from 'react-loading-skeleton';
import { FiFilter } from 'react-icons/fi';
import { AiOutlineOrderedList } from 'react-icons/ai';
import { TiArrowDown, TiArrowUp } from 'react-icons/ti';
import { GrCheckboxSelected, GrCheckbox } from 'react-icons/gr';
import { IoShareOutline } from 'react-icons/io5';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as Styles from './styles';

import PrivateRoute from '../../routes/PrivateRoute';
import PivotModal from './PivotModal';
import ModalMap from '../../components/Modals/ModalMap';

import InfoCard from '../../components/InfoCard';
import Area from '../../components/Area';
import CardAreaSkeleton from '../../components/Skeletons/CardAreaSkeleton';
import ButtonFloatMain from '../../components/ButtonFloatMain';

import iconGraph from '../../assets/svgs/icon_graph.svg';
import iconPin from '../../assets/svgs/icon_pin.svg';

import {
    areasMapRequest,
    cropsStartRequest,
    requestAreaSummaryByUserId,
    requestCustomerByUser,
    requestPagedAreaWithFilterByUserId,
} from '../../store/modules/area/actions';
import Layout from '../../components/Layout';
import { CardIrrigation } from '../../components/CardIrrigation';
import {
    COLOR_ATENCAO,
    COLOR_DEFICIT,
    COLOR_EXTRA,
    COLOR_IDEAL,
} from '../../utils/functions/area';

import { CardTemperature } from '../../components/CardTemperature';

let deferredPrompt;
let showInstallPwaButton = true;

window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt = e;
});

const Main = () => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const {
        loading, customers, dataCrops, pagedAreaWithFilter, areaSummary,
    } = useSelector((state) => state.area);

    const [waterDeficit, setWaterDeficit] = useState([]);
    const [active, setActivedAreas] = useState(true);
    const [orderBy, setOrderByFilter] = useState('areas.updated_at');
    const [order, setOrderFilter] = useState('asc');
    const [customer, setCustomer] = useState([]);
    const [crops, setCrops] = useState([]);

    const {
        loadingSummary,
        areaAmount,
        deficit,
        extra,
        ideal,
        attention,
        totalAreaSize,
    } = areaSummary;

    const ReactSwal = withReactContent(Swal);

    if (window.matchMedia('(display-mode: standalone)').matches) {
        showInstallPwaButton = false;
    }

    const installApp = async () => {
        if (deferredPrompt !== null && deferredPrompt !== undefined) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                deferredPrompt = null;
            }
        } else {
            ReactSwal.fire({
                title: <strong>Instalar SmartAgri</strong>,
                icon: 'info',
                html: (
                    <Styles.PWASwal>
                        <p>Para dispositivos iOS:</p>
                        <p>
                            1. Clique em
                            <IoShareOutline />
                        </p>
                        <p>
                            2. Selecione a opção
                        </p>
                        <p>
                            <strong> Adicionar á página inicial</strong>
                        </p>
                        <div>
                            <p>Caso esteja em outro dispositivo ou navegador busque em suas configurações, lembrando que só é possível instalar o aplicativo através do Google Chrome, Microsoft Edge ou Safari.</p>
                        </div>
                    </Styles.PWASwal>),
                showCloseButton: true,
                focusConfirm: false,
                confirmButtonText: 'Ok, entendido!',
                confirmButtonAriaLabel: 'Ok, entendido!',
                background: '#EEE',
                width: '500px',
            });
        }
    };

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/service-worker.js', { scope: '/' });
        }
    }, []);

    useEffect(() => {
        dispatch(areasMapRequest(user.id));
    }, [user.id, dispatch]);

    useEffect(() => {
        if (!dataCrops.length) {
            dispatch(cropsStartRequest());
        } else {
            setCrops(dataCrops.map((crop) => Number(crop.id)));
        }
    }, [dataCrops, dispatch]);

    useEffect(() => {
        dispatch(requestCustomerByUser(Number(user.id)));
    }, [user.id, dispatch]);

    // for the summaries
    useEffect(() => {
        const filter = {
            waterDeficit: waterDeficit.length ? waterDeficit : null,
            active,
            crops: crops.length ? crops : null,
            producer: customer.length ? customer : null,
        };
        dispatch(requestAreaSummaryByUserId(user.id, filter));
    }, [user.id, dispatch, user.role, crops, customer, active, waterDeficit]);

    // to cards, filter and pagination
    useEffect(() => {
        const newFilter = true;
        dispatch(
            requestPagedAreaWithFilterByUserId(
                Number(user.id),
                pagedAreaWithFilter.currentPage,
                {
                    waterDeficit: waterDeficit.length ? waterDeficit : null,
                    active,
                    crops: crops.length ? crops : null,
                    producer: customer.length ? customer : null,
                },
                {
                    orderBy,
                    order,
                },
                newFilter,
            ),
        );
        // eslint-disable-next-line
    }, [
        user.id,
        dispatch,
        user.role,
        waterDeficit,
        crops,
        customer,
        active,
        orderBy,
        order,
    ]);

    const handleActiveFilter = ({ target: { value } }) => {
        let isActive;
        switch (value) {
        case 'true':
            isActive = true;
            break;

        case 'false':
            isActive = false;
            break;
        default:
            isActive = null;
            break;
        }
        setActivedAreas(isActive);
    };

    const handleOrderByFilter = ({ target: { value } }) => {
        setOrderByFilter(value);
    };

    const handleOrderFilter = () => {
        setOrderFilter(order === 'asc' ? 'desc' : 'asc');
    };

    const handleCheckFilters = (value) => {
        if (waterDeficit.includes(value)) {
            setWaterDeficit(waterDeficit.filter((label) => label !== value));
        } else {
            setWaterDeficit([...waterDeficit, value]);
        }
    };

    const handleCheckFiltersCrops = (event, crops) => {
        setCrops(crops.map((crop) => Number(crop.id)));
    };

    const handleFilterAreaByCustomer = (event, value) => {
        setCustomer(value.map((user) => Number(user.id)));
    };
    return (
        <Layout showInstallPwaButton={showInstallPwaButton} installApp={installApp}>
            <PrivateRoute path="/pivot/:id" component={PivotModal} />
            <Styles.Wrapper>
                <ModalMap
                    styleMap={{ width: '100%' }}
                    hasMainPage
                />
                <CardTemperature />
            </Styles.Wrapper>

            <Styles.ContainerCards>
                <InfoCard
                    iconPath={iconGraph}
                    title="Área Total"
                    description={
                        loadingSummary ? <Skeleton width={120} /> : totalAreaSize
                    }
                />
                <InfoCard
                    iconPath={iconPin}
                    title="Áreas"
                    description={loadingSummary ? <Skeleton width={120} /> : areaAmount}
                />

                {!loadingSummary ? (
                    <CardIrrigation
                        color={COLOR_DEFICIT}
                        label="Déficit hídrico"
                        count={deficit}
                    />
                ) : (
                    <Skeleton width={150} height={80} style={{ marginLeft: '1rem' }} />
                )}

                {!loadingSummary ? (
                    <CardIrrigation
                        color={COLOR_IDEAL}
                        label="Umidade Ideal"
                        count={ideal}
                    />
                ) : (
                    <Skeleton width={150} height={80} style={{ marginLeft: '1rem' }} />
                )}

                {!loadingSummary ? (
                    <CardIrrigation
                        color={COLOR_EXTRA}
                        label="Umidade Extra"
                        count={extra}
                    />
                ) : (
                    <Skeleton width={150} height={80} style={{ marginLeft: '1rem' }} />
                )}

                {!loadingSummary ? (
                    <CardIrrigation
                        color={COLOR_ATENCAO}
                        label="Atenção"
                        count={attention}
                    />
                ) : (
                    <Skeleton width={150} height={80} style={{ marginLeft: '1rem' }} />
                )}
            </Styles.ContainerCards>

            <Styles.FiltersContainer>
                <Styles.FormControl>
                    <div className="auto-complete">
                        <Autocomplete
                            id="multiple-limit-tags"
                            multiple
                            disabled={loading}
                            options={dataCrops.map((crop) => crop)}
                            onChange={handleCheckFiltersCrops}
                            getOptionLabel={(option) => option.name}
                            style={{ width: '100%' }}
                            placeholder="Cultivos"
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="cultivos" />
                            )}
                        />
                    </div>
                </Styles.FormControl>

                <Styles.FormControl>
                    <div className="auto-complete">
                        <Autocomplete
                            id="multiple-limit-tags"
                            multiple
                            disabled={loading}
                            options={customers}
                            onChange={handleFilterAreaByCustomer}
                            onInputChange={() => setCustomer(false)}
                            getOptionLabel={(option) => option.name}
                            style={{ width: '100%', marginBottom: '1.5rem' }}
                            placeholder="Encontrar produtor"
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="produtores" />
                            )}
                        />
                    </div>
                </Styles.FormControl>

                <Styles.FormControl>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                icon={<GrCheckbox />}
                                checkedIcon={<GrCheckboxSelected />}
                            />
                        )}
                        checked={waterDeficit.includes('deficit')}
                        onChange={() => handleCheckFilters('deficit')}
                        label="Filtrar por Déficit hidrico"
                    />
                </Styles.FormControl>

                <Styles.FormControl>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                icon={<GrCheckbox />}
                                checkedIcon={<GrCheckboxSelected />}
                            />
                        )}
                        checked={waterDeficit.includes('ideal')}
                        label="Filtrar por Umidade ideal"
                        onChange={() => handleCheckFilters('ideal')}
                    />
                </Styles.FormControl>

                <Styles.FormControl>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                icon={<GrCheckbox />}
                                checkedIcon={<GrCheckboxSelected />}
                            />
                        )}
                        checked={waterDeficit.includes('extra')}
                        onChange={() => handleCheckFilters('extra')}
                        label="Filtrar por Umidade extra"
                    />
                </Styles.FormControl>

                <Styles.FormControl>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                icon={<GrCheckbox />}
                                checkedIcon={<GrCheckboxSelected />}
                            />
                        )}
                        checked={waterDeficit.includes('attention')}
                        onChange={() => handleCheckFilters('attention')}
                        label="Filtrar por Atenção"
                    />
                </Styles.FormControl>
            </Styles.FiltersContainer>

            <Styles.FormControl>
                <div className="filters">
                    <label>
                        <FiFilter />
                        Filtro de áreas ativadas
                    </label>
                    <select onChange={handleActiveFilter} defaultValue="true">
                        <option value="">Todas</option>
                        <option value="true">Ativadas</option>
                        <option value="false">Desativadas</option>
                    </select>
                </div>

                <div className="filters">
                    <label>
                        <AiOutlineOrderedList />
                        Ordenar por
                    </label>
                    <Styles.OrderBy style={{ display: 'flex' }}>
                        <select onChange={handleOrderByFilter} defaultValue="updated_at">
                            <option value="areas.created_at">Data de criação</option>
                            <option value="areas.updated_at">Data de atualização</option>
                            <option value="areas.name">Name</option>
                            <option value="bh_today.deficit">Deficit hidrico</option>
                        </select>
                        <Styles.ArrowIcon onClick={handleOrderFilter}>
                            {order === 'asc' ? <TiArrowDown /> : <TiArrowUp />}
                        </Styles.ArrowIcon>
                    </Styles.OrderBy>
                </div>
            </Styles.FormControl>

            {pagedAreaWithFilter.loadingFirstPage ? <CardAreaSkeleton /> : <Area />}

            <ButtonFloatMain />
        </Layout>
    );
};

export default Main;
