import styled, { css } from 'styled-components';

export const Container = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  gap:${({ theme }) => theme.spacings.small};

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const WrapperImage = styled.div`
  ${() => css`
    display:flex;
    align-items:center;
    justify-content:flex-end;
  `}
`;

export const Image = styled.img`
  ${() => css`
    width:4rem;
    height:4rem;
    object-fit:contain;
    margin:auto 1rem;
  `}
`;
