import { getDayOfYear } from 'date-fns';

export const CAD = (teta_cc, teta_pmp, soil_density) => (soil_density
    ? ((teta_cc - teta_pmp) * soil_density) / 100
    : teta_cc - teta_pmp);

export const CadRoot = (cad, root_depth) => cad * root_depth;

export const convertWaterToObject = (waters) => {
    const water_object = {};

    waters.forEach((water) => {
        const day = new Date(water.date).toISOString().split('T')[0];
        const { irrigation, ...water_data } = water;
        water_object[day] = {
            ...water_data,
            day_irrigation: irrigation,
        };
    });
    return water_object;
};

export const dayWater = (day, waters) => {
    const day_formated = new Date(day).toISOString().split('T')[0];

    const water = waters[day_formated];
    return water || { rain: null, day_irrigation: null };
};

export const culturePhase = (crop, sowing_days, cycle) => {
    if (crop.crop_type_id === 2) {
    // eslint-disable-next-line no-param-reassign
        cycle = crop.cycle;
    }
    const at = (sowing_days * 100) / cycle;
    const { crop_parameters } = crop;

    let phase = 0;
    let range = 0;
    let total_percent = 0;

    for (let i = 1, len = crop_parameters.length; i < len; i += 1) {
        const min = total_percent + crop_parameters[i - 1].phase_percent;
        const max = min + crop_parameters[i].phase_percent;

        if (at <= min) {
            range = `<${min}`;
            break;
        }

        if (min < at && at <= max) {
            range = `>${min}`;
            phase = i;
            break;
        }

        total_percent = min;
    }
    return { phase, range, at };
};

export const growth = (crop, phase_at, phase_days) => {
    const max = crop.crop_parameters[1].root;
    const min = crop.crop_parameters[0].root;
    const delta = max - min;
    const type = crop.growth_type;
    if (type === 1) {
        if (phase_at > 2) {
            return [max];
        }
        return delta / (phase_days[0] + phase_days[1]);
    }
    if (type === 2) {
        if (phase_at > 1) {
            return [max];
        }
        return delta / (phase_days[0] + phase_days[1]);
    }
    if (type === 3) {
        if (phase_at > 0) {
            return [max];
        }
        return delta / phase_days[0];
    }
    if (type === 4) {
        if (phase_at > 2) {
            return [max];
        }
        return (
            delta
      / phase_days.reduce((a, b, i) => {
          if (i <= phase_days.length - 2) {
              return a + b;
          }
          return a;
      }, 0)
        );
    }
    return undefined;
};

export const RootDepth = ({ area_crops, sowing_days }) => {
    const { crop, cycle_days } = area_crops;
    const cycle = parseFloat(cycle_days);
    const phase_at = culturePhase(crop, sowing_days, cycle).phase;
    const { crop_parameters } = crop;
    const days = crop_parameters.map(
        (crop_parameter) => (cycle * crop_parameter.phase_percent) / 100,
    );
    const root_min_depth = 300;

    let result = crop_parameters[0].root;
    let delta = 0;

    if (sowing_days === 1) {
        return root_min_depth;
    }

    if (Array.isArray(growth(crop, phase_at, days))) {
        return crop_parameters[1].root;
    }

    for (let index = 0, len = phase_at; index < len; index += 1) {
        const _growth = growth(crop, index, days);

        delta += days[index];
        result += days[index] * _growth;
    }

    delta = (sowing_days - 1 - delta) * growth(crop, phase_at, days);
    result += delta;

    if (crop.name === 'Café') {
        return result < 500 ? 500 : Math.ceil(result * 100) / 100;
    }

    return result < root_min_depth
        ? root_min_depth
        : Math.ceil(result * 100) / 100;
};

const coffeKc = (crop, age, population) => {
    const kc_index = getDayOfYear(new Date()) - 1;

    const indexByPopulation = (population) => {
        if (population <= 2800) return 0;
        if (population >= 2801 && population <= 4800) return 1;
        if (population >= 4801 && population <= 9000) return 2;
        return 3;
    };

    const indexByAge = (age) => {
        if (age <= 24) return 0;
        if (age >= 25 && age <= 36) return 1;
        return 2;
    };
    const month = 4 * indexByAge(age * 12) + indexByPopulation(population);
    const index = 365 * month + kc_index;
    return crop.crop_parameters[index + 3].kc;
};

export const cultureKC = (phase, crop_parameters, phase_days) => {
    const result = (phase === 0
        ? crop_parameters[phase].kc
        : crop_parameters[phase].kc / phase_days[phase]);
    return result;
};

export const Kc = ({ area_crops, sowing_days }) => {
    const {
        crop, age, population, cycle_days,
    } = area_crops;
    const { crop_parameters } = crop;

    if (crop.name === 'Café') {
        return coffeKc(crop, age, population);
    }

    const cycle = parseFloat(cycle_days);
    const phase_at = culturePhase(crop, sowing_days, cycle).phase;

    const days = crop_parameters.map(
        (crop_parameter) => (cycle * crop_parameter.phase_percent) / 100,
    );
    let result = cultureKC(0, crop_parameters);
    let delta = days[0];

    if (phase_at === 0) {
        return result;
    }

    for (let i = 1, len = phase_at; i < len; i += 1) {
        result += days[i] * cultureKC(i, crop_parameters, days);
        delta += days[i];
    }

    result
        += (sowing_days - delta)
        * cultureKC(phase_at, crop_parameters, days);

    return Math.round(result * 100) / 100;
};

export const MeteoBlueFromDay = (day, all_area_meteo_blue) => {
    const day_formated = new Date(day).toISOString().split('T')[0];
    return all_area_meteo_blue[day_formated]
        ? all_area_meteo_blue[day_formated]
        : {
            eto_pm: 4,
            eto_source: 'DEFAULT',
            altitude: 546,
        };
};

const isNumeric = (value) => /^\d+(?:\.\d+)?$/.test(value);

export const containsDefaultParameter = (
    default_area_parameters,
    day_to_calculate,
    parameter,
) => {
    if (!default_area_parameters.length) {
        return null;
    }

    const default_area_parameter_from_day = default_area_parameters.find(
        (default_area_parameter) => {
            const start_date = new Date(new Date(default_area_parameter.start_date).toISOString().split('T')[0]).getTime();
            const end_date = new Date(new Date(default_area_parameter.end_date).toISOString().split('T')[0]).getTime();
            const provided_day_to_calculate = new Date(new Date(day_to_calculate).toISOString().split('T')[0]).getTime();

            return (
                start_date <= provided_day_to_calculate
                && end_date >= provided_day_to_calculate
                && default_area_parameter.parameter === parameter
            );
        },
    );

    if (!default_area_parameter_from_day) {
        return null;
    }

    return isNumeric(default_area_parameter_from_day.value)
        ? parseFloat(default_area_parameter_from_day.value)
        : default_area_parameter_from_day.value;
};

export const defaultParameterOrCalculation = (
    default_area_parameters,
    day_to_calculate,
    parameter,
    calculationFunction,
    calculation_values,
) => {
    const default_parameter = containsDefaultParameter(
        default_area_parameters,
        day_to_calculate,
        parameter,
    );

    return default_parameter || calculationFunction(calculation_values);
};

export const ATDCalculate = (cad_root, soil_humidity, last_bh) => {
    if (last_bh) {
        return Number(last_bh.balance) + Number(last_bh.atd) >= Number(cad_root)
            ? cad_root
            : last_bh.balance + last_bh.atd;
    }
    return cad_root * (soil_humidity / 100);
};

export const ATDFixed = (cad_root, soil_moisture_change) => (soil_moisture_change
    ? cad_root * (soil_moisture_change / 100)
    : null);

export const ATD = (atd_calculate, atd_fixed) => atd_fixed || atd_calculate;

export const ATDPercentageCalculate = (cad_root, atd_calculate) => (atd_calculate / cad_root) * 100;

export const ATDPercentageFixed = (soil_moisture_change) => soil_moisture_change || null;

export const ATDPercentage = (atd_percentage_calculate, atd_percentage_fixed) => atd_percentage_fixed || atd_percentage_calculate;

export const ETR = (etc, kred) => etc * kred;

export const ETc = (eto_pm, kc, eto_predicted) => {
    const result = (eto_pm ? eto_pm * kc : eto_predicted * kc);

    return result;
};

export const Kred = (add, add_max) => add / add_max;

export const Add = (add_max, atd) => {
    const result = (add_max <= atd ? add_max : atd);

    return result;
};

export const AddMax = (cad_root) => {
    const sixty_percent = 0.6;
    return cad_root * sixty_percent;
};

export const Balance = (effective_rain, effective_irrigation, etr_adj) => effective_rain + effective_irrigation - etr_adj;

export const EffectiveRain = (rain) => {
    const ninety_percent = 0.9;
    return rain ? rain * ninety_percent : 0;
};

export const EffectiveIrrigation = (day_irrigation, effective_irrigation_milimiter, effective_irrigation_percentage) => (day_irrigation > 0
    ? effective_irrigation_milimiter
    : effective_irrigation_percentage);

export const EtrAdj = (etr, adjustment_coefficient) => etr * adjustment_coefficient;

export const EffectiveIrrigationMilimiter = (irrigation, efficiency_irrigation_system) => (irrigation
    ? irrigation * efficiency_irrigation_system
    : 0);

export const EffectiveIrrigationPercentage = (irrigation_percentimeter, leaf, efficiency_irrigation_system) => (irrigation_percentimeter
    ? ((leaf * 100) / irrigation_percentimeter) * efficiency_irrigation_system
    : 0);

const env_object = {
    DEFAULT_ETO_PREDICTED: 3,
    DEFAULT_ADJUSTMENT_COEFFICIENT: 1,
    DEFAULT_ETO_PM: 4,
    DEFAULT_ETO_SOURCE: 'DEFAULT',
    DEFAULT_ALTITUDE: 546,
};

export const defaultParameterOrEnvVariable = (default_area_parameters, day_to_calculate, parameter) => {
    const default_area_parameter_from_day = containsDefaultParameter(default_area_parameters, day_to_calculate, parameter);

    if (default_area_parameter_from_day) {
        return default_area_parameter_from_day;
    }

    const default_value = env_object[`DEFAULT_${parameter}`];

    if (default_value) {
        return parseFloat(default_value);
    }

    return null;
};

export const Irrigation = (day_irrigation, efficiency) => (day_irrigation
    ? day_irrigation * efficiency
    : 0);

export const meteoBlue = (area_weather) => {
    const meteo_blue = {};

    const sorted_area_weather = [...area_weather];

    sorted_area_weather.sort((firstElement, secondElement) => {
        const first_created_at = new Date(firstElement.created_at).getTime();
        const second_element = new Date(secondElement.created_at).getTime();

        return second_element - first_created_at;
    });

    sorted_area_weather.forEach((weather) => {
        if (weather?.eto_pm) {
            const day = new Date(weather.date).toISOString().split('T')[0];
            const key_exists = Object.keys(meteo_blue).find((key) => key === day);

            if (!key_exists) {
                meteo_blue[day] = {
                    ...weather,
                    eto_source: 'meteoblue',
                };
            }
        }
    });

    return meteo_blue;
};

export const formattedArea = (area) => {
    const {
        id, weather, water: waters, area_crops, area_irrigation_system, soil, ...area_fields
    } = area;

    const _weather = meteoBlue(weather);
    const _water = convertWaterToObject(waters);
    return {
        area_id: id,
        weather: _weather,
        waters: _water,
        area_crop: area_crops,
        area_irrigation_system,
        soil,
        ...area_fields,
    };
};

export const generateDefaultAreaParameters = async (
    default_area_parameters,
) => {
    if (!default_area_parameters.length) return [];

    const default_formatted_area_parameters = default_area_parameters.map(
        (default_area_parameter) => ({
            parameter: default_area_parameter.parameter.key,
            value: default_area_parameter.value,
            start_date: new Date(default_area_parameter.start_date),
            end_date: new Date(default_area_parameter.end_date),
        }),
    );

    return default_formatted_area_parameters;
};

export const CadDif = (cad_root, atd_calculate) => cad_root - atd_calculate;
