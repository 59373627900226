import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
    ${({ theme }) => css`
        position:relative;
        box-shadow:2px 2px 10px rgba(0,0,0,0.1);
        border-radius: ${theme.border.radius};
        padding: ${theme.spacings.xxsmall};
        height: 8rem;
        width:18rem;
        margin: ${theme.spacings.xxxsmall};
        max-width: 25rem;
        min-width: 15rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        ${media.greaterThan('medium')`
            width: 100%;
        `}
    `}  
`;

export const IconContainer = styled.div`
    ${({ theme }) => css`
        display:flex;
        align-items:center;
        justify-content:center;
        box-shadow:2px 2px 10px rgba(0,0,0,0.3);
        position:absolute;
        top:-10px;
        left:5px;
        width:74px;
        height:74px;
        background: rgb(16,49,62);
        background: linear-gradient(90deg, rgba(16,49,62,1) 0%, rgba(0,88,107,1) 100%);
        padding:${theme.spacings.medium};
        border-radius:${theme.border.radius};

        @media screen and (max-width:1312px){
            width:10px;
            height:10px;
            top:-15px;
            padding:${theme.spacings.xmedium};
        }
  
    `}
`;

export const Data = styled.div`
    display:flex;
    flex-direction:column;
    margin-left:4rem;

    @media screen and (max-width:1312px){
        margin-left:0px;
    }
`;

export const Title = styled.span`
    ${({ theme }) => css`
        text-align:right;
        color:${theme.colors.gray};
        margin-bottom:${theme.spacings.xxxsmall};
        font-size:${theme.font.sizes.xxxsmall};
    `}
`;

export const Description = styled.span`
    ${({ theme }) => css`
        text-align:right;
        color:${theme.colors.texts};
        font-size:${theme.font.sizes.small};
    `}
`;
