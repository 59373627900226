import { gql } from 'graphql-request';

const ADD_PARAMETERS = gql`
mutation AREA_ADD($fields: AreaParametersInput!) {
    areaParametersAdd(
      fields: $fields
    ) {
      id
      area {
        id
        name
      }
      value
      end_date
      start_date
      parameter {
        id
        name
      }
    }
  }
`;

export default ADD_PARAMETERS;
