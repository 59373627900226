import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { useDispatch, useSelector } from 'react-redux';

import ButtonAction from '../../ButtonAction';
import Checkbox from '../../Checkbox';

import { handleOnClickAdd } from './utils/addUser';
import { handleOnClickDelete } from './utils/removeUser';
import { handleOnChangeCheckbox } from './utils/selectUser';
import { handleOnActiveUser } from './utils/activeUser';

const roles = {
    Administrador: 'Administrador',
    Gerente: 'Gerente',
    Consultor: 'Consultor',
    Cliente: 'Cliente',
};

const Cell = ({
    cell, form, data, setData,
}) => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const isChecked = (currentUser, farm) => {
        if ((currentUser.id === farm?.customer?.id)
        || (currentUser.id === farm?.consultant?.id)) return true;
        return currentUser.checked;
    };

    return (
        <>
            {(() => {
                if (!cell.column?.id) return null;
                switch (cell.column.id) {
                case 'id':
                    return (
                        <>
                            {form
                                ? (
                                    <td {...cell.getCellProps()}>
                                        <div className="id-container" style={{ paddingLeft: `${cell.row.depth * 1.2}rem` }}>
                                            <Checkbox
                                                checked={isChecked(cell.row.original, form?.values?.farmEdit)}
                                                onChange={() => handleOnChangeCheckbox(cell.row.original, data, setData, form)}
                                            />
                                        </div>
                                    </td>
                                ) : null}
                        </>
                    );
                case 'role':
                    return (
                        <td {...cell.getCellProps()} style={{ paddingLeft: `calc(${cell.row.depth * 1.2}rem + 1rem)` }}>
                            {roles[cell.row.original?.role.name]}
                        </td>
                    );
                case 'actions':
                    return (
                        <td {...cell.getCellProps()}>
                            <div className="actions-container">

                                {cell.row.original.role?.name !== 'Cliente'
                                    ? (
                                        <ButtonAction
                                            className="action add"
                                            onClick={() => handleOnClickAdd(cell.row.original, dispatch)}
                                            iconName="add"
                                        />
                                    )
                                    : null}

                                <ButtonAction
                                    className="action delete"
                                    onClick={() => handleOnClickDelete(cell.row.original, dispatch)}
                                    iconName="clear"
                                />

                                <Toggle
                                    id="cheese-status"
                                    icons={false}
                                    checked={cell.row.original.active}
                                    onChange={(event) => handleOnActiveUser(cell.row.original, event.target.checked, dispatch, user.id)}
                                />
                            </div>
                        </td>
                    );
                default:
                    return (
                        <td {...cell.getCellProps()} style={{ paddingLeft: `calc(${cell.row.depth * 1.2}rem + 1rem)` }}>
                            {cell.render('Cell')}
                        </td>
                    );
                }
            })()}
        </>
    );
};

export default Cell;
