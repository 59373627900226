import produce from 'immer';

const INITIAL_STATE = {
    data: [],
    areaParametersData: [],
    message: null,
    loading: false,
    loadingAreaParameters: false,
};

export default function areaParameters(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@parameters/AREA_PARAMETERS_BY_ID':
        return produce(state, (draft) => {
            draft.loadingAreaParameters = true;
        });

    case '@parameters/AREA_PARAMETERS_BY_ID_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingAreaParameters = false;
            draft.areaParametersData = action.payload;
        });

    case '@parameters/AREA_PARAMETERS_BY_ID_ERROR':
        return produce(state, (draft) => {
            draft.loadingAreaParameters = false;
            draft.areaParametersData = [];
            draft.message = action.payload.message;
        });

    case '@parameters/PARAMETERS_ADD':
    case '@parameters/PARAMETERS_REMOVE':
    case '@parameters/PARAMETERS_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
        });
    case '@parameters/PARAMETERS_REMOVE_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.loadingAreaParameters = true;
            draft.data = action.payload.data;
        });
    case '@parameters/PARAMETERS_ADD_SUCCESS':
    case '@parameters/PARAMETERS_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.data = action.payload.data;
        });
    case '@parameters/PARAMETERS_ADD_ERROR':
    case '@parameters/PARAMETERS_REMOVE_ERROR':
    case '@parameters/PARAMETERS_ERROR':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.message = action.payload.message;
        });
    default:
        return state;
    }
}
