import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import { meteoblue } from '../../utils/helpers/weather';
import iconWind from '../../assets/images/weather/wind.png';
import * as Styles from './styles';
import { getUserFarms, weatherByFarm } from '../../store/modules/farm/actions';

export const CardTemperature = () => {
    const dispatch = useDispatch();
    const { weatherData, loading } = useSelector((state) => state.farm);
    const { data: user } = useSelector((state) => state.user);
    const { userFarms } = useSelector((state) => state.farm);

    const [selectedFarm, setSelectedFarm] = useState('');
    const [toggleDaily, setToggleDaily] = useState(false);
    const [isDropdownLoaded, setIsDropdownLoaded] = useState(false);

    const handleSelectFarm = useCallback((evt) => {
        setSelectedFarm(evt.target.value);
    }, []);

    useEffect(() => {
        dispatch(getUserFarms(user.id));
    }, [user.id, dispatch]);

    useEffect(() => {
        if (selectedFarm && user.id) {
            dispatch(weatherByFarm(selectedFarm, user.id, toggleDaily));
        }
    }, [selectedFarm, user.id, toggleDaily, dispatch]);

    const memoForecastFormatted = useMemo(() => {
        if (!weatherData || weatherData.length === 0) return null;

        return weatherData.map((forecast) => {
            const [date, fullHour] = forecast.label.split('T');
            const [hour] = !toggleDaily ? fullHour.split('-') : '';

            return {
                ...forecast,
                verify: date,
                hour,
            };
        });
    }, [weatherData]);

    useEffect(() => {
        if (weatherData && weatherData.length > 0) {
            setIsDropdownLoaded(true);
        }
    }, [weatherData]);

    useEffect(() => {
        if (user.id && userFarms.length > 0) {
            setSelectedFarm(userFarms[0]?.farms[0]?.id);
            setIsDropdownLoaded(true);
        }
    }, [user.id, userFarms]);

    return (
        <Styles.Container>
            <Styles.SelectContainer>
                <div className="container-select">
                    <div className="group">
                        <label>Selecione fazenda:</label>
                        <select
                            onChange={handleSelectFarm}
                            value={selectedFarm}
                            disabled={!isDropdownLoaded}
                        >
                            {loading && <option>Carregando...</option>}
                            {userFarms.length > 0
                && userFarms[0].farms.map((farm) => (
                    <option key={farm.id} value={farm.id}>
                        {farm.name}
                    </option>
                ))}
                        </select>
                    </div>
                    <div className="toggle">
                        <label className="label-toggle">Temperatura Diária:</label>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={toggleDaily}
                                onChange={() => setToggleDaily(!toggleDaily)}
                                disabled={!isDropdownLoaded}
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                </div>
            </Styles.SelectContainer>

            {!memoForecastFormatted || memoForecastFormatted.length === 0 ? (
                <div className="not-found">
                    <img src={iconWind} alt="Ícone de vento" />
                    <span>Não existem dados para esta fazenda :(</span>
                </div>
            ) : (
                <Styles.TemperatureContainer>
                    {memoForecastFormatted.map((forecast, index) => (
                        <Styles.Temperature key={index}>
                            <div className="infos">
                                <span className="date">
                                    {toggleDaily
                                        ? moment(new Date(forecast.label))
                                            .add(1, 'day')
                                            .locale('pt-br')
                                            .format('ll')
                                        : moment(new Date(forecast.label))
                                            .locale('pt-br')
                                            .format('ll')}
                                </span>
                                <span className="temperature">
                                    {forecast.max_temp}
                                    ° C
                                </span>
                            </div>
                            <div
                                className="hours"
                                style={{ flexDirection: 'row', alignItems: 'center' }}
                            >
                                <span className="percent">{forecast.hour}</span>
                            </div>

                            <img
                                src={meteoblue[forecast.is_day][forecast.type - 1]}
                                alt="Ícone de clima"
                            />

                            <div className="group">
                                <span className="percent">
                                    {forecast.precip_probabil}
                                    %
                                </span>
                                <span className="description none">
                                    Probabilidade
                                    <br />
                                    de chuva
                                </span>
                            </div>
                            <div className="group">
                                <span className="intensity">
                                    {forecast.precip_intensity}
                                    mm
                                </span>
                                <span className="description none">
                                    Intensidade
                                    <br />
                                    de chuva
                                </span>
                            </div>
                        </Styles.Temperature>
                    ))}
                </Styles.TemperatureContainer>
            )}
        </Styles.Container>
    );
};
