import React, { useMemo } from 'react';
import {
    VictoryStack,
    VictoryBar,
    VictoryLabel,
    VictoryChart,
    VictoryAxis,
    VictoryContainer,
} from 'victory';
import _ from 'lodash';
import moment from 'moment';
import {
    ImageBackground, NotFound, WrapperLegend, GraphWrapper,
} from './styles';

import { lastDays } from '../../utils/helpers/dateUtils';
import { LegendChart } from '../Chart';
import { Bullet } from '../Chart/Legend';

const StackChart = ({
    bh,
    useTitle,
    width = 100,
    height = 240,
    widthBar = 100,
    padding = {
        top: -10, bottom: 0, left: 60, right: 50,
    },
    backgroundStyle = { width: '100%', height: 240, flex: 1 },
    labelPosition = 20,
    fontSize = 16,
    minStackHeight = 20,
    datesHashmap,
}) => {
    const blue = useMemo(() => _.map(bh, (item) => ({
        x: item.date,
        y: item.percentageBlue < minStackHeight ? minStackHeight : item.percentageBlue,
        label: () => `${item?.blue?.toFixed(2)}mm`.replace('.', ','),
    })), [bh]);

    const getWhiteHeight = ({ percentageBlue }) => 100 - Math.abs(percentageBlue);
    const white = useMemo(() => _.map(bh, (item) => ({
        x: item.date,
        y: getWhiteHeight(item),
        label: '',
    })), [bh]);

    const dates = useMemo(() => lastDays(bh.length, true) || [], [bh]);

    const isCard = useMemo(() => dates.length <= 1, [dates]);

    const findColorToFill = (text) => {
        if (isCard) return '#FFF';
        const color = {
            today: '#A1DAA5',
            old: '#FFF',
            future: '#99d2f3',
        };
        const today = moment().startOf('day');
        const tickDate = moment(datesHashmap[text[0]], 'DD/MM/YYYY');
        const diff = today.diff(tickDate, 'days');

        let currentColor;

        if (diff === 0) {
            currentColor = color.today;
        } else if (diff >= 1) {
            currentColor = color.old;
        } else {
            currentColor = color.future;
        }

        return (currentColor);
    };

    if (!bh?.length) {
        return (
            <ImageBackground notFound>
                <NotFound>
                    <h2>Não foi possível gerar o gráfico.</h2>
                </NotFound>
            </ImageBackground>
        );
    }

    return (
        <>
            <GraphWrapper isCard={isCard}>
                <ImageBackground width={width} isCard={isCard}>
                    <VictoryChart
                        width={width}
                        height={height}
                        padding={padding}
                        style={{
                            ticks: { strokeWidth: 0 },
                            tickLabels: {
                                fill: '#FFFFFF',
                                fontFamily: 'inherit',
                                fontSize: 10,
                            },
                        }}
                        containerComponent={(
                            <VictoryContainer
                                responsive={isCard}
                                style={{
                                    marginLeft: isCard ? 0 : '-5rem',
                                    touchAction: 'unset',
                                }}
                            />
                        )}
                    >
                        <VictoryStack
                            style={{
                                parent: {
                                    border: '5px solid #ccc',
                                },
                            }}
                        >
                            <VictoryBar
                                labelComponent={<VictoryLabel dy={labelPosition} />}
                                cornerRadius={{ bottom: 5 }}
                                style={{
                                    data: {
                                        fill: '#99D2F3',
                                        width: widthBar,
                                    },
                                    labels: {
                                        fill: '#6395D6',
                                        fontWeight: 'bold',
                                        fontSize,
                                        textAlign: 'center',
                                    },
                                }}
                                data={blue}
                            />

                            <VictoryBar
                                labelComponent={<VictoryLabel dy={labelPosition} />}
                                cornerRadius={{ top: 5 }}
                                style={{
                                    data: { fill: '#F2F2F2', width: widthBar },
                                    labels: { fontSize: 0 },
                                }}
                                data={white}
                            />
                        </VictoryStack>

                        <VictoryAxis
                            tickValues={isCard ? null : dates}
                            tickFormat={(t) => {
                                const bh_item = bh.find((item) => item.date === t);
                                const irrigate = bh_item?.irrigate > 0 ? `Irrigar ${bh_item?.irrigate}mm` : 'Não irrigar';
                                const percentimeter = bh_item?.percentimeter > 0 ? `Relé ${bh_item?.percentimeter}%` : '';
                                const atd_percentage = bh_item?.atd_percentage ? `${bh_item?.atd_percentage}%CC` : 'Sem dados';
                                return [t, atd_percentage, irrigate, percentimeter];
                            }}
                            tickLabelComponent={(
                                <VictoryLabel
                                    lineHeight={[1.25, 1.25, 1.25, 1.25]}
                                    style={[{
                                        fill: ({ text }) => findColorToFill(text),
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                    }, {
                                        fill: ({ text }) => findColorToFill(text),
                                        fontSize: 18,
                                        fontWeight: '600px',
                                    }, {
                                        fill: ({ text }) => findColorToFill(text),
                                        fontSize: 16,
                                        fontWeight: '600px',
                                    }, {
                                        fill: ({ text }) => findColorToFill(text),
                                        fontSize: 16,
                                        fontWeight: '600px',
                                    }]}
                                />
                            )}
                            style={{
                                axis: { stroke: 'none', strokeWidth: 0 },
                            }}
                        />
                    </VictoryChart>
                </ImageBackground>
            </GraphWrapper>

            {useTitle ? (
                <WrapperLegend style={backgroundStyle}>
                    <LegendChart color="#F2F2F2" title="Água para completar a CC " />
                    <LegendChart color="#99D2F3" title="Água de fácil absorção" />
                    <LegendChart color="#E4AE66" title="Água de absorção dificultada" />
                </WrapperLegend>
            ) : null}

            {useTitle ? (
                <WrapperLegend style={backgroundStyle}>
                    <Bullet rounded color="#FFF">Histórico</Bullet>
                    <Bullet rounded color="#A1DAA5">Hoje</Bullet>
                    <Bullet rounded color="#99d2f3">Previsão</Bullet>
                </WrapperLegend>
            ) : null}
        </>
    );
};

export default StackChart;
