import styled, { css } from 'styled-components';

export const MapContainer = styled.div`
    height: ${(props) => props.customHeight};
    @media(min-width: 601px) {
        ${(props) => props.hasMainPage && 'width: 100%;'}
    }
    @media(max-width: 600px) {
        width: 100% !important;
        ${(props) => !props.hasMainPage && 'height: 50vh;'}
        ${(props) => !props.hasMainPage && 'margin-top: 2rem;'}
    }
`;

export const Container = styled.div`
    ${({ theme, showAlert }) => css`
        display: flex;
        opacity: ${showAlert ? 1 : 0};
        transition: 1s;
        position: absolute;
        z-index: ${showAlert ? '900' : '0'};
        width: 50%;
        left: 25%;
        top: 25%;
        right: 25%;
        background: ${theme.colors.white};
        padding: ${theme.spacings.xsmall};
        border-radius: .3rem;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
        align-items: center;
        justify-content: center;
        span {
            text-align: center;
            color:${theme.colors.black};
            font-size:${theme.font.sizes.xsmall};
        }
    `}
`;

export const ContainerButton = styled.button`
    ${({ theme }) => css`
        display:flex;
        transition: width 0.15s;
        position:absolute;
        z-index: 900;
        width: 3rem;
        left: 1.2rem;
        top: 20%;
        background:${theme.colors.white};
        padding:${theme.spacings.xxsmall} 0.7rem;
        border-radius:.3rem;
        box-shadow:2px 2px 5px rgba(0,0,0,0.3);
        font-size:${theme.font.sizes.xxsmall};

        .labels{
            display: none;
        }

        &.extended-button-upload{
            width: 14.5rem;
            transition: width 0.3s;
            .labels{
                display: block;
                margin-left: ${theme.spacings.xsmall};
                white-space: nowrap;
                overflow: hidden;
            }

            .name-file{
                margin: 0 ${theme.spacings.xxsmall};
                margin-top: 0.1rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    `}
`;

export const Dropzone = styled.div` 
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
        border: 2px dashed #D8D8D8;
        height: 38rem;
        margin: 1rem;
        border-radius: 5px;
        p{
            margin-top: 1.5rem;
            color: ${theme.colors.gray};
        }
        h3{
            margin-top: 3rem;
            color: ${theme.colors.gray};
        }
    `}
`;

export const Modal = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 900;
`;

export const Wrapper = styled.div`
    border-radius: .4rem;
    max-height: 90%;
    min-width: 80%;
    max-width: 80%;
    overflow: hidden;
`;

export const Header = styled.div`
   ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 56px;
        width: 100%;
        background-color: #00586B;
        padding: 0 ${theme.spacings.xsmall};

        & img:hover {
            cursor: pointer;
            opacity: .6;
            transition: .5s;
        }
  `}
`;

export const ContainerModal = styled.div`
   ${({ theme }) => css`
        background-color: ${theme.colors.white};
        width: 100%;
        height: 40rem;
        max-height: 40rem;
        overflow: auto;
        overflow-x: hidden;
  `}
`;

export const CardTemperatureContainer = styled.div`
    position:absolute;
    right:1rem;
    z-index: 999;
`;
