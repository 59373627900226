import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Wrapper, Container } from './styles';

const ContainerPlanningSkeleton = () => (
    <Wrapper>
        <Container>
            <Skeleton height={132} />
        </Container>
    </Wrapper>
);

export default ContainerPlanningSkeleton;
