import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
    ${({ theme }) => css`
        background:${theme.colors.white};
        height:100%;
        max-height: 450px;
        overflow: auto;
        box-shadow:2px 2px 3px rgba(0,0,0,0.2);
        border-radius:5px;
        flex-grow:1;
        margin-left:1rem;
        border:.1rem solid #eee;

        .not-found {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding:.8rem;
            img {
                width: 20rem;
                height:20rem;
                max-width: 100%;
            }
            span {
                color:${theme.colors.gray};
                font-size:${theme.font.sizes.xsmall};
            }
   
        }

        @media (max-width: 600px) {
            display: none;
        }
    `}
`;

export const SelectContainer = styled.div`
      ${({ theme }) => css`
        background:#eee;
        border-bottom:1px solid #eee;
        padding:10px;
        margin-bottom:10px;

        .container-select {
            display: flex;
            flex-direction: row;
            align-items: center;

            label {
                    color:${theme.colors.gray};
                    font-size:${theme.font.sizes.xxsmall};
                    margin-bottom:1rem;
            }

            .group {
                width:70%;
                padding:0px 5px;
                display:flex;
                align-items: flex-start;
                flex-direction: column;

                select {
                        width:100%;
                        color:${theme.colors.black};
                        font-size:${theme.font.sizes.xxsmall};
                        padding:.8rem;
                }
            }

            .toggle {
                padding:0px 5px;

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 45px;
                    height: 23px;
                }
        
                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
    
                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
    
                .slider:before {
                    position: absolute;
                    content: "";
                    height: 15px;
                    width: 15px;
                    left: 4px;
                    bottom: 4px;
                    background-color: white;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
    
                input:checked + .slider {
                    background-color: #143540;
                }
    
                input:focus + .slider {
                    box-shadow: 0 0 1px #143540;
                }
    
                input:checked + .slider:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                }
    
                .slider.round {
                    border-radius: 34px;
                }
    
                .slider.round:before {
                    border-radius: 50%;
                }
            }
        }
    `}  
`;

export const TemperatureContainer = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
    `}
`;

export const Temperature = styled.div`
    ${({ theme }) => css`  
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        border-bottom:1px solid #eee;
        padding:.5rem;


        .none {
            display: none;
        }

        span{
            color:${theme.colors.black};
            font-size:${theme.font.sizes.xxsmall};
        }

        img {
            width:3rem;
            height:3rem;
            border-radius:2rem;
            order:-1;
        }

        .infos {
            display: flex;
            flex-direction: column;

            .date {
                font-size:.7rem;
                text-align: center;
            }

            .temperature {
                font-size:2rem;
            }
        }

        .hours {
            font-size: 1.2rem;
        }

        .group {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .description {
                font-size:1.2rem;
                color:#333;
                text-align:center;

                ${media.lessThan('medium')`
                    font-size:.8rem;
                `}
            }
        }

 
        &:hover {
            padding:1rem;
            background:#eee;
            cursor:pointer;

            .none {
                display: block;
            }

            .infos {
                .date {
                    font-size:1rem;
                }
    
                .temperature {
                    font-size:3rem;
                    font-weight: bold;
                }
            }

            img{
                margin-right:.5rem;
                width:4rem;
                height:4rem;
            }

        }
    `}
`;
