import { FRAGMENT_GET_AREA } from '../area';

export const FRAGMENT_GET_FARMS = `
    id
    name
    city{
        id
        name
        state {
            id
            name
            uf
        }   
    }
    customer {
        id
        name
    }
    consultant {
        id
        name
    }
    partners {
        id
        name
        role {
            name
            id
        }
    }
    active
    areas {
      ${FRAGMENT_GET_AREA}
        forecast {
            max_temp
          }
        bh_today {
            complementarWater:complementar_water
        }
    }
`;

export const FRAGMENT_GET_WEATHER_BY_FARM = `
    precip_intensity
    precip_probabil
    is_day
    type
    max_temp
    label
`;

export const FRAGMENT_GET_USER_FARMS = `
    farms {
        id
        name
    }
`;
