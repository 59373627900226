import { GraphQLClient } from 'graphql-request';

const endpoint = '/graphql';

// process.env.REACT_APP_API
const client = new GraphQLClient(
    `https://sim-backend-refactor.lonewolfcorp.com${endpoint}`,
    {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*',
        },
    },
);

export default client;
