import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    areaById,
    setEmptyPagedBHReportsArea,
} from '../../store/modules/area/actions';
import { farmsConsultantRequest } from '../../store/modules/farm/actions';
import TableBh from './components/TableBh';
import * as Styles from './styles';

const ReportBhTemplate = () => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const { dataArea, loadingArea } = useSelector((state) => state.area);
    const { data: farms, loading } = useSelector((state) => state.farm);
    const [selectedFarm, setSelectedFarm] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [displayTable, setDisplayTable] = useState(false);

    const [areasFarmSelected, setAreasFarmSelected] = useState([]);

    useEffect(() => {
        dispatch(farmsConsultantRequest(user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (farms.length && farms[0]?.areas.length) {
            setAreasFarmSelected(farms[0].areas);
            const firstArea = farms[0]?.areas[0];
            dispatch(areaById(firstArea.id));
        }
    }, [farms, dispatch]);

    useEffect(() => {
        if (areasFarmSelected.length) {
            dispatch(areaById(areasFarmSelected[0].id));
        }

        dispatch({
            type: '@area/CLEAR_DATA_AREA',
        });
    }, [areasFarmSelected, dispatch]);

    const handleSelectAreaByFarm = (evt) => {
        setDisplayTable(false);
        const farmId = evt.target.value;
        const farmSelected = farms.filter((farm) => farm.id === farmId)[0];
        setSelectedFarm(farmId);

        if (!farmSelected) return;

        dispatch(setEmptyPagedBHReportsArea());
        setAreasFarmSelected(farmSelected.areas);
    };

    const handleSelectArea = (evt) => {
        const areaId = evt.target.value;
        setSelectedArea(areaId);

        dispatch(setEmptyPagedBHReportsArea());
        dispatch(areaById(areaId));
        if (!displayTable) {
            setDisplayTable(true);
        }
    };

    return (
        <Styles.Container>
            <Styles.SelectContainer>
                <FormControl>
                    <InputLabel>Selecione uma fazenda:</InputLabel>
                    <Select
                        onChange={handleSelectAreaByFarm}
                        labelId="select-parameter"
                        value={selectedFarm}
                        autoWidth
                    >
                        {loading && <MenuItem style={{ fontSize: '1.4rem' }}>Carregando fazendas...</MenuItem>}
                        {farms.length
                            && farms.map((farm) => (
                                <MenuItem key={farm.id} value={farm.id} style={{ fontSize: '1.4rem' }}>
                                    {farm.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <FormControl>
                    <InputLabel>Selecione uma área:</InputLabel>
                    <Select
                        onChange={handleSelectArea}
                        labelId="select-parameter"
                        value={selectedArea}
                        disabled={!areasFarmSelected.length}
                        autoWidth
                    >
                        {areasFarmSelected.length ? (
                            areasFarmSelected.map((area) => (
                                <MenuItem key={area.id} value={area.id} style={{ fontSize: '1.4rem' }}>
                                    {area.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem style={{ fontSize: '1.4rem' }}>Selecione uma área</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Styles.SelectContainer>
            {loadingArea && <Skeleton width="100%" height="800" />}

            {displayTable && dataArea && !loadingArea && <TableBh area_id={dataArea.id} />}
        </Styles.Container>
    );
};

export default ReportBhTemplate;
