// Get Farms by Consultant
export function farmsConsultantRequest(consultantId) {
    return {
        type: '@farm/FARMS_CONSULTANT_REQUEST',
        payload: { consultantId },
    };
}

// Get Farms by Customer
export function farmsCustomerRequest(customerId) {
    return {
        type: '@farm/FARMS_CUSTOMER_REQUEST',
        payload: { customerId },
    };
}
export function farmsSuccess(data) {
    return {
        type: '@farm/FARMS_SUCCESS',
        payload: { data },
    };
}

export function farmsError(message) {
    return {
        type: '@farm/FARMS_ERROR',
        payload: {
            message,
        },
    };
}

export function farmDisableById(farmId, user) {
    return {
        type: '@farm/FARMS_DISABLE',
        payload: { farmId, user },
    };
}

export function farmRequestEmployees(id) {
    return {
        type: '@farm/FARM_REQUEST_EMPLOYEES',
        payload: { id },
    };
}

export function employeUpdate(idUser, name, phone, active, id) {
    return {
        type: '@farm/EMPLOYEE_UPDATE',
        payload: {
            idUser,
            name,
            phone,
            active,
            id,
        },
    };
}

export function farmRequestEmployeesSuccess(employees) {
    return {
        type: '@farm/FARM_REQUEST_EMPLOYEES_SUCCESS',
        payload: { employees },
    };
}

export function farmRequestEmployeesError() {
    return { type: '@farm/FARM_REQUEST_EMPLOYEES_ERROR' };
}

export function farmAdd(
    user,
    name,
    cityId,
    consultantId,
    customerId,
    partners,
) {
    return {
        type: '@farm/FARM_ADD',
        payload: {
            user,
            name,
            cityId,
            consultantId,
            customerId,
            partners,
        },
    };
}

export function farmUpdate(
    id,
    user,
    name,
    cityId,
    consultantId,
    customerId,
    partners,
) {
    return {
        type: '@farm/FARM_UPDATE',
        payload: {
            id,
            user,
            name,
            cityId,
            consultantId,
            customerId,
            partners,
        },
    };
}

export function farmAddSuccess() {
    return { type: '@farm/FARM_ADD_SUCCESS' };
}

// Get weather by farm
export function weatherByFarm(farmId, userId, daily) {
    return {
        type: '@farm/WEATHER_BY_FARM',
        payload: { farmId, userId, daily },
    };
}

export function getUserFarms(userId) {
    return {
        type: '@farm/GET_USER_FARMS',
        payload: { userId },
    };
}

export function setEmptyToWeatherData() {
    return {
        type: '@farm/SET_EMPTY_WEATHER_DATA',
    };
}
