import styled, { css } from 'styled-components';

export const Container = styled.div`
    .fullscreen-enabled {
        margin: auto;
        background: white;

        width: ${(props) => props.width}px !important;
        min-width: ${(props) => props.width}px!important;
        height: ${(props) => props.height}px!important;
        min-height: ${(props) => props.height}px!important;
    }

    .fullscreen {
        overflow: auto;
    }

    ${() => css`
        height:98vh;
    `}
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    width: 100%;
    max-width: 1280px;
    margin-inline: auto;
    overflow: auto;

    svg {
        display: none;
        margin: 1rem;
        font-size: 32px;
        cursor: pointer;
        color: black;
        background: white;
    }

    @media screen and (max-width: 768px) {
        svg {
            display: block;
        }
        ${(props) => props.fullScreen && `
        overflow: visible;
        justify-content: center;

        svg {
            position: fixed;
            bottom: 0;
            right: 0;
        }
    `}
    }
`;

export const TableOverflow = styled.div`
    margin-top: 2rem;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    @media screen and (max-width: 768px) {
        ${(props) => props.fullScreen && `
            overflow: visible;
            margin-top: 0;
        `}
    }
`;

export const TableContainer = styled.div`
    max-width: 1280px;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;

    margin-inline: auto;
    margin: auto;
    * {
        cursor: crosshair;
    }

    @media screen and (max-width: 768px) {
        ${(props) => props.fullScreen && `
            position: absolute;
            transform: translate(${props.height + 24}px) rotate(90deg);
            transform-origin: top left;

            canvas {
                width: ${props.width - 16}px !important;
                height: ${props.height}px !important;
                padding: 0rem 0rem 0rem 1rem;
            }
        `}
    }
`;
