import {
    formattedArea, generateDefaultAreaParameters,
} from './calculationFunctions';
import { bhGenerator } from './bhGenerator';

export const calculateBh = async (areaParameters, areaFormatted) => {
    const today = new Date();
    const initial_date = new Date();
    const final_date = today.setDate(today.getDate() + 7);

    const bhs = [];
    const formatted_area = formattedArea(areaFormatted);

    const default_area_parameters = await generateDefaultAreaParameters(areaParameters);
    let index = 0;

    while (initial_date <= final_date) {
        const last_bh = bhs[index - 1];
        const bhObject = bhGenerator(formatted_area, initial_date, last_bh, default_area_parameters);
        bhObject.date = new Date(initial_date).toISOString();
        bhs.push(bhObject);
        index += 1;

        initial_date.setDate(initial_date.getDate() + 1);
    }

    bhs.sort((a, b) => {
        if (b.sowing_days > a.sowing_days) return -1;
        if (b.sowing_days < a.sowing_days) return 1;
        return 0;
    });

    return bhs;
};
