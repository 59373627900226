import { gql } from 'graphql-request';

const AREA_UPDATE = gql`
    mutation areaUpdate(
        $id: ID!,
        $name: String!,
        $farm_id: ID
        $boundary: [CoordinateInput]!
        $soil: SoilInput
        $area_irrigation_system: AreaIrrigationSystemInput
        $area_crops: AreaCropsInput
        $kl: Float
        $ks: Float
        $pas: Float
        $pam: Float
        $time_back_to_one_hundred_percent: Float
    ) {
        areaUpdate(
            id:$id,
            name: $name
            farm_id: $farm_id
            boundary: $boundary
            soil: $soil
            area_irrigation_system: $area_irrigation_system
            area_crops: $area_crops
            kl: $kl
            ks: $ks
            pas: $pas
            pam: $pam
            time_back_to_one_hundred_percent: $time_back_to_one_hundred_percent
        ){
            error
            message
            code
        }
    }
`;

export default AREA_UPDATE;
