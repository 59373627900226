import { gql } from 'graphql-request';

const REMOVE_PARAMETERS = gql`
mutation REMOVE_PARAMETERS($id: ID!) {
  areaParametersRemove(
      id: $id
    ) {
      removed
    }
  }
`;

export default REMOVE_PARAMETERS;
