import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${() => css`
    `}
`;
export const Wrapper = styled.div`
    ${({ theme }) => css`
        margin-bottom:${theme.spacings.xxsmall};
    `}
`;

export const Label = styled.span`
    ${({ theme, borderColor }) => css`
        color:${theme.colors.black};
        font-size:${theme.font.sizes.xxsmall};
        border-left:4px solid ${borderColor};
        padding-left:.4rem;
    `}
`;

export const BulletItem = styled.div`
    ${({ color }) => css`
        width: 10px;
        height: 10px;
        background: ${color};
        border: .05px solid black;
        border-radius: 50px;
        margin-right: 1rem !important;
    `}
`;

export const BulletContainer = styled.div`
    color: black;
    display: flex;
    font-size: 1.5rem;
    align-items: center;
`;
