const GET_CUSTOMER_BY_USER = /* GraphQL */ `
query GET_CUSTOMER($id:ID!){
    users(id:$id){
      customers {
        name
        id
      }
  }
}
`;

export default GET_CUSTOMER_BY_USER;
