export const FRAGMENT_GET_AREA_SUMMARY = `
size
bh_today {
  water_deficit 
}
`;

export const FRAGMENT_GET_AREA = `
id
image_file
active
name
created_at
area_crops {
  crop {
    crop_type {
      id
      name
    }
    culture_name:name
  }
  sowing_date
  cycle_days
  sowing_days
}
size
soil {
  soil_type {
    id
    name
  }
}
farm {
  id
}

boundary {
  data {
      latitude
      longitude
  }
  square{
      latitude
      longitude
  }
  center {
      latitude
      longitude
  }
}

bh_today {
  AFDf:afdf
  AFD:afd
  ATD:atd
  ADD:add
  altitude
  cadRoot:cad_root
  complementarWater:complementar_water
  sowingdays:sowing_days
  irrigation_time
  percentimeter
  waterDeficit: water_deficit
}
`;

export const FRAGMENT_AREA_BY_ID = `
  id
  etc_sum_since
  active
  name
  time_back_to_one_hundred_percent
  area_crops {
    population
    variety
    age
    street_spacing
    variety
    spacing
        
    crop {
      crop_type {
      id,
      name
    }
    culture_name:name
      crop_parameters {
      id
      kc
      phase_percent
      root
      index
    }
    
    name
    growth_type
  }
  
  sowing_date,
  cycle_days,
  sowing_days
  }

  image_file
  size

  farm {
    id
    name
    consultant {
      id
      name
    }
    customer {
      id
      name
    }
  }

  soil {
    soil_type {
      id
      name
    }
    soil_depth
    soil_density
    soil_humidity
    soil_threshold
    tetaCC: teta_cc
    tetaPMP: teta_pmp
    tetaUnit: teta_unit
  }

  area_irrigation_system {
    leaf
    threshold_min
    threshold_max
    drippingFlow:dripping_flow
    drippingSpacing:dripping_spacing
      irrigation_system {
        name,
        active,
        id
      }
    irrigation_type {
      id,
      name
    }
    efficiency
  }

  kl
  ks
  pam
  pas

  boundary {
    data {
      latitude
      longitude
    }
    square{
      latitude
      longitude
    }
    center {
      latitude
      longitude
    }
  }

  forecast {
    precip_intensity
    precip_probabil
    is_day
    type
    max_temp
    label
  }

  water {
    date
    irrigation
    rain
    created_at
  }

  sumTotalRain
  sumTotalIrrigation
  created_at

  weather {
    precipitation
    temp_max
    temp_min
    date
    eto_pm
    altitude
    created_at
  }
`;

export const FRAGMENT_BH = `
    bh {
      effectiveRain:effective_rain
      irrigation
      sowingdays:sowing_days
      irrigation_time
      cadRoot:cad_root
      etc
      atd_percentage
      percentimeter
      deficit
      eto_pm
      kc
      root_depth
      etr
      _date:date
      AFDf:afdf
      AFD:afd
      ATD:atd
      ADD:add
      complementarWater:complementar_water
      _createdAt:created_at
    }
    
    bh_today {
      etc
      AFDf:afdf
      AFD:afd
      ATD:atd
      ADD:add
      cadRoot:cad_root
      altitude
      complementarWater:complementar_water
      sowingdays:sowing_days
      irrigation_time
      percentimeter
    }
`;

export const FRAGMENT_AREA_BH_REPORTS_PAGINATED = `
  bh {
    effectiveRain:effective_rain
    irrigation
    sowingdays:sowing_days
    irrigation_time
    cadRoot:cad_root
    etc
    atd_percentage
    percentimeter
    deficit
    eto_pm
    kc
    root_depth
    etr
    _date:date
    AFDf:afdf
    AFD:afd
    ATD:atd
    ADD:add
    complementarWater:complementar_water
    _createdAt:created_at
    area_id
  }
  water {
    irrigation
    rain
    date
  }
`;

export const FRAGMENT_AVERAGE_TEMP = `
  average_temp
  average_temp_since
`;

export const FRAGMENT_CALCULATE_BH = `
  bh {
      effectiveRain: effective_rain
      irrigation
      sowingdays: sowing_days
      tempoIrriga: irrigation_time
      cadRoot: cad_root
      etc
      eto_pm
      etr
      _date: date
      AFD: afd
      ATD: atd
      ADD: add
      deficit
      aguaComplementar: complementar_water
  }

  weather {
      precipitation
      temp_max
      temp_min
      date
  }

  water {
    irrigation
    rain
    date
  }

  area_irrigation_system {
    efficiency
  }  
`;
