import React, { useCallback, useMemo } from 'react';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryGroup,
    VictoryLabel,
} from 'victory';
import * as Styles from './styles';

const WaterChart = ({ data, handleSelectAreaByFarm }) => {
    const memoData = useMemo(() => {
        const array = [];
        data.forEach((item) => {
            if (item.bh_today != null) {
                array.push({
                    label: item.name,
                    value: item.bh_today.complementarWater,
                    id: item.id,
                });
            } else {
                array.push({
                    label: item.name,
                    value: 0,
                    id: item.id,
                });
            }
        });

        return array.sort((a, b) => a.value - b.value).map((item, index) => ({
            label: `${item.value.toFixed()}`,
            area: item.label.length > 9 ? `${index}-${item.label.slice(0, 9)}...` : `${index}-${item.label}`,
            value: item.value === 0 ? 0 : item.value.toFixed(),
            id: item.id,
        }));
    }, [data]);

    const polygonColor = useCallback((valueComplementarWater) => {
        if (valueComplementarWater === '0') return 'white';
        return '#F44336';
    }, []);

    return (
        <Styles.Container>
            <VictoryChart
                padding={{ left: 90, bottom: 90, top: 20 }}
                height={350}
                domainPadding={{ x: 20 }}
                maxDomain={{ y: 7, x: 7 }}
            >
                <VictoryGroup>
                    <VictoryBar
                        events={
                            [
                                {
                                    eventHandlers: {
                                        onClick: () => [
                                            {
                                                target: 'data',
                                                mutation: (dataProps) => {
                                                    // Envio os dados da area selecionada para o component CardFarm
                                                    handleSelectAreaByFarm(dataProps?.datum);
                                                },
                                            },
                                        ],
                                    },
                                },
                            ]
                        }
                        data={memoData}
                        animate={{
                            duration: 2000,
                            onLoad: { duration: 1000 },
                        }}
                        horizontal
                        x="area"
                        y="value"
                        barWidth={28}
                        style={{
                            data: { fill: ({ datum }) => polygonColor(datum.value), cursor: 'pointer' },
                            labels: {
                                fill: '#333',
                                fontSize: 12,
                                fontWeight: 'bold',
                                cursor: 'pointer',
                            },
                        }}
                    />

                    <VictoryAxis
                        tickValues={memoData.map((item) => String(item.value))}
                        dependentAxis
                        tickLabelComponent={(
                            <VictoryLabel
                                angle={0}
                                textAnchor="start"
                            />
                        )}
                    />

                    <VictoryAxis
                        tickValues={memoData.map((item) => item.area)}
                        tickFormat={memoData.map((item) => item.area.split(/[0-9]+-/)[1])}
                        orientation="left"
                    />
                </VictoryGroup>
            </VictoryChart>

            <div
                style={{
                    display: 'flex',
                    marginTop: '1.8rem',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}
            />
        </Styles.Container>
    );
};

export default WaterChart;
