import React from 'react';
import Layout from '../../components/Layout';
import ReportBhTemplate from '../../components/ReportBhTemplate';
import * as Styles from './styles';

const ReportsBh = () => (
    <Layout>
        <Styles.Container>
            <Styles.WrapperGraphs>
                <Styles.Content>
                    <ReportBhTemplate />
                </Styles.Content>
            </Styles.WrapperGraphs>
        </Styles.Container>
    </Layout>
);

export default ReportsBh;
