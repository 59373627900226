import {
    all, takeLatest, call, put,
} from 'redux-saga/effects';
import Swal from 'sweetalert2';
import history from '../../../services/history';

import {
    requestAreaByUser,
    requestAreaById,
    requestRemoveById,
    requestArchived,
    requestRefresh,
    requestIrrigationAdd,
    requestIrrigationAddAreas,
    requestAreaAdd,
    requestAreaUpdate,
    requestAreaByIdBH,
    requestAreaByIdAverage,
    requestBhCalculateByArea,
    requestByIdNdvi,
    requestByIdNdviLabel,
    requestPagedAreaByUser,
    requestAllCrops,
    queryRequestAreasMap,
    requestPagedAreaWithFilterByUser,
    requestAreaSummaryById,
    requestCustomerByUserId,
    requestAreaByIdBHReports,
} from './utils';
import {
    areaError,
    areaSuccess,
    areaByIdSuccess,
    areaEndRequest,
    areaRefreshSuccess,
    areaById,
    areaAddSuccess,
    areaStartRequestById,
    areaByIdEndRequest,
    areaRequestConsultant,
    areaRequestCustomer,
    areaUpdateSuccess,
    areaIrrigationMessage,
    areaByIdBHSuccess,
    areaByIdBh,
    areaByIdAverage,
    areaByIdAverageSuccess,
    areaStartRequest,
    requestCalculateBhError,
    requestCalculateBhSuccess,
    areaRequestNDVI,
    areaByIdNDVISuccess,
    successPagedAreaByUserId,
    cropsEndRequest,
    cropsRequestError,
    cropsRequestSuccess,
    areasMapSuccess,
    areasMapStartRequest,
    areasMapEndRequest,
    successPagedAreaWithFilterByUserId,
    successAreaSummaryByUserId,
    successCustomerByUser,
    areaBHReportsByIdSuccess,
} from './actions';
import { showModal } from '../modal/actions';
import {
    farmsConsultantRequest,
} from '../farm/actions';

export function* request(action) {
    yield put(areaStartRequest());
    const res = yield call(requestAreaByUser, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
    } else {
        const { users } = res;
        yield put(areaSuccess(users[0]));
    }
    yield put(areaEndRequest());
}

export function* requestAreaSummary(action) {
    const res = yield call(requestAreaSummaryById, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors ? res.response.errors[0].message : 'Um erro aconteceu. Recarregue a página e tente novamente.'));
    }
    yield put(successAreaSummaryByUserId(res.users[0].areas));
}

export function* requestCustomerByUser(action) {
    const res = yield call(requestCustomerByUserId, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors ? res.response.errors[0].message : 'Um erro aconteceu. Recarregue a página e tente novamente.'));
    }
    yield put(successCustomerByUser(res.users[0].customers));
}

export function* requestPagedAreaWithFilterByUserId(action) {
    const res = yield call(requestPagedAreaWithFilterByUser, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors ? res.response.errors[0].message : 'Um erro aconteceu. Recarregue a página e tente novamente.'));
        return;
    }
    const {
        data, newFilter, ordination, filter,
    } = res;
    yield put(successPagedAreaWithFilterByUserId(data.users[0].areas, newFilter, filter, ordination));
}

export function* requestPagedAreaByUserId(action) {
    const res = yield call(requestPagedAreaByUser, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors ? res.response.errors[0].message : 'Um erro aconteceu. Recarregue a página e tente novamente.'));
        return;
    }
    yield put(successPagedAreaByUserId(res.users[0].areas));
}

export function* requestAreasMap(action) {
    yield put(areasMapStartRequest());
    const res = yield call(queryRequestAreasMap, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
    } else {
        const { users } = res;
        yield put(areasMapSuccess(users[0]));
    }
    yield put(areasMapEndRequest());
}

export function* requestCrops(action) {
    const res = yield call(requestAllCrops, action);
    if (!res.ok) {
        yield put(cropsRequestError(res.response.errors[0].message));
    } else {
        const { crops } = res;
        yield put(cropsRequestSuccess(crops));
    }
    yield put(cropsEndRequest());
}

export function* requestById(action) {
    yield put(areaStartRequestById());

    const res = yield call(requestAreaById, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors ? res.response.errors[0].message : 'Um erro aconteceu. Recarregue a página e tente novamente.'));
        history.push('/');
        yield put(areaById(action.payload.id));
    } else if (res.ok && !res.areas.length) {
        yield put(areaError('Um erro aconteceu. Recarregue a página e tente novamente.'));
    } else {
        const { areas } = res;
        yield put(areaByIdSuccess({ ...areas[0], ndvi: [] }));
        yield put(areaByIdBh(action.payload.id));
        yield put(areaByIdAverage(action.payload.id));
        yield put(areaRequestNDVI(action.payload.id));
    }
    yield put(areaByIdEndRequest());
}

export function* requestNDVI(action) {
    const res = yield call(requestByIdNdvi, action);
    const resLabel = yield call(requestByIdNdviLabel, action);
    if (!res.ok) {
        return; // disabled error so it doesn't break the page
    }
    const { ndvi } = res;
    const { ndviLabel } = resLabel;
    yield put(areaByIdNDVISuccess({ ...ndvi, labels: ndviLabel }));

    yield put(areaByIdEndRequest());
}

export function* requestByIdBh(action) {
    const res = yield call(requestAreaByIdBH, action);

    if (!res.ok) {
        yield put(areaError(res.response.errors ? res.response.errors[0].message : 'Um erro aconteceu. Recarregue a página e tente novamente.'));
        history.push('/');
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
        yield put(areaById(action.payload.id));
    } else {
        const { areas } = res;
        yield put(areaByIdBHSuccess(areas[0]));
    }
    yield put(areaByIdEndRequest());
}

export function* requestByIdAverageTempSince(action) {
    const res = yield call(requestAreaByIdAverage, action);

    if (res.ok) {
        const { average_temp, average_temp_since } = res.areas[0];
        yield put(areaByIdAverageSuccess(average_temp, average_temp_since));
    }

    yield put(areaByIdEndRequest());
}

export function* removeById(action) {
    yield put(areaStartRequestById());
    const res = yield call(requestRemoveById, action);

    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        history.push('/');
        Swal.fire('Removida!', 'Área removida com sucesso', 'success');
        const { user } = action.payload;
        yield put(
            user.role.name !== 'Cliente'
                ? areaRequestConsultant(user.id)
                : areaRequestCustomer(user.id),
        );
    }
    yield put(areaByIdEndRequest());
}

export function* refreshArea(action) {
    yield put(areaStartRequestById());
    const res = yield call(requestRefresh, action);

    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        const { areaRefresh } = res;
        yield put(areaRefreshSuccess(areaRefresh));
        yield put(areaByIdBh(action.payload.id));
        yield put(areaByIdAverage(action.payload.id));
        yield put(areaRequestConsultant(action.payload.userId));
    }
    yield put(areaByIdEndRequest());
}

export function* archivedArea(action) {
    yield put(areaStartRequestById());
    const res = yield call(requestArchived, action);

    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        history.push('/');

        const { user, archived } = action.payload;

        if (!archived) Swal.fire('Atualizada!', 'Área atualizada com sucesso.', 'success');
        else Swal.fire('Atualizada!', 'Área atualizada com sucesso.', 'success');

        yield put(
            user.role.name !== 'Cliente'
                ? areaRequestConsultant(user.id)
                : areaRequestCustomer(user.id),
        );
    }
    yield put(areaByIdEndRequest());
}

export function* addIrrigation(action) {
    yield put(areaStartRequestById());
    const res = yield call(requestIrrigationAdd, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        const type = action.payload.rain ? 'Chuva' : 'Irrigação';
        Swal.fire('Adicionado!', `${type} adicionada com sucesso`, 'success');
        yield put(areaById(action.payload._areaId));
        yield put(farmsConsultantRequest(action.payload._createdBy));
        yield put(areaRequestConsultant(action.payload._createdBy));
    }
    yield put(areaByIdEndRequest());
}

export function* addIrrigationAreas(action) {
    yield put(areaIrrigationMessage({ message: '', loading: true, loadingAdd: true }));
    const res = yield call(requestIrrigationAddAreas, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
        yield put(areaIrrigationMessage({ message: 'ERROR', loading: false }));
    } else {
        const type = action.payload.rain ? 'Chuva' : 'Irrigação';
        Swal.fire('Adicionado!', `${type} adicionada com sucesso`, 'success');
        yield put(areaIrrigationMessage({ message: 'SUCCESS', loadingAdd: false, loading: false }));
        yield put(areaRequestConsultant(action.payload._createdBy));
        yield put(farmsConsultantRequest(action.payload._createdBy));
    }
}

export function* addArea(action) {
    const res = yield call(requestAreaAdd, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        const { areaAdd } = res;
        Swal.fire('Adicionado!', 'Área cadastrada com sucesso.', 'success');
        yield put(showModal('ModalArea', { isOpen: false }));
        yield put(areaAddSuccess(areaAdd));
    }
}

export function* updateArea(action) {
    const res = yield call(requestAreaUpdate, action);
    if (!res.ok) {
        yield put(areaError(res.response.errors[0].message));
        yield put(showModal('ModalArea', { isOpen: false, isEdit: false }));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        const { id, user } = action.payload;
        Swal.fire('Atualizado!', 'A área foi atualizada com sucesso.', 'success');
        yield put(showModal('ModalArea', { isOpen: false }));
        yield put(areaById(id));
        yield put(
            user.data.role !== 'Cliente'
                ? areaRequestConsultant(user.data.id)
                : areaRequestCustomer(user.data.id),
        );
        yield put(areaUpdateSuccess());
    }
}

export function* requestCalculateBhByArea(action) {
    const res = yield call(requestBhCalculateByArea, action);
    if (!res.ok) {
        yield put(requestCalculateBhError(res.response.errors[0].message));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        yield put(requestCalculateBhSuccess(res.areas[0]));
    }
}

export function* requestAreaBHByIdReports(action) {
    const res = yield call(requestAreaByIdBHReports, action);

    if (!res.ok) {
        yield put(areaError(res.response.errors ? res.response.errors[0].message : 'Algo aconteceu, tente novamente mais tarde'));
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
        yield put(areaById(action.payload.id));
    } else {
        const { paginatedBHByArea } = res;
        yield put(areaBHReportsByIdSuccess(paginatedBHByArea));
    }
    yield put(areaByIdEndRequest());
}

export default all([
    takeLatest('@area/AREA_CONSULTANT_REQUEST', request),
    takeLatest('@area/AREA_REQUEST_MAP', requestAreasMap),
    takeLatest('@area/AREA_CUSTOMER_REQUEST', request),
    takeLatest('@area/PAGED_AREA_BY_USER_REQUEST', requestPagedAreaByUserId),
    takeLatest('@area/AREA_SUMMARY_BY_USER_ID', requestAreaSummary),
    takeLatest('@area/PAGED_AREA_WITH_FILTER_BY_USER_REQUEST', requestPagedAreaWithFilterByUserId),
    takeLatest('@area/AREA_CROPS_START_REQUEST', requestCrops),
    takeLatest('@area/AREA_REQUEST_NDVI', requestNDVI),
    takeLatest('@area/AREA_BY_ID', requestById),
    takeLatest('@area/AREA_BY_ID_BH', requestByIdBh),
    takeLatest('@area/AREA_BY_ID_AVERAGE_TEMP_SINCE', requestByIdAverageTempSince),
    takeLatest('@area/AREA_BY_ID_ARCHIVED', archivedArea),
    takeLatest('@area/AREA_IRRIGATION_ADD', addIrrigation),
    takeLatest('@area/AREA_IRRIGATION_ADD_AREAS', addIrrigationAreas),
    takeLatest('@area/AREA_ADD', addArea),
    takeLatest('@area/AREA_UPDATE', updateArea),
    takeLatest('@area/AREA_REFRESH', refreshArea),
    takeLatest('@area/AREA_REMOVE_BY_ID', removeById),
    takeLatest('@area/REQUEST_CUSTOMER_BY_USER', requestCustomerByUser),
    takeLatest('@area/REQUEST_CALCULATE_ABH', requestCalculateBhByArea),
    takeLatest('@area/REQUEST_AREA_BH_BY_ID_REPORTS', requestAreaBHByIdReports),
]);
