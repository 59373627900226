import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as Styles from '../styles';
import * as CardStyles from '../../../CardArea/styles';

const CreateSkeleton = () => {
    const typeStyles = { marginRight: '.8rem', padding: '.3rem' };
    const labelStyles = { marginBottom: '.8rem' };
    return (
        <>
            <Skeleton height={200} width="100%" />

            <CardStyles.AreaInfo>
                <Skeleton height={10} width="80%" />
            </CardStyles.AreaInfo>

            <CardStyles.PivotContainer>

                <Styles.InfosContainer>
                    <Skeleton height={18} width="60%" style={labelStyles} />
                    <Skeleton height={16} width="30%" style={labelStyles} />
                    <Skeleton height={14} width="90%" style={labelStyles} />
                    <Skeleton height={14} width="50%" style={labelStyles} />
                    <Skeleton height={14} width="60%" style={labelStyles} />
                    <Skeleton height={14} width="90%" style={labelStyles} />
                    <Skeleton height={14} width="60%" style={labelStyles} />
                </Styles.InfosContainer>

                <CardStyles.ChartContainer>

                    <CardStyles.IconsContainer>
                        <Skeleton circle height={40} width={40} style={typeStyles} />
                        <Skeleton circle height={40} width={40} style={typeStyles} />
                    </CardStyles.IconsContainer>

                    <Skeleton height={130} width="100%" />

                </CardStyles.ChartContainer>

            </CardStyles.PivotContainer>
        </>
    );
};

export default CreateSkeleton;
