import {
    FRAGMENT_AREA_BY_ID, FRAGMENT_AVERAGE_TEMP, FRAGMENT_BH, FRAGMENT_AREA_BH_REPORTS_PAGINATED,
} from '../../fragments/area';

export const GET_AREA_ID = /* GraphQL */ `

query GET_AREA_ID($id:ID!, $filters:AreaFilterInput){
  areas(id:$id,filters:$filters){
    ${FRAGMENT_AREA_BY_ID}
    }
  }
`;

export const GET_AREA_ID_BH = /* GraphQL */ `
query GET_AREA_ID($id:ID!){
  areas(id:$id){
    ${FRAGMENT_BH}
    }
  }
`;

export const GET_PAGINATED_AREA_BH_REPORTS = /* GraphQL */ `
query GET_PAGINATED_AREA_BH_REPORTS_BY_AREA_ID($area_id:ID!, $filters:BHFilterInput!){
  paginatedBHByArea(area_id:$area_id, filters:$filters){
    ${FRAGMENT_AREA_BH_REPORTS_PAGINATED}
    }
  }
`;

export const GET_AREA_ID_AVERAGE = /* GraphQL */ `
query GET_AREA_ID($id:ID!){
  areas(id:$id){
    ${FRAGMENT_AVERAGE_TEMP}
    }
  }
`;
