import styled, { css } from 'styled-components';

export const MobileMenu = styled.div`
    ${({ theme }) => css`
        @media (min-width: 601px) {
            display: none;
        }

        @media (max-width: 600px) {
            position: fixed;
            background-color: ${theme.colors.primary};
            height: 8rem;
            width: 100vw;
            z-index 951;
            padding: 1rem;
            display: flex;
            align-items: center;
        }
    `}
`;

export const Hamburguer = styled.div`
    ${() => css`
        display: block;
        height: 26px;
        width: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 2rem;

        .line {
            display: block;
            height: 4px;
            width: 100%;
            border-radius: 10px;
            background: white;
        }

        .line1 {
            transform-origin: 0% 0%;
            transition: transform 0.4s ease-in-out;
        }
        
        .line2 {
            transition: transform 0.2s ease-in-out;
        }
        
        .line3 {
            transform-origin: 0% 100%;
            transition: transform 0.4s ease-in-out;
        }
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`
        position: fixed;
        background-color: ${theme.colors.primary};

        @media (min-width: 600px) {
            box-shadow: ${theme.box.shadow};
            left: 0px;
            border-top-right-radius: ${theme.border.radius};
            border-bottom-right-radius: ${theme.border.radius};
            width: 70px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: ${theme.spacings.xsmall} 0;
            z-index: 950;
            transition: width 0.15s;

            .user-name{
                display: none;
            }

            .labels{
                display: none;   
            }
        }
        
        &.not-extended-side-menu{
            @media (max-width: 600px) {
                margin-left: -20rem;
            }
        }
      
        &.extended-side-menu{
            width: 18rem;
            z-index: 950;
            transition: width 0.3s;
            .user-name{
                display: block;
                text-align:center;
                font-size: ${theme.font.sizes.xxsmall};
                margin-bottom: ${theme.spacings.xxsmall};
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .labels{
                display: block;
                margin-top: 0.2rem;
                margin-left: ${theme.spacings.xsmall};
                white-space: nowrap;
                overflow: hidden;
                
            }

            @media (max-width: 600px) {
                width: 100%;
                height: 100vh;
                padding-top: 10rem;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    `}
`;

export const ImgPerfil = styled.img`
    ${({ theme }) => css`
        cursor:pointer;
        width: ${'52px !important'};
        height: ${'52px !important'};
        border-radius: 50%;
        object-fit: cover;
        box-shadow: ${theme.box.shadow};
        margin: ${theme.spacings.xxsmall} 0;
        margin-bottom: ${'3.3rem'};
        &.extendedMarginImg{
            margin-bottom: ${theme.spacings.xxsmall};
        }
    `}

`;

export const List = styled.ul`
    ${() => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center};
        align-items: flex-start;
        list-style-type: none;
        
    `}
`;

export const Divider = styled.div`
    ${({ theme }) => css`
        width: 80%;
        height: 1px;
        background-color: ${theme.colors.white};
        opacity: 0.3;
        margin: ${theme.spacings.xsmall} 0.5rem;

        &.content-divider{
            width: 90%;
        }
    `}
`;

export const MenuItem = styled.li`
    ${({ theme }) => css`
        text-align:center;
        display: inline;
        position: relative;
        float: left;
        & img {
            width: 2rem;
            height: 2rem;
        }
        
        & a, div {
            text-decoration:none;
            font-size: ${theme.font.sizes.xxsmall};
            color:#fff;
            display:flex;
            justify-content: center;
            align-items: center;
            padding: ${theme.spacings.xsmall};
            margin: ${theme.spacings.xxsmall};
            border-radius:${theme.border.radius};
            cursor:pointer;
            transition:.5s;

            &:hover{
                transition:.5s;
                background-color:${theme.colors.secondary};
            }
            &.actived-link { 
                background-color:${theme.colors.secondary};
            }
            
        }
        
        &.content-menu{
            width: 100%;
            & a, div {
                justify-content: center;
                margin: ${theme.spacings.xxsmall} ;
            } 
        }
    `}

`;
