import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestPagedAreaWithFilterByUserId } from '../../store/modules/area/actions';
import CardArea from '../CardArea';
import NotFound from '../NotFound';
import CreateSkeleton from '../Skeletons/CardAreaSkeleton/skeleton';
import * as Styles from './styles';

const Area = () => {
    const history = useHistory();
    const { filter } = useSelector((state) => state.area);
    const { pagedAreaWithFilter } = useSelector((state) => state.area);
    const { data: user } = useSelector((state) => state.user);
    const {
        loadingArea, areas, hasMorePage, loadingFirstPage,
    } = pagedAreaWithFilter;

    const handleSelected = useCallback((id) => {
        history.push(`pivot/${id}`);
    }, [history]);

    const memoFilter = useCallback((area) => {
        if (filter === 'hideArchived') {
            return area.active && (
                <CardArea
                    key={area.id}
                    onClick={() => handleSelected(area.id)}
                    data={area}
                />
            );
        } if (filter === 'showArchived') {
            return !area.active && (
                <CardArea
                    key={area.id}
                    onClick={() => handleSelected(area.id)}
                    data={area}
                />
            );
        }

        return (
            <CardArea
                key={area.id}
                onClick={() => handleSelected(area.id)}
                data={area}
            />
        );
    }, [filter, handleSelected]);

    const dispatch = useDispatch();
    const nextPage = async () => {
        dispatch(requestPagedAreaWithFilterByUserId(
            Number(user.id),
            pagedAreaWithFilter.currentPage,
            pagedAreaWithFilter.currentFilter,
            pagedAreaWithFilter.currentOrdination,
        ));
    };

    return (
        <>
            <InfiniteScroll
                dataLength={areas.length}
                next={nextPage}
                hasMore={hasMorePage}
            >
                {!areas.length && !hasMorePage && !loadingFirstPage
                    ? <NotFound text="Nenhuma área cadastrada" />
                    : (
                        <Styles.ListArea>
                            {!loadingFirstPage && areas.map((area) => memoFilter(area))}
                            {loadingArea
                                && (
                                    Array(4)
                                        .fill()
                                        .map((item, index) => (
                                            <div key={index}>
                                                <CreateSkeleton />
                                            </div>
                                        )))}
                        </Styles.ListArea>
                    )}
            </InfiniteScroll>

        </>
    );
};

export default Area;
