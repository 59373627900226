import { FRAGMENT_GET_WEATHER_BY_FARM } from '../../fragments/farms';

const GET_WEATHER_BY_FARM = /* GraphQL */`
    query GET_WEATHER_BY_FARM($farmId:ID!, $daily:Boolean){
        farmsForecasts(farm_id:$farmId, daily: $daily){
            ${FRAGMENT_GET_WEATHER_BY_FARM}
        }
    }
`;

export default GET_WEATHER_BY_FARM;
