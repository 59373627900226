import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${() => css`
    `}
`;

export const SelectContainer = styled.div`
    ${() => css`
        display:grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-auto-flow: row;
        width:50%;

        .MuiFormControl-root {
            min-width: 160px;
            font-size: 1.4rem;
            margin-right: 1rem;

            label {
                font-size: 1.4rem;
            }

            .MuiInputBase-root {
                color: #00586B;
            }

            .MuiSelect-root {
                font-size: 1.4rem;
                min-height: 16px;
            }
        }

        @media (max-width: 600px){
            width: 100%;
            grid-template-columns: 1fr;

            .MuiFormControl-root {
                margin-bottom: 1rem;
            }
        }
    `}
`;
export const GroupSelect = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom:${theme.spacings.small};
        margin-right:${theme.spacings.small};

        label {
            color:${theme.colors.gray};
            margin-bottom:${theme.spacings.xxsmall};
            font-size:${theme.font.sizes.xxsmall};
        }

        select {
            background:#fff;
            border:.3rem solid #eee;
            border:0;
            box-shadow:2px 2px 5px rgba(0,0,0,0.5);
            width: 100%;
            padding:${theme.spacings.xsmall};
            border-radius:1rem;
        }

        @media (max-width: 600px) {
            select {
                width: 107%;
            }
        }
    `}
`;
