export const FRAGMENT_LIST_PARAMETERS = `
parameters {
  id
  name
  description
  treshold
  created_at
  updated_at
}
`;
