import {
    all, takeLatest, call, put,
} from 'redux-saga/effects';
import Swal from 'sweetalert2';
import { userAddFinished, userLoading } from '../user/actions';
import {
    farmsError, farmsSuccess, farmsConsultantRequest, farmsCustomerRequest,
    farmRequestEmployeesError, farmRequestEmployeesSuccess,
} from './actions';
import {
    requestFarmsConsultant, requestFarmsCustomer, requestDisableFarm,
    requestEmployees, requestFarmAdd, requestFarmUpdate, requestWeatherByFarm, requestEmployeeUpdate, getUserFarms,

} from './utils';

export function* request(action) {
    if (action.type === '@farm/FARMS_CONSULTANT_REQUEST') {
        const res = yield call(requestFarmsConsultant, action);
        if (!res.ok) {
            yield put(farmsError(res.response.errors[0].message));
        } else {
            const { users } = res;
            yield put(farmsSuccess(users[0].farms));
        }
    } else if (action.type === '@farm/FARMS_CUSTOMER_REQUEST') {
        const res = yield call(requestFarmsCustomer, action);

        if (!res.ok) {
            yield put(farmsError(res.response.errors[0].message));
        } else {
            const { users } = res;
            yield put(farmsSuccess(users[0].farms));
        }
    }
}

export function* disableById(action) {
    const res = yield call(requestDisableFarm, action);
    if (!res.ok) {
        yield put(farmsError(res.response.errors[0].message));
    } else {
        const { removed } = res.farmRemove;

        if (removed) {
            const { user } = action.payload;
            yield put(
                user.role.name !== 'Cliente'
                    ? farmsConsultantRequest(user.id)
                    : farmsCustomerRequest(user.id),
            );
            Swal.fire('Removida!', 'Fazenda removida com sucesso!', 'success');
        } else {
            Swal.fire('Ops, algo aconteceu!', 'Tente novamente mais tarde', 'error');
        }
    }
}

export function* employees(action) {
    const res = yield call(requestEmployees, action);
    if (!res.ok) {
        yield put(farmRequestEmployeesError(res.response.errors[0].message));
    } else {
        const { employees } = res;
        yield put(farmRequestEmployeesSuccess(employees));
    }
}

export function* farmAdd(action) {
    const res = yield call(requestFarmAdd, action);
    if (!res.ok) {
        yield put(farmsError(res.response.errors[0].message));
    } else {
        const { user } = action.payload;
        yield put(
            user.role.name !== 'Cliente'
                ? farmsConsultantRequest(user.id)
                : farmsCustomerRequest(user.id),
        );
        Swal.fire('Adicionada!', 'Fazenda adicionada com sucesso', 'success');
    }
}

export function* farmUpdate(action) {
    const res = yield call(requestFarmUpdate, action);
    if (!res.ok) {
        yield put(farmsError(res.response.errors[0].message));
    } else {
        const { user } = action.payload;
        yield put(
            user.role.name !== 'Cliente'
                ? farmsConsultantRequest(user.id)
                : farmsCustomerRequest(user.id),
        );
        Swal.fire('Atualizada!', 'A fazenda foi atualizada com sucesso.', 'success');
    }
}

export function* updateEmployee(action) {
    yield put(userLoading());
    const res = yield call(requestEmployeeUpdate, action);
    if (!res.ok) {
        yield put(farmsError(res.response.errors[0].message));
    } else {
        yield call(requestEmployees, action);
    }
    yield put(userAddFinished());
}

export function* weatherRequest(action) {
    yield put(farmsCustomerRequest(action.payload.userId));

    const res = yield call(requestWeatherByFarm, action);
    if (!res.ok) {
        yield put(farmsError(res.response.errors[0].message));
    } else {
        yield put({
            type: '@farm/FARM_GET_WEATHER',
            payload: res.farmsForecasts,
        });
    }
}

export function* getUserFarmsByUserId(action) {
    const res = yield call(getUserFarms, action);

    if (!res.ok) {
        yield put(farmsError(res.response.errors[0].message));
    } else {
        yield put({
            type: '@farm/GET_USER_FARMS_BY_ID',
            payload: res.users,
        });
    }
}

export default all([
    takeLatest('@farm/FARMS_CONSULTANT_REQUEST', request),
    takeLatest('@farm/FARMS_CUSTOMER_REQUEST', request),
    takeLatest('@farm/FARMS_DISABLE', disableById),
    takeLatest('@farm/FARM_REQUEST_EMPLOYEES', employees),
    takeLatest('@farm/EMPLOYEE_UPDATE', updateEmployee),
    takeLatest('@farm/FARM_ADD', farmAdd),
    takeLatest('@farm/FARM_UPDATE', farmUpdate),
    takeLatest('@farm/WEATHER_BY_FARM', weatherRequest),
    takeLatest('@farm/GET_USER_FARMS', getUserFarmsByUserId),
]);
