import React, { useEffect, useState, useRef } from 'react';

import moment from 'moment';
import { FaEdit, FaTrash } from 'react-icons/fa';

import { DataGrid } from '@mui/x-data-grid';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import client from '../../../graphql/client';

import * as Styles from './styles';
import ContainerTableSkeleton from '../../Skeletons/ContainerTableSkeleton';
import ContainerPlanningSkeleton from '../../Skeletons/ContainerPlanningSkeleton';
import { calculateBh } from '../../../utils/functions/irrigationPlanning/calculateBh';

import {
    one_second, textToSeconds, excelToSeconds, secondsToExcel,
} from '../../../utils/excelTime';

export const GET_IRRIGATIONS = `
query IRRIGATION_PLANNINGS($area_id: ID!){
    irrigationPlannings(area_id: $area_id) {
      id
      area {
        id
        name
        time_back_to_one_hundred_percent
        area_irrigation_system {
            leaf
        }
      }
      created_by {
        name
        email
      }
      created_at
      updated_at
    }
  }
`;

export const GET_IRRIGATIONS_BH = `
query IRRIGATION_PLANNINGS_BH($id: ID!){
    irrigationPlanningBalanceSimulation(id: $id) {
        sowing_days
        cad
        cad_dif
        cad_root
        kc
        etr
        date
        atd_percentage
        balance
        atd
    }
  }
`;

export const GET_IRRIGATIONS_WATER = `
query IRRIGATION_WATER($id: ID!){
    irrigationPlanningWaters(irrigation_planning_id: $id) {
        date
        irrigation
        rain
    }
  }
`;

export const ADD_IRRIGATIONS_WATER = `
mutation ADD_IRRIGATIONS_WATER($fields: IrrigationPlanningWaterInput!) {
    irrigationPlanningWaterAdd(
      fields: $fields
    ) {
      error
      message
      code
    }
  }
`;

export const ADD_IRRIGATION_PLANNING = `
mutation ADD_IRRIGATION_PLANNING($fields: IrrigationPlanningInput!) {
    irrigationPlanningAdd(fields: $fields) {
        id
        area {
            id
            name
            time_back_to_one_hundred_percent
                area_irrigation_system {
                    leaf
                }
            }
        created_at
        updated_at
        created_by {
            email
            name
        }
    }
  }
`;

export const REMOVE_IRRIGATION_PLANNING = `
mutation REMOVE_IRRIGATION_PLANNING($id: ID!) {
    irrigationPlanningRemove(id: $id) {
        removed
    }
}
`;

function formatDateTime(dateString) {
    return moment.utc(dateString).format('DD/MM/YYYY HH:mm');
}
function formatDate(dateString) {
    return moment.utc(dateString).format('DD/MM/YYYY');
}

function formatSystemDate(dateString) {
    return moment.utc(dateString).format('YYYY-MM-DD');
}

const ListPlannings = ({ irrigationPlannings, selectPlanning, removePlanning }) => {
    if (irrigationPlannings?.length > 0) {
        return (
            <Styles.ItemList>
                <Styles.Items style={{
                    background: '#fafafa', color: 'black', fontWeight: 'bold', height: '84px',
                }}
                >
                    <Styles.Item>ID</Styles.Item>
                    <Styles.Item>Criado por</Styles.Item>
                    <Styles.Item>Inicio da previsão</Styles.Item>
                    <Styles.Item>Fim da previsão</Styles.Item>
                    <Styles.Item>Atualizado em</Styles.Item>
                    <Styles.Item>Ações</Styles.Item>
                </Styles.Items>
                {irrigationPlannings.map((planning) => {
                    const createdAt = new Date(new Date(planning.created_at).getTime() - 10800000).toISOString();
                    const updatedAt = new Date(new Date(planning.updated_at).getTime() - 10800000).toISOString();
                    return (
                        <Styles.Items key={planning.id}>
                            <Styles.Item>
                                #
                                {planning.id}
                            </Styles.Item>
                            <Styles.Item>{planning.created_by.name}</Styles.Item>
                            <Styles.Item>{formatDateTime(createdAt)}</Styles.Item>
                            <Styles.Item>
                                {moment
                                    .utc(createdAt)
                                    .add('days', 7)
                                    .format('DD/MM/YYYY HH:mm')}
                            </Styles.Item>
                            <Styles.Item>{formatDateTime(updatedAt)}</Styles.Item>
                            <Styles.ItemEdit onClick={() => selectPlanning(planning)}>
                                <FaEdit />
                            </Styles.ItemEdit>
                            <Styles.ItemEdit onClick={() => removePlanning(planning.id)}>
                                <FaTrash />
                            </Styles.ItemEdit>
                        </Styles.Items>
                    );
                })}
            </Styles.ItemList>
        );
    }
    return <>Nenhum planejamento encontrado para essa área</>;
};

const handleReleChange = (value, leaf) => (!value || value === 0 ? value : parseFloat((leaf / value) * 100).toFixed(3));

export const generateDefaultAreaParameters = async (
    default_area_parameters,
) => {
    if (!default_area_parameters.length) return [];

    const default_formatted_area_parameters = default_area_parameters.map(
        (default_area_parameter) => ({
            parameter: default_area_parameter.parameter.name,
            value: default_area_parameter.value,
            start_date: new Date(default_area_parameter.start_date),
            end_date: new Date(default_area_parameter.end_date),
        }),
    );

    return default_formatted_area_parameters;
};

let irrigationWater = [];

const ModalIrrigationPreview = ({ area_id, area, area_parameters }) => {
    const areaParameters = area_parameters;
    const areasToUpdate = area;
    const { data: user } = useSelector((state) => state.user);

    const [irrigationPlannings, setIrrigationPlannings] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loadingPlanningList, setLoadingPlanningList] = useState(true);

    const [selectedPlanning, setSelectedPlanning] = useState();
    const [balanceData, setBalanceData] = useState([]);
    const [columns, setColumns] = useState([
        {
            field: 'name',
            headerName: 'Name',
            width: 180,
        },
    ]);
    const [rows, setRows] = useState([]);
    const refHours = useRef([]);
    const refMinutes = useRef([]);
    const timeDurationArray = [];

    const removePlanning = (id) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Não será possível reverter após excluir!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                client
                    .request(REMOVE_IRRIGATION_PLANNING, {
                        id,
                    })
                    .then((data) => {
                        if (data?.irrigationPlanningRemove?.removed) {
                            Swal.fire({
                                title: 'Excluído!',
                                text: `O planejamento #${id} foi excluído`,
                                icon: 'success',
                            });
                            setIrrigationPlannings((plannings) => plannings.filter((planning) => planning.id !== id));
                        } else {
                            Swal.fire({
                                title: 'Erro',
                                text: 'O planejamento não foi excluído, tente novamente mais tarde',
                                icon: 'error',
                            });
                        }
                    });
            }
        });
    };

    const addPlanning = () => {
        client
            .request(ADD_IRRIGATION_PLANNING, {
                fields: {
                    area_id,
                    created_by: Number(user.id),
                },
            })
            .then((data) => {
                if (data?.irrigationPlanningAdd?.error) {
                    Swal.fire({
                        title: 'Erro',
                        text: data?.irrigationPlanningAdd?.message,
                        icon: 'error',
                    });
                } else {
                    setIrrigationPlannings((plannings) => [...plannings, data?.irrigationPlanningAdd]);
                }
            });
    };

    const addWaters = (date, irrigation, irrigation_planning) => {
        // TODO: Add date/time change

        client
            .request(ADD_IRRIGATIONS_WATER, {
                fields: {
                    irrigation_planning_id: Number(irrigation_planning.id),
                    irrigation,
                    date,
                },
            })
            .then((data) => {
                if (data?.irrigationPlanningWaterAdd?.error) {
                    Swal.fire({
                        title: 'Erro',
                        text: data?.irrigationPlanningWaterAdd?.message,
                        icon: 'error',
                    });
                }
            });
    };

    useEffect(() => {
        setLoadingPlanningList(true);
        client
            .request(GET_IRRIGATIONS, {
                area_id,
            })
            .then((data) => {
                setIrrigationPlannings(data?.irrigationPlannings);
            })
            .catch(() => setIrrigationPlannings([]))
            .finally(() => setLoadingPlanningList(false));
    }, []);

    useEffect(() => {
        if (selectedPlanning?.id) {
            client
                .request(GET_IRRIGATIONS_WATER, {
                    id: selectedPlanning?.id,
                })
                .then((data) => {
                    irrigationWater = data?.irrigationPlanningWaters;
                })
                .catch(() => {
                    irrigationWater = [];
                });

            client
                .request(GET_IRRIGATIONS_BH, {
                    id: selectedPlanning?.id,
                })
                .then((data) => {
                    setBalanceData(data?.irrigationPlanningBalanceSimulation.sort((a, b) => {
                        if (b.sowing_days > a.sowing_days) return -1;
                        if (b.sowing_days < a.sowing_days) return 1;
                        return 0;
                    }) || []);
                });
        } else {
            setBalanceData([]);
        }
    }, [selectedPlanning?.id]);

    const recalculateValues = async () => {
        const area_formatted = {
            ...areasToUpdate,
            water: irrigationWater,
            created_at: new Date(),
        };

        const bhs = await calculateBh(areaParameters, area_formatted);

        setBalanceData(bhs);
    };

    let lastChangedIndex = null;
    let editingCell = false;

    const handleTimeChange = async (e) => {
        const isKeyPressValid = e.pointerType === 'mouse' || e.keyCode === 13 || e.keyCode === 9;

        if (isKeyPressValid && editingCell && lastChangedIndex !== null) {
            timeDurationArray[lastChangedIndex].hours = refHours.current[lastChangedIndex].value;
            timeDurationArray[lastChangedIndex].minutes = refMinutes.current[lastChangedIndex].value * 1 ? refMinutes.current[lastChangedIndex].value : timeDurationArray[lastChangedIndex].minutes;

            const { hours, minutes, date } = timeDurationArray[lastChangedIndex];

            let excelTime = secondsToExcel(textToSeconds(hours, minutes, 0));
            if (excelTime < 0) { excelTime = 0; }
            const time = selectedPlanning.area.time_back_to_one_hundred_percent * one_second;
            const irrigationMM = time === 0 ? 0 : (selectedPlanning.area.area_irrigation_system.leaf * excelTime) / time;

            let irrigation = irrigationWater.find((irrigation) => formatSystemDate(new Date(irrigation.date)) === formatSystemDate(new Date(date)));

            if (!irrigation) {
                irrigationWater.push({ date: new Date(date).toISOString().replace(/[^T]*$/, '00:00:00.000Z'), irrigation: irrigationMM, rain: 0 });
                irrigation = irrigationWater.find((irrigation) => formatSystemDate(new Date(irrigation.date)) === formatSystemDate(new Date(date)));
            }
            const irrigationIndex = irrigationWater.indexOf(irrigation);

            addWaters(date, irrigationMM, selectedPlanning);
            irrigationWater[irrigationIndex].irrigation = irrigationMM;
            await recalculateValues();

            editingCell = false;
            if (e.keyCode !== 9 && document.activeElement?.name !== 'minutes') {
                document.activeElement.blur();
            }
        }
    };

    const mainUseEffect = async () => {
        const columnData = [
            {
                field: 'Legenda',
                headerName: '',
                width: 200,
                editable: true,
            },
        ];

        const rowData = [
            { id: 1, Legenda: 'Dias após plantio' },
            { id: 2, Legenda: 'CAD radicular (mm)' },
            { id: 3, Legenda: '%CC' },
            { id: 4, Legenda: 'CAD atual (mm)' },
            { id: 5, Legenda: 'Irrigar (mm)' },
            { id: 6, Legenda: '% percentímetro' },
            { id: 7, Legenda: 'Duração irrigação (h)' },
            { id: 8, Legenda: 'Kc' },
            { id: 9, Legenda: 'ETr (mm)' },
        ];

        const dateArray = [];
        const irrigationArray = [];
        const time = selectedPlanning.area.time_back_to_one_hundred_percent * one_second;

        await Promise.all(balanceData.map(async (balance, index) => {
            const date = formatSystemDate(new Date(balance.date));
            dateArray.push(date);

            const irrigationMM = irrigationWater?.find((water) => formatSystemDate(water.date) === date)?.irrigation || 0;
            const excelSeconds = time === 0 ? 0 : irrigationMM * time / selectedPlanning.area.area_irrigation_system.leaf;
            const [hours, minutes] = excelToSeconds(excelSeconds);

            const formatMinutes = minutes < 10 ? `0${minutes.toString()}` : minutes;

            const timeObject = {
                hours,
                minutes: formatMinutes,
                date,
            };
            timeDurationArray.push(timeObject);

            await columnData.push({
                field: date,
                headerName: formatDate(balance.date),
                width: 180,
                editable: true,
                type: 'number',
                renderCell: (params) => {
                    if (params.id === 7) {
                        return (
                            <>
                                <Styles.TimeDurationForm minutesLength={refMinutes.current[index] ? refMinutes.current[index].value.length : 40}>
                                    <input
                                        type="number"
                                        min="0"
                                        name="hours"
                                        defaultValue={timeDurationArray[index].hours}
                                        ref={(element) => { refHours.current[index] = element; }}
                                        onChange={() => {
                                            lastChangedIndex = index;
                                            if (!editingCell) { editingCell = true; }
                                        }}
                                    />
                                    <label>h</label>
                                    <input
                                        type="text"
                                        name="minutes"
                                        maxLength={2}
                                        defaultValue={timeDurationArray[index].minutes}
                                        ref={(element) => { refMinutes.current[index] = element; }}
                                        onChange={() => {
                                            lastChangedIndex = index;
                                            if (!editingCell) { editingCell = true; }
                                        }}
                                    />
                                    <label>min</label>
                                </Styles.TimeDurationForm>
                            </>
                        );
                    }
                },
            });

            if (refHours.current[index]) {
                refHours.current[index].value = timeDurationArray[index].hours;
                refMinutes.current[index].value = timeDurationArray[index].minutes;
            }

            irrigationArray.push(irrigationMM);

            const releValue = !irrigationMM || irrigationMM === 0
                ? irrigationMM
                : parseFloat((selectedPlanning.area.area_irrigation_system.leaf * 100) / irrigationMM).toFixed(2);

            rowData[0][date] = balance.sowing_days;
            rowData[1][date] = balance.cad_root.toFixed(2);
            rowData[2][date] = balance.atd_percentage.toFixed(2);
            rowData[3][date] = balance.cad.toFixed(2);
            rowData[4][date] = irrigationMM;
            rowData[5][date] = releValue || 0;
            // rowData[6][date] = balance.duracao;
            rowData[7][date] = balance.kc.toFixed(2);
            rowData[8][date] = balance.etr.toFixed(2);
            // row 9 = irrigation graph

            document.addEventListener('click', handleTimeChange);
            document.addEventListener('keydown', handleTimeChange);
        }));

        // TODO: change cells 5 and 6 according to row 4
        setColumns(columnData);
        setRows(rowData);

        if (loading) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (balanceData.length > 0) {
            mainUseEffect();
        }
    }, [balanceData]);

    const defaultTheme = createTheme();
    const useStyles = makeStyles(
        () => {
            const backgroundColor = '#EBF2F3';
            return {
                root: {
                    '& .MuiDataGrid-cell--editable': {
                        backgroundColor,
                    },
                },
            };
        },
        { defaultTheme },
    );

    const classes = useStyles();

    return (
        <Styles.Wrapper>
            <Styles.TitleContainer>
                <h2 style={{ color: 'black' }}>{`Planejamento de irrigação - ${area.name} - ${area.farm.name}`}</h2>
                {!selectedPlanning?.id && (
                    <Styles.Button onClick={addPlanning}>Adicionar Planejamento</Styles.Button>
                )}
            </Styles.TitleContainer>

            {selectedPlanning?.id
        && (loading ? (
            <ContainerTableSkeleton />
        ) : (
            <Styles.ContainerTable>
                <div
                    style={{ margin: '1rem 0', color: '#00586b', cursor: 'pointer' }}
                    onClick={() => {
                        setSelectedPlanning(undefined);
                        setLoading(true);
                    }}
                >
                    {'<'}
                    {' '}
                    voltar
                </div>
                <DataGrid
                    className={classes.root}
                    rows={rows}
                    columns={columns}
                    hideFooter
                    isCellEditable={(params) => (params.row.id === 5
                        || params.row.id === 6
                    ) && params.field !== 'Legenda'}
                    onCellEditCommit={async (params) => {
                        const { id, field } = params;
                        const value = params.value < 0 ? 0 : params.value;

                        let irrigation = irrigationWater.find((irrigation) => formatSystemDate(new Date(irrigation.date)) === formatSystemDate(new Date(field)));

                        if (!irrigation) {
                            irrigationWater.push({ date: new Date(field).toISOString().replace(/[^T]*$/, '00:00:00.000Z'), irrigation: value, rain: 0 });
                            irrigation = irrigationWater.find((irrigation) => formatSystemDate(new Date(irrigation.date)) === formatSystemDate(new Date(field)));
                        }

                        const irrigationIndex = irrigationWater.indexOf(irrigation);

                        if (id === 5) {
                            addWaters(field, value, selectedPlanning);
                            irrigationWater[irrigationIndex].irrigation = value;
                        } else {
                            const newIrrigation = Number(
                                handleReleChange(
                                    value,
                                    selectedPlanning.area.area_irrigation_system.leaf,
                                ),
                            );
                            addWaters(field, newIrrigation, selectedPlanning);
                            irrigationWater[irrigationIndex].irrigation = newIrrigation;
                        }

                        await recalculateValues();
                    }}
                />
            </Styles.ContainerTable>
        ))}

            {!selectedPlanning?.id && (
                <>
                    <Styles.ContainerGraphs>
                        {loadingPlanningList ? (
                            <ContainerPlanningSkeleton />
                        ) : (
                            <ListPlannings
                                irrigationPlannings={irrigationPlannings}
                                selectPlanning={setSelectedPlanning}
                                removePlanning={removePlanning}
                            />
                        )}
                    </Styles.ContainerGraphs>
                </>
            )}
        </Styles.Wrapper>
    );
};

export default ModalIrrigationPreview;
