import styled, { css } from 'styled-components';
import {
    Field,
} from 'formik';

export const Wrapper = styled.div`
    padding: 1rem;
`;

export const Footer = styled.div`
   ${({ theme }) => css`
        height: 72px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: ${theme.colors.white};
        border-top: 1px solid ${theme.colors.lightGray};
    `}
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const Button = styled.button`
   ${({ theme }) => css`
        outline:none;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:${theme.border.radius};
        padding:${theme.spacings.xsmall};
        background-color:${theme.colors.primary};
        margin-bottom:${theme.spacings.small};
        color:${theme.colors.white};
        font-size:${theme.font.sizes.xxsmall};

        &:hover {
            transition:.5s;
            opacity: 0.8;
        }
   `}
`;

export const LinkButton = styled.button`
   ${({ theme }) => css`
        outline:none;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:${theme.border.radius};
        padding:${theme.spacings.xsmall};
        background-color: transparent;
        margin-bottom:${theme.spacings.small};
        color:${theme.colors.primary};
        font-size:${theme.font.sizes.xxsmall};

        &:hover {
            transition:.5s;
            opacity: 0.8;
        }
   `}
`;

export const Errors = styled.span`
    display: flex;
    text-align: left;
    height: 15px;
    width: 100%;
    color: #f1003b;
    font-size: 1.2rem;
    padding:0 0.8rem;
    margin-top: 0;
`;

export const TextField = styled(Field)`
        width: 97.5%;
        font-size: 1.5rem !important;
`;

export const ErrorMessageSelect = styled.div`
    font-size: 1.071rem;
    margin-top: 0.63rem;
    text-align: left;
    font-family: Lato,Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-weight: 400;
    line-height: 1.66;
    color: #f44336;
`;

export const Table = styled.table`
   ${({ theme }) => css`
        border-spacing: 0;
        border: 1px solid ${theme.colors.lightGray};
        font-size: ${theme.font.sizes.xxsmall};
        color: ${theme.colors.gray};
        width: 100%;
        
        th { font-size: ${theme.font.sizes.xsmall}; }
        th, td {
            padding: .6rem 1rem;
            height: 52px;
            vertical-align: middle;
            text-align: left;
            border-bottom: 1px solid ${theme.colors.lightGray};
        }

        & .id-container {
            display: flex;
            align-items: center;
            width: 54px !important;
        }

        & .actions-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: 120px !important; 
            max-width: 120px !important; 
            width: 120px !important;

            & > .action {
                height: 24px !important;
                margin: 4px;

                &.add { background-color: ${theme.colors.green}; }
                &.person { background-color: ${theme.colors.primary}; }
                &.delete { background-color: ${theme.colors.danger}; }
            }
        }
    `}
`;
