import React, { useState } from 'react';
import { TextField as MuiTextField } from 'formik-material-ui';
import {
    Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';

import {
    Wrapper,
    SelectIrrigation,
    Options,
    TextField,
    TimeContainer,
    WrapperInput,
    useStyles,
} from './styles';

import CardGraph from '../../CardGraph';
import Selector from '../../Selector';

import * as AREA from '../../../utils/helpers/area';
import * as MASK from '../../../utils/helpers/mask';

import { getSoilIcon } from '../../../utils/functions/area';

import iconWateringCan from '../../../assets/svgs/icon_watering_can.svg';
import { textToSeconds } from '../../../utils/excelTime';

const Irrigation = ({ form }) => {
    const modal = useSelector((state) => state.modal);
    const [, setType] = useState('');
    const [tooltipGotejamento, setTooltipGotejamento] = useState(false);
    const [tooltipLepa, setTooltipLepa] = useState(false);
    const classes = useStyles();

    const style = {
        marginTop: 7,
    };

    const { culture, irrigationSystem, irrigationType } = form.values;

    const handleChangeTime = (e, values, setFieldValue) => {
        const { value, name } = e.target;
        const timeHash = { hour: values.hour || 0, minute: values.minute || 0 };
        timeHash[name] = value;

        setFieldValue(name, timeHash[name]);
        const total_seconds = textToSeconds(form.values.hour, form.values.minute);

        setFieldValue('time_back_to_one_hundred_percent', total_seconds);
    };

    const handleIrrigationSelect = (type, index) => {
        if (culture === 'Anual') {
            if (index === 1) {
                setTooltipGotejamento(true);
                return;
            } if (index === 2) {
                setTooltipLepa(true);
                return;
            }
        } else if (index !== 1) {
            form.setFieldValue('drippingSpacing', null);
            form.setFieldValue('drippingFlow', null);
            if (index === 0 || index === 3) {
                form.setFieldValue('pas', null);
                form.setFieldValue('pam', null);
                form.setFieldValue('kl', null);
                form.setFieldValue('leaf', null);
            }
        } else if (!form.values.ks && modal.props.isEdit && index === 1) {
            const { value } = getSoilIcon(form.values.soilType);
            form.setFieldValue('ks', value);
        }
        form.setFieldValue('irrigationType', type);
        form.setFieldValue('efficiency', AREA.irrigationType[index].value * 100);
        setType(type);
    };

    const isThresoldMaxGreatherThanThresoldMin = () => {
        const min = parseFloat(form.values.threshold_min);
        const max = parseFloat(form.values.threshold_max);

        if (max > 1 || max < 0) {
            form.errors.threshold_max = 'Valores permitidos apenas entre 0 e 1';
            return false;
        }
        if (min > 1 || min < 0) {
            form.errors.threshold_min = 'Valores permitidos apenas entre 0 e 1';
            return false;
        }

        if (max > min) {
            delete form.errors.threshold_max;
            delete form.errors.threshold_min;
            return true;
        }

        form.errors.threshold_max = 'O Limiar máximo precisar ser superior ao limiar mínimo';
        form.errors.threshold_min = 'O Limiar máximo precisar ser superior ao limiar mínimo';
        return false;
    };

    const calculateLeaf = () => {
        if (!form.values.drippingSpacing || !form.values.drippingFlow || !form.values.street) {
            form.values.leaf = '';
            return form.values.leaf;
        }

        const result = parseFloat(form.values.drippingFlow.toString().replace(',', '.')) / (parseFloat(form.values.drippingSpacing.toString().replace(',', '.'))
            * parseFloat(form.values.street.toString().replace(',', '.')));

        if (Number.isFinite(result)) {
            const aux = Math.round(result * 100) / 100;
            form.values.leaf = aux.toString();
            return form.values.leaf;
        }
        form.values.leaf = '';
        return form.values.leaf;
    };

    const calculateKl = () => {
        if (!form.values.pas || !form.values.pam) {
            form.values.kl = '';
            return form.values.kl;
        }

        const pas = parseFloat(form.values.pas.toString().replace(',', '.'));
        const pam = parseFloat(form.values.pam.toString().replace(',', '.'));

        if (pas > pam) {
            const result = (0.1 * (pas ** 0.5));
            if (Number.isFinite(result)) {
                form.values.kl = (Math.round(result * 100) / 100).toString();
                return form.values.kl;
            }
            form.values.kl = '';
            return form.values.kl;
        }

        const result = (0.1 * (pam ** 0.5));
        if (Number.isFinite(result)) {
            form.values.kl = (Math.round(result * 100) / 100).toString();
            return form.values.kl;
        }
        form.values.kl = '';
        return form.values.kl;
    };

    return (
        <CardGraph
            title="Irrigação"
            svgPath={iconWateringCan}
        >
            <Wrapper>
                {irrigationSystem === 'Irrigado' && (
                    <>
                        <SelectIrrigation>
                            <span>Selecione o tipo de Irrigação</span>

                            <Options>
                                {
                                    AREA.irrigationType.map((item, index) => (
                                        <Selector
                                            key={index}
                                            on={item.type === form.values.irrigationType
                                                && !(form.values.culture === 'Anual' && (index === 1 || index === 2))}

                                        >
                                            {form.values.culture === 'Anual' && (index === 1 || index === 2)
                                                ? (
                                                    <>
                                                        {index === 1

                                                            && (
                                                                <Tooltip
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}
                                                                    open={tooltipGotejamento}
                                                                    onClose={() => setTooltipGotejamento(false)}
                                                                    placement="top"
                                                                    title="Irrigação localizada para cultivo anual não disponível no momento."
                                                                    arrow
                                                                    classes={{
                                                                        tooltip: classes.customTooltip,
                                                                        arrow: classes.customArrow,
                                                                    }}
                                                                >
                                                                    <img
                                                                        onClick={() => handleIrrigationSelect(item.type, index)}
                                                                        src={item.icon}
                                                                        alt={item.label}
                                                                        style={{ opacity: 0.2 }}
                                                                    />

                                                                </Tooltip>
                                                            )}

                                                        {index === 2

                                                            && (
                                                                <Tooltip
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}
                                                                    open={tooltipLepa}
                                                                    onClose={() => setTooltipLepa(false)}
                                                                    placement="top"
                                                                    title="Irrigação localizada para cultivo anual não disponível no momento."
                                                                    arrow
                                                                    classes={{
                                                                        tooltip: classes.customTooltip,
                                                                        arrow: classes.customArrow,
                                                                    }}
                                                                >
                                                                    <img
                                                                        onClick={() => handleIrrigationSelect(item.type, index)}
                                                                        src={item.icon}
                                                                        alt={item.label}
                                                                        style={{ opacity: 0.2 }}
                                                                    />

                                                                </Tooltip>
                                                            )}

                                                    </>
                                                )
                                                : (
                                                    <img
                                                        onClick={() => handleIrrigationSelect(item.type, index)}
                                                        src={item.icon}
                                                        alt={item.label}
                                                    />
                                                )}

                                            <span style={{ opacity: (form.values.culture === 'Anual' && (index === 1 || index === 2)) ? 0.2 : 1 }}>
                                                {item.label}
                                            </span>
                                        </Selector>
                                    ))
                                }
                            </Options>

                        </SelectIrrigation>
                        <WrapperInput>
                            <Grid container spacing={2}>
                                {irrigationType === 'Pivô central'
                                    && (
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="leaf"
                                                    label="Lâmina (percentímetro a 100%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('leaf', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('leaf', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskLeaf,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br />
                                                <span>Tempo de Volta (h)</span>
                                                <TimeContainer>
                                                    <TextField
                                                        component={MuiTextField}
                                                        name="hour"
                                                        label="horas"
                                                        type="number"
                                                        onBlur={(e) => {
                                                            form.setFieldTouched('hour', true);
                                                            handleChangeTime(e, form.values, form.setFieldValue);
                                                        }}
                                                    />
                                                    <TextField
                                                        component={MuiTextField}
                                                        name="minute"
                                                        label="minutos"
                                                        type="number"
                                                        onBlur={(e) => {
                                                            form.setFieldTouched('minute', true);
                                                            handleChangeTime(e, form.values, form.setFieldValue);
                                                        }}
                                                    />
                                                </TimeContainer>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="efficiency"
                                                    label="Eficiência (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('efficiency', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('efficiency', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}

                                {irrigationType === 'Gotejamento' && culture === 'Perene'
                                    && (

                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="drippingSpacing"
                                                    label="Espaçamento entre gotejadores (m)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('drippingSpacing', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('drippingSpacing', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskSpacing,
                                                    }}

                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="drippingFlow"
                                                    label="Vazão do gotejador (l/h)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('drippingFlow', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('drippingFlow', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskDrippingFlow,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="pas"
                                                    label="PAS (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('pas', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('pas', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="pam"
                                                    label="PAM (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('pam', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('pam', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    value={calculateLeaf() ? `${calculateLeaf()} mm/h` : form.values.leaf}
                                                    name="leaf"
                                                    label="Lâmina (mm/h)"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br />
                                                <span>Tempo de Volta (h)</span>
                                                <TimeContainer>
                                                    <TextField
                                                        component={MuiTextField}
                                                        name="hour"
                                                        label="horas"
                                                        type="number"
                                                        onBlur={(e) => {
                                                            form.setFieldTouched('hour', true);
                                                            handleChangeTime(e, form.values, form.setFieldValue);
                                                        }}
                                                    />
                                                    <TextField
                                                        component={MuiTextField}
                                                        name="minute"
                                                        label="minutos"
                                                        type="number"
                                                        onBlur={(e) => {
                                                            form.setFieldTouched('minute', true);
                                                            handleChangeTime(e, form.values, form.setFieldValue);
                                                        }}
                                                    />
                                                </TimeContainer>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    value={calculateKl() ? `${calculateKl() * 100} %` : form.values.kl}
                                                    name="kl"
                                                    label="Kl (%)"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="ks"
                                                    label="Ks (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('ks', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('ks', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="efficiency"
                                                    label="Eficiência (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('efficiency', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('efficiency', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                        </>

                                    )}

                                {irrigationType === 'Pivô central (LEPA)' && culture === 'Perene'
                                    && (
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="pas"
                                                    label="PAS (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('pas', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('pas', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="pam"
                                                    label="PAM (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('pam', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('pam', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="leaf"
                                                    label="Lâmina (percentímetro a 100%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('leaf', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('leaf', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskLeaf,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br />
                                                <span>Tempo de Volta (h)</span>
                                                <TimeContainer>
                                                    <TextField
                                                        component={MuiTextField}
                                                        name="hour"
                                                        label="horas"
                                                        type="number"
                                                        onBlur={(e) => {
                                                            form.setFieldTouched('hour', true);
                                                            handleChangeTime(e, form.values, form.setFieldValue);
                                                        }}
                                                    />
                                                    <TextField
                                                        component={MuiTextField}
                                                        name="minute"
                                                        label="minutos"
                                                        type="number"
                                                        onBlur={(e) => {
                                                            form.setFieldTouched('minute', true);
                                                            handleChangeTime(e, form.values, form.setFieldValue);
                                                        }}
                                                    />
                                                </TimeContainer>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    value={calculateKl() ? `${calculateKl() * 100} %` : form.values.kl}
                                                    name="kl"
                                                    label="Kl (%)"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="efficiency"
                                                    label="Eficiência (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('efficiency', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('efficiency', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}

                                {irrigationType === 'Aspersão convencional'
                                    && (
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="leaf"
                                                    label="Lâmina (percentímetro a 100%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('leaf', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('leaf', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskLeaf,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br />
                                                <span>Tempo de Volta (h)</span>
                                                <TimeContainer>
                                                    <TextField
                                                        component={MuiTextField}
                                                        name="hour"
                                                        label="horas"
                                                        type="number"
                                                        onBlur={(e) => {
                                                            form.setFieldTouched('hour', true);
                                                            handleChangeTime(e, form.values, form.setFieldValue);
                                                        }}
                                                    />
                                                    <TextField
                                                        component={MuiTextField}
                                                        name="minute"
                                                        label="minutos"
                                                        type="number"
                                                        onBlur={(e) => {
                                                            form.setFieldTouched('minute', true);
                                                            handleChangeTime(e, form.values, form.setFieldValue);
                                                        }}
                                                    />
                                                </TimeContainer>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    component={MuiTextField}
                                                    name="efficiency"
                                                    label="Eficiência (%)"
                                                    onBlur={(e) => {
                                                        form.setFieldTouched('efficiency', true);
                                                        if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('efficiency', parseFloat(e.target.value).toString());
                                                    }}
                                                    InputProps={{
                                                        inputComponent: MASK.maskPercent,
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}

                            </Grid>
                            {modal.props.isEdit
                                && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={style}
                                                component={MuiTextField}
                                                name="threshold_min"
                                                label="Limiar Mínimo (0 a 1)"
                                                onBlur={(e) => {
                                                    form.setFieldTouched('threshold_min', true);
                                                    if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('threshold_min', parseFloat(e.target.value).toString());
                                                }}
                                                InputProps={{
                                                    inputComponent: MASK.maskWithThresold,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                component={MuiTextField}
                                                name="threshold_max"
                                                label="Limiar Máximo (0 a 1)"
                                                onBlur={(e) => {
                                                    form.setFieldTouched('threshold_max', true);
                                                    if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('threshold_max', parseFloat(e.target.value).toString());
                                                }}
                                                InputProps={{
                                                    inputComponent: MASK.maskWithThresold,
                                                }}
                                                validate={isThresoldMaxGreatherThanThresoldMin()}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                        </WrapperInput>
                    </>
                )}
            </Wrapper>

        </CardGraph>
    );
};

export default Irrigation;
