import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import ModalArea from './ModalArea';
import ModalParameters from './ModalParameters';
import ModalCustomer from './ModalCustomer';
import ModalMap from './ModalMap';
import IconSvg from '../IconSvg';

import * as Styles from './styles';
import IconClose from '../../assets/svgs/icon_close.svg';

import { hideModal } from '../../store/modules/modal/actions';
import { userClearState } from '../../store/modules/user/actions';
import ModalIrrigationPreview from './ModalIrrigationPreview';

const Modals = () => {
    const [showAlert, setShowAlert] = useState(false);

    const modal = useSelector((state) => state.modal);
    const areaParameters = useSelector((state) => state.areaParameters);
    const area = useSelector((state) => state.area);
    const { data: user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        if (modal.name === 'ModalArea' && showAlert) {
            Swal.fire({
                title: 'Você tem certeza?',
                text: 'Após fechar o modal, você irá perder todos os dados.',
                icon: 'warning',
                allowOutsideClick: false,
                showCloseButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Sim',
                confirmButtonColor: '#f1003b',
                reverseButtons: true,
                padding: 32,
                preConfirm: () => {
                    dispatch(hideModal('', { isOpen: false, fullscreen: false }));
                },
            });
        } else if (modal.name === 'ModalCustomer') {
            dispatch(hideModal('', { isOpen: false, fullscreen: false }));
            dispatch(userClearState(user));
        } else {
            dispatch(hideModal('', { isOpen: false, fullscreen: false }));
        }
    };

    useEffect(() => {
        const body = window.document.querySelector('body');
        const { href } = document.location;
        const isPivotOpen = href.includes('pivot');
        body.style.overflow = modal.props.isOpen || isPivotOpen ? 'hidden' : 'auto';
    }, [modal.props.isOpen]);

    if (!modal.props.isOpen) return true;

    return (
        <Styles.Modal>
            <Styles.Wrapper fullscreen={modal.props.fullscreen}>
                <Styles.Header>
                    <div onClick={handleCloseModal}>
                        <IconSvg svgPath={IconClose} />
                    </div>
                </Styles.Header>
                <Styles.Container>
                    <>
                        {modal.name === 'ModalArea' && <ModalArea setShowAlert={setShowAlert} handleCloseModal={handleCloseModal} />}
                        {modal.name === 'ModalCustomer' && <ModalCustomer />}
                        {modal.name === 'ModalMap' && <ModalMap />}
                        {modal.name === 'ModalParameters' && <ModalParameters selected_area_id={modal.props.selected_area_id} />}
                        {modal.name === 'ModalIrrigationPreview' && <ModalIrrigationPreview area_parameters={areaParameters.areaParametersData} area={area.dataArea} area_id={modal.props.selected_area_id} />}
                    </>
                </Styles.Container>
            </Styles.Wrapper>
        </Styles.Modal>
    );
};

export default Modals;
