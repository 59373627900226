import React, {
    useEffect, useMemo, useState,
} from 'react';
import { format } from 'date-fns';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import { Paper } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import * as Styles from './styles';
import {
    requestPagedAreaBHById,
    setLoadingBHData,
} from '../../../../store/modules/area/actions';
import client from '../../../../graphql/client';
import GET_FARM_REPORTSBH from '../../../../graphql/queries/farm/getFarmReportsBH';

const TableBh = ({ area_id }) => {
    const { dataArea, pagedBHReportsArea } = useSelector((state) => state.area);
    const { hasMorePage, loadingBHData } = pagedBHReportsArea;

    const [loadingMore, setLoadingMore] = useState(false);
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isDownloading, setIsDownloading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentPage(1);
        dispatch(requestPagedAreaBHById(area_id, currentPage));
    }, [area_id]);

    const loadNextPage = async () => {
        if (loadingMore && hasMorePage) {
            const nextPageNumber = currentPage + 1;

            await dispatch(setLoadingBHData(true));
            await dispatch(requestPagedAreaBHById(area_id, nextPageNumber));

            setCurrentPage(nextPageNumber);
            setLoadingMore(false);
        }
    };

    useEffect(() => {
        loadNextPage();
    }, [loadingMore]);

    const handleScroll = () => {
        const tableContainer = document.getElementById('table-container');
        if (
            tableContainer
            && tableContainer.scrollTop + tableContainer.clientHeight
            >= tableContainer.scrollHeight - 10
            && tableContainer.scrollTop > 0
            && !loadingMore
            && hasMorePage
            && !loadingBHData
        ) {
            setLoadingMore(true);
        }
    };

    const onScroll = () => {
        handleScroll();
    };

    const showLabels = useMemo(() => {
        if (dataArea && dataArea?.area_irrigation_system) {
            const irrigationType = dataArea.area_irrigation_system?.irrigation_type?.name;

            switch (irrigationType) {
            case 'Pivô central':
                return false;

            case 'Pivô central LEPA':
                return true;

            case 'Gotejamento':
                return true;

            default:
                return false;
            }
        }

        return false;
    }, [dataArea]);

    const createData = (
        date,
        sowingDays,
        kc,
        root_depth,
        hundred,
        humidity_actual,
        cad_root,
        ATD,
        eto_pm,
        etc,
        etr,
        deficit,
        complementarwater,
        efficiency,
        tempoIrrigarOrPercentimenter,
        irrigation,
        rain,
        kl,
        ks,
        pam,
        pas,
    ) => ({
        date,
        sowingDays,
        kc,
        root_depth,
        hundred,
        humidity_actual,
        cad_root,
        ATD,
        eto_pm,
        etc,
        etr,
        deficit,
        complementarwater,
        efficiency,
        tempoIrrigarOrPercentimenter,
        irrigation,
        rain,
        kl,
        ks,
        pam,
        pas,
    });

    const findIrrigation = (dateBh) => {
        const bhWater = pagedBHReportsArea.water.find((water) => {
            const waterDate = new Date(water.date.split('T')[0]);
            const dateBhDate = new Date(dateBh.split('T')[0]);
            return waterDate.toLocaleDateString() === dateBhDate.toLocaleDateString();
        });
        return (
            bhWater || {
                irrigation: 0,
                rain: 0,
            }
        );
    };

    useEffect(() => {
        setRows((prevRows) => {
            const newRows = [...prevRows];

            if (pagedBHReportsArea.bh.length) {
                pagedBHReportsArea.bh.forEach((bh) => {
                    const bhDate = format(new Date(bh._date), 'dd/MM/yyyy');
                    const existingRow = newRows.find((row) => row.date === bhDate);

                    if (!existingRow) {
                        newRows.push(
                            createData(
                                bhDate,
                                bh?.sowingdays,
                                bh?.kc,
                                bh?.root_depth,
                                100,
                                ((bh?.ATD * 100) / bh?.cadRoot)?.toFixed(1),
                                bh?.cadRoot,
                                bh?.ATD?.toFixed(1),
                                bh?.eto_pm?.toFixed(1),
                                bh?.etc?.toFixed(1),
                                bh?.etr?.toFixed(1),
                                bh?.deficit?.toFixed(1),
                                bh?.complementarWater?.toFixed(1),
                                dataArea?.area_irrigation_system?.efficiency,
                                showLabels ? bh?.irrigation_time : bh?.percentimeter,
                                findIrrigation(bh._date)?.irrigation,
                                findIrrigation(bh._date)?.rain,
                                showLabels && dataArea.kl,
                                showLabels && dataArea.ks,
                                showLabels && dataArea.pam,
                                showLabels && dataArea.pas,
                            ),
                        );
                    }
                });
            }

            return newRows;
        });
    }, [area_id, currentPage, pagedBHReportsArea.bh.length]);

    const downloadBHReportOnClick = async () => {
        setIsDownloading(true);

        const xlsx_file_url = await client.request(GET_FARM_REPORTSBH, {
            area_id: Number(area_id),
        });

        window.location.href = xlsx_file_url.exportAreaDataToSpredsheet;

        setIsDownloading(false);
    };

    return (
        <Styles.Container>
            <Styles.Button disabled={isDownloading} onClick={downloadBHReportOnClick}>{isDownloading ? 'Baixando...' : 'Baixar Relatório'}</Styles.Button>
            <TableContainer
                id="table-container"
                style={{ maxHeight: 800, overflow: 'auto' }}
                component={Paper}
                onScroll={onScroll}
            >

                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="left">Dias desde a semeadura</TableCell>
                            <TableCell align="left">Kc</TableCell>
                            <TableCell align="left">Profundidade Radicular(cm)</TableCell>
                            <TableCell align="left">CAD Máxima (%)</TableCell>
                            <TableCell align="left">Umidade Atual (%)</TableCell>
                            <TableCell align="left">CAD Máxima (mm)</TableCell>
                            <TableCell align="left">Umidade Atual (mm)</TableCell>
                            <TableCell align="left">ETo (mm)</TableCell>
                            <TableCell align="left">ETc (mm)</TableCell>
                            <TableCell align="left">ETr (mm)</TableCell>
                            <TableCell align="left">Défict (mm)</TableCell>
                            <TableCell align="left">Lâmina a irrigar (mm)</TableCell>
                            <TableCell align="left">Eficiência (%)</TableCell>
                            {showLabels ? (
                                <TableCell align="left">Tempo a irrigar</TableCell>
                            ) : (
                                <TableCell align="left">Percentímetro</TableCell>
                            )}
                            <TableCell align="left">Irrigação (mm)</TableCell>
                            <TableCell align="left">Chuva (mm)</TableCell>
                            {showLabels && (
                                <>
                                    <TableCell align="left">Kl</TableCell>
                                    <TableCell align="left">Ks</TableCell>
                                    <TableCell align="left">PAM(%)</TableCell>
                                    <TableCell align="left">PAS(%)</TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.date}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.sowingDays}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.kc}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.root_depth}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.hundred}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.humidity_actual}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.cad_root}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.ATD}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.eto_pm}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.etc}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.etr}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.deficit}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.complementarwater}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.efficiency}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.tempoIrrigarOrPercentimenter}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                    {row.irrigation}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.rain}
                                </TableCell>
                                {showLabels && (
                                    <>
                                        <TableCell component="th" scope="row">
                                            {row.kl}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.ks}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.pam}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.pas}
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}

                        {loadingBHData && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={50} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Styles.Container>
    );
};

export default TableBh;
