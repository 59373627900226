export const one_second = 0.0000115740740740741;

export const secondsToText = (total_seconds) => {
    if (!total_seconds) return [0, 0, 0];
    const total_min = total_seconds / 60;
    const hours = Math.floor(total_min / 60);
    const minutes = Math.floor(total_min % 60);
    const seconds = Math.floor(total_seconds - (hours * 60 * 60) - (minutes * 60));

    return [hours, minutes, seconds];
};

export const textToSeconds = (hour, minute = 0, second = 0) => {
    const hourSeconds = Math.floor(hour) * 60 * 60;
    const minuteSeconds = Math.floor(minute) * 60;

    return hourSeconds + minuteSeconds + Math.floor(second);
};

export const excelToSeconds = (excel) => {
    const seconds = excel / one_second;

    return secondsToText(seconds);
};

export const secondsToExcel = (seconds) => seconds * one_second;
