import styled, { css } from 'styled-components';
import Container from '../../components/Container';

export const CustomContainer = styled(Container)`
`;

export const Wrapper = styled.div`
   ${() => css`
      display:flex;
      flex:1;
      height:100%;
      align-items: flex-start;
  `}
`;

export const Map = styled.div`
   ${({ theme }) => css`
        position:relative;
        margin:0 auto;
        z-index:0;
        width:90%;
        height:200px;
        margin-bottom:${theme.spacings.small};
  `}
`;

export const ContainerCards = styled.div`
   ${({ theme }) => css`
    display:flex;
    align-items: center;
    justify-content: center;
    padding-left:${theme.spacings.small};

    @media screen and (max-width: 600px) {
      overflow-x: scroll;
      overflow-y: hidden;
      width: 100%;
      height: 100%;
      align-items: left;
      justify-content: left;
      padding-top: 1rem;
      padding-left: 0;
    }
  `}
`;

export const InfoTemperature = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FiltersContainer = styled.div`
   ${({ theme }) => css`
    display:flex;
    align-items: center;
    justify-content: center;
    padding-left:${theme.spacings.small};

    @media screen and (max-width: 600px){
      flex-direction: column;
      padding-left: 0;
    }
  `}
`;

export const FormControl = styled.div`
  ${({ theme }) => css`
    display:flex;
    flex:1;
    align-items:flex-start;
    flex-direction:row;
    justify-content:flex-start;
    margin-top:${theme.spacings.xsmall};
    margin-left:1rem;

    .auto-complete {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .label {
        position: absolute;
        color: #000;
        font-size: 1.2rem;
        top: 0;
        left: 0;
        bottom: 0;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    label {
        svg {
             margin-right:${theme.spacings.xxsmall};
        }
        
      display:flex;
      align-items:center;
      font-size:${theme.font.sizes.xxsmall};
      color:${theme.colors.black};
      margin-bottom:${theme.spacings.xxsmall};
    }

    input {
        margin-right:${theme.spacings.xxsmall};
      }

      select {
      width:100%;
      background-color: transparent;
      padding:.9rem;
      border-bottom:.1rem solid #ddd;
      border-bottom-color:rgba(0, 0, 0, 0.23);
      color:${theme.colors.black};
      padding:1.5rem;
      border-radius:.3rem;
      margin-right:${theme.spacings.xxsmall};
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding:4px;
    }

    .MuiTypography-body1 {
      font-size:${theme.font.sizes.xxsmall};
    }

      .MuiOutlinedInput-notchedOutline {
          border:0;
          border-bottom:.1rem solid #ddd;
          border-bottom-color:rgba(0, 0, 0, 0.23);
          border-radius:0;

          &:focus {
            border-bottom-color:rgba(0, 0, 0, 0.23);
          }
        }

        #combo-box {
        font-size:${theme.font.sizes.xxsmall};
      }

      .filters {
        display: flex;
        flex-direction: column;
        width: 30%;
        display: flex;
        margin-left: ${theme.spacings.small};
        margin-top: 5rem;

        @media screen and (max-width: 600px) {
          margin: 1rem 0;
          width: 100%;
        }
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        padding: 0;
        margin: .5rem 0; 
        display: flex;
        flex-direction: column;
      }
  `}
`;

export const OrderBy = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowIcon = styled.div`
  color: black;
`;

export const PWASwal = styled.div`
margin-top: 1rem;
  text-align: left;

  h2 {
    font-size: 24px !important;
    strong {
      font-size: 24px;
    }
  }

  p {
    margin-top: 3px;
    font-size: 16px;

    strong {
      margin-bottom: -6px;
      padding: 3px;
      border-radius: 3px;
      background: white;
    }
  }

  svg {
    margin-bottom: -6px;
    padding: 3px;
    border-radius: 3px;
    background: white;
    margin-left: 4px;
    font-size: 24px;
    color: #057BFF;
  }

  div {
    margin-top: 1rem;
  }
`;
