import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
    ${({ theme, color }) => css`
        display:flex;
        align-items: center;
        justify-content: center;
        border-bottom:3px solid ${color};
        box-shadow:2px 2px 10px rgba(0,0,0,0.1);
        border-radius: ${theme.border.radius};
        height: 8rem;
        max-width: 20rem;
        min-width: 10rem;
        padding: ${theme.spacings.xxsmall};
        margin: ${theme.spacings.xxxsmall};
        width:fit-content;

        ${media.greaterThan('medium')`
            width: 100%;
        `}

        ${media.lessThan('medium')`
            flex-direction: column;
        `}
    `}
`;

export const Count = styled.div`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.large};
        color:${theme.colors.black};
        margin-right:${theme.spacings.xxsmall};

        ${media.lessThan('medium')`
            margin-right:0;
        `}
    `}
`;

export const Label = styled.div`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.xxsmall};
        color:${theme.colors.gray};
        text-align: center;
    `}
`;
