import React from 'react';

import * as AreaStyles from '../../Area/styles';
import CreateSkeleton from './skeleton';

const CardAreaSkeleton = () => (
    <>
        <AreaStyles.ListArea className="list-area-skeleton">
            {Array(4)
                .fill()
                .map((item, index) => (
                    <div key={index}>
                        <CreateSkeleton />
                    </div>
                ))}
        </AreaStyles.ListArea>
    </>
);

export default CardAreaSkeleton;
