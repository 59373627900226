import styled, { css } from 'styled-components';
import Input from '../../../Input';

export const Container = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-evenly;

    @media (max-width: 600px) {
        flex-direction: column;
        width: 100%;
        form {
            div {
                width: 100%;
            }
        }
    }
`;

export const Form = styled.form`
        display:flex;
        flex-direction:column;
        align-items:flex-start;

        @media (max-width: 600px) {
            width: 100%;
        }
`;

export const Img = styled.img`
        width:'150px';
        height:'150px';
        border-radius:150px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.5);
`;

export const CustomInput = styled(Input)`
    ${({ theme }) => css`
        color:${theme.colors.primary};
    `}

    @media(max-width: 600px) {
        width: 100%;
    }
`;

export const InputWrapper = styled.div`
    display:flex;
    flex-direction:column;

    @media(max-width: 600px) {
        width: 100%;
    }
`;

export const InputContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;

    & img {
        width: 20px;
        height: 20px;
    }
`;

export const InputContent = styled.div`
    ${({ theme }) => css`
        width:100%;
        & input {
            width: 97.5%;
            height: 40px;
            outline:none;
            border: 0;
            border-bottom:1px solid #EEEEEE;
            padding: 0 1.2rem;
            margin: 0.4rem;
            color:${theme.colors.primary};
            &:focus {
                transition:.1s;
                border-bottom:1px solid ${theme.colors.primary};
            }
        }
    `}
`;

export const InputErrors = styled.span`
    display:flex;
    height: 15px;
    width: 100%;
    color: #f1003b;
    font-size: 1.2rem;
    padding:0 0.8rem;
    margin: 0.8rem;
    margin-top: 0;
`;
