import React from 'react';
import {
    Container, Label, BulletItem, BulletContainer,
} from './styles';

const LegendChart = ({ color, title }) => (
    <Container>
        <Label borderColor={color}>{title}</Label>
    </Container>
);

const Bullet = ({ color, children }) => (
    <BulletContainer>
        <BulletItem color={color} />
        <span>{children}</span>
    </BulletContainer>
);

export default LegendChart;
export { Bullet };
