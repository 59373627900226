import {
    all, takeLatest, call, put,
} from 'redux-saga/effects';
import Swal from 'sweetalert2';
import {
    areaParametersByIdError, areaParametersByIdSuccess,
    parametersAddError, parametersAddSuccess,
    parametersRemoveError, parametersRemoveSuccess,
} from './actions';
import {
    requestParameterAdd, requestAreaParameters, requestParameterRemove,
} from './utils';

export function* areaParameters(action) {
    const res = yield call(requestAreaParameters, action);
    if (!res.ok) {
        yield put(areaParametersByIdError(res.response.errors[0].message));
    } else {
        const { areaParameters: _areaParameters } = res;
        yield put(areaParametersByIdSuccess(_areaParameters));
    }
}

export function* parameterAdd(action) {
    const res = yield call(requestParameterAdd, action);
    if (!res.ok) {
        yield put(parametersAddError(res.response.errors[0].message));
        Swal.fire('Erro!', res.response.errors[0].message);
    } else {
        const response = action.payload;
        yield put(
            parametersAddSuccess(response),
        );
        Swal.fire('Sucesso!', 'Parametros da área adicionados com sucesso', 'success');
        const areaResponse = yield call(requestAreaParameters, ({ payload: { id: parseInt(response.area_ids[0]) } }));
        if (!areaResponse.ok) {
            yield put(areaParametersByIdError(areaResponse.response.errors[0].message));
        } else {
            const { areaParameters: _areaParameters } = areaResponse;
            yield put(areaParametersByIdSuccess(_areaParameters));
        }
    }
}

export function* parameterRemove(action) {
    const res = yield call(requestParameterRemove, action);
    if (!res.ok) {
        yield put(parametersRemoveError(res.response.errors[0].message));
        Swal.fire('Erro!', 'não foi possível cadastrar os parametros');
    } else {
        const response = action.payload;
        yield put(
            parametersRemoveSuccess(response),
        );
        Swal.fire('Sucesso!', 'Parametro removido com sucesso', 'success');
        const areaResponse = yield call(requestAreaParameters, ({ payload: { id: parseInt(response.areaId) } }));
        if (!areaResponse.ok) {
            yield put(areaParametersByIdError(areaResponse.response.errors[0].message));
        } else {
            const { areaParameters: _areaParameters } = areaResponse;
            yield put(areaParametersByIdSuccess(_areaParameters));
        }
    }
}

export default all([
    takeLatest('@parameters/PARAMETERS_ADD', parameterAdd),
    takeLatest('@parameters/PARAMETERS_REMOVE', parameterRemove),
    takeLatest('@parameters/AREA_PARAMETERS_BY_ID', areaParameters),
]);
