export function areaParametersById(id) {
    return {
        type: '@parameters/AREA_PARAMETERS_BY_ID',
        payload: { id },
    };
}

export function areaParametersByIdSuccess(data) {
    return {
        type: '@parameters/AREA_PARAMETERS_BY_ID_SUCCESS',
        payload: data,
    };
}

export function areaParametersByIdError(message) {
    return {
        type: '@parameters/AREA_PARAMETERS_BY_ID_ERROR',
        payload: message,
    };
}

export function parametersAdd(area_ids, parameters) {
    return {
        type: '@parameters/PARAMETERS_ADD',
        payload: {
            area_ids, parameters,
        },
    };
}

export function parametersAddSuccess(data) {
    return {
        type: '@parameters/PARAMETERS_ADD_SUCCESS',
        payload: data,
    };
}

export function parametersAddError(message) {
    return {
        type: '@parameters/PARAMETERS_ADD_ERROR',
        payload: {
            message,
        },
    };
}

export function parametersRemove(id, areaId) {
    return {
        type: '@parameters/PARAMETERS_REMOVE',
        payload: {
            id, areaId,
        },
    };
}

export function parametersRemoveSuccess(data) {
    return {
        type: '@parameters/PARAMETERS_REMOVE_SUCCESS',
        payload: data,
    };
}

export function parametersRemoveError(message) {
    return {
        type: '@parameters/PARAMETERS_REMOVE_ERROR',
        payload: {
            message,
        },
    };
}
