import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Wrapper, Container } from './styles';

const ContainerGraphSkeleton = () => (
    <Wrapper>
        <Container>
            <Skeleton height={400} width={600} style={{ marginRight: 100 }} />
        </Container>
    </Wrapper>
);

export default ContainerGraphSkeleton;
