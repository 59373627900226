import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    table {
      th {
        &:first-child {
          position: sticky;
          z-index: 4;
          left: 0;
        }
        font-size: ${theme.font.sizes.xxxsmall};
      }
      td {
        font-size: ${theme.font.sizes.xxsmall};
      }
      thead {
        position: sticky;
        z-index: 5;
      }
      tbody {
        th {
          &:first-child {
            background: white;
          }
        }
      }
    }
  `}
`;

export const Button = styled.button`
   ${({ theme }) => css`
    outline:none;
    display:flex;
    align-items:center;
    justify-content:center;
    margin: -50px auto 50px 50%;
    border-radius:${theme.border.radius};
    padding:${theme.spacings.xsmall};
    background-color: rgb(0, 88, 107);
    color:${theme.colors.white};
    font-size:${theme.font.sizes.xxsmall};

    &:hover {
      transition:.5s;
      opacity: 0.8;

      @media (max-width: 600px) {
        opacity: 1;
      }
    }

    @media (max-width: 850px) {
      width: 132px;
      margin: -50px 338px 50px;
    }

    @media (max-width: 600px) {
      font-size:${theme.font.sizes.xxsmall};
      margin: 10px 10px 10px 0px;
      width: 100%;
    }
`}
`;
