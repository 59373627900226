import { createMuiTheme } from '@material-ui/core/styles';

export default {
    grid: {
        container: '130rem',
        gutter: '3.2rem',
    },
    border: {
        radius: '0.4rem',
    },
    box: {
        shadow: '2px 2px 5px rgba(0,0,0,0.3)',
    },
    font: {
        family:
      "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        bold: 600,
        sizes: {
            xxxsmall: '1.2rem',
            xxsmall: '1.4rem',
            xsmall: '1.6rem',
            small: '1.8rem',
            medium: '2.2rem',
            large: '2.6rem',
            xlarge: '3.4rem',
            xxlarge: '5.2rem',
        },
    },
    colors: {
        primary: '#00586B',
        primaryHover: '#112C39',
        secondary: '#336973',
        mainBg: '#030518',
        green: '#43A047',
        danger: '#f1003b',
        boxBg: '#FAFAFA',
        texts: '#030517',
        white: '#FFFFFF',
        offwhite: '#FAFAFA',
        black: '#000000',
        gray: '#7B7373',
        lightGray: '#EEEEEE',
    },
    spacings: {
        xxxsmall: '0.4rem',
        xxsmall: '0.8rem',
        xsmall: '1.6rem',
        small: '2.4rem',
        xmedium: '2.7rem',
        medium: '3.2rem',
        large: '6.0rem',
        xlarge: '6.4rem',
        xmlarge: '10rem',
        xxlarge: '12.8rem',
    },
};

export const provider = createMuiTheme({
    palette: {
        primary: {
            main: '#00586B',
        },
    },
    typography: {
        fontFamily: [
            'Lato',
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            '"Open Sans"',
            '"Helvetica Neue"',
            'sans-serif',
        ].join(','),
        fontSize: 20,
    },

});
