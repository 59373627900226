import React, { useState, useEffect, useMemo } from 'react';
import {
    Formik, ErrorMessage, Form,
} from 'formik';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';

import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import * as Yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTable, useExpanded } from 'react-table';
import * as Styles from './styles';
import CardGraph from '../../CardGraph';

import IconPin from '../../../assets/svgs/icon_pin.svg';
import IconPeoples from '../../../assets/svgs/icon_peoples.svg';
import ButtonAction from '../../ButtonAction';

import { parametersRequest } from '../../../store/modules/parameters/actions';
import { parametersAdd } from '../../../store/modules/areaParameters/actions';

import { showModal } from '../../../store/modules/modal/actions';

import IconSvg from '../../IconSvg';
import IconDown from '../../../assets/svgs/icon_arrow_down.svg';
import IconRight from '../../../assets/svgs/icon_arrow_right.svg';

import Checkbox from '../../Checkbox';

import { Table as StyledTable } from './styles';

const validationSchemaFields = Yup.object().shape({
    parameter_id: Yup.string().required('Seleção obrigatória'),
    value: Yup.string().required('Campo obrigatório'),
    start_date: Yup.date().required('Campo obrigatório'),
    end_date: Yup.date().required('Campo obrigatório'),
});

function Table({ columns: userColumns, data, renderRowSubComponent }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
    } = useTable(
        {
            columns: userColumns,
            data,
        },
        useExpanded,
    );

    return (
        <StyledTable {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup, index) => (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                            <th key={index} {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                        // Use a React.Fragment here so the table markup is still valid
                        // <React.Fragment key={index} {...row.getRowProps()}>
                        <React.Fragment key={index}>
                            <tr>
                                {row.cells.map((cell, index) => (
                                    <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                            {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                            {row.isExpanded ? (
                                <tr>
                                    <td colSpan={visibleColumns.length}>
                                        {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                                        {renderRowSubComponent({ row })}
                                    </td>
                                </tr>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </StyledTable>
    );
}

const ModalParameters = (props) => {
    const { selected_area_id } = props;
    const { data: parameters, loading } = useSelector((state) => state.parameters);
    const { data: farms } = useSelector((state) => state.farm);
    const parameters_map = useMemo(() => {
        const hashmap = {};
        parameters.forEach((element) => { hashmap[element.id] = element; });

        return hashmap;
    }, [parameters]);

    const { loadingSave } = useSelector((state) => state.areaParameters);
    const [parameterInfo, setParameterInfo] = useState([]);
    const [selectedAreas, setSelectedAreas] = useState([]);

    const dispatch = useDispatch();

    const handleClose = () => {
        if (selectedAreas.length > 0 || parameterInfo.length > 0) {
            Swal.fire({
                title: 'Tem certeza?',
                text: 'Você perderá todos os dados.',
                icon: 'warning',
                allowOutsideClick: false,
                showCloseButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Sim',
                confirmButtonColor: '#f1003b',
                reverseButtons: true,
                padding: 32,
                preConfirm: () => {
                    dispatch(showModal('ModalParameters', { isOpen: false }));
                },
            });
        } else {
            dispatch(showModal('ModalParameters', { isOpen: false }));
        }
    };

    const handleSave = async () => {
        let areas_to_save = [];

        if (!selected_area_id) {
            if (selectedAreas.length === 0 || parameterInfo.length === 0) {
                Swal.fire({
                    title: 'Ops!',
                    text: 'É necessário selecionar ao menos uma área e um parametro',
                    icon: 'warning',
                });
                return;
            }
            areas_to_save = selectedAreas;
        } else {
            areas_to_save = [selected_area_id];
        }

        try {
            await dispatch(parametersAdd(areas_to_save, parameterInfo));

            setSelectedAreas([]);
            setParameterInfo([]);
            dispatch(showModal('ModalParameters', { isOpen: false }));
        } catch (e) {
            console.error('Error', e);
        }
    };

    const handleAddParameter = (values, { resetForm }) => {
        const newParameter = [...parameterInfo];
        newParameter.push({ ...values, parameter_id: parseInt(values.parameter_id) });
        setParameterInfo(newParameter);
        resetForm();
    };

    const handleDeleteParameter = (index) => {
        const newParameter = [...parameterInfo];
        newParameter.splice(index, 1);
        setParameterInfo(newParameter);
    };

    const handleCheck = (areaId) => {
        let newAreas = [...selectedAreas];
        if (selectedAreas.includes(areaId)) {
            newAreas = selectedAreas.filter((element) => element !== areaId);
        } else {
            newAreas.push(areaId);
        }
        setSelectedAreas(newAreas);
    };

    useEffect(() => {
        dispatch(parametersRequest());
    }, []);

    const columns = React.useMemo(
        () => [
            {
                // Make an expander cell
                Header: () => null, // No header
                id: 'expander', // It needs an ID
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span
                        {...row.getToggleRowExpandedProps({
                            style: { paddingLeft: `${row.depth * 2}rem` },
                        })}
                    >
                        {row.isExpanded ? <IconSvg svgPath={IconDown} /> : <IconSvg svgPath={IconRight} />}
                    </span>
                ),
            },
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
        ],
        [],
    );

    return (
        <>
            <Formik
                initialValues={{
                    parameter_id: '',
                    value: '',
                    start_date: '',
                    end_date: '',
                }}
                onSubmit={handleAddParameter}
                validationSchema={validationSchemaFields}
            >
                {(form) => (
                    <Styles.Wrapper>
                        <CardGraph style={{ margin: '5rem 0' }} className="card" title="Insira o parametro" svgPath={IconPin}>
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl style={{ width: '97.5%' }}>
                                            <InputLabel id="select-parameter" htmlFor="select" error={!!(form.errors.parameter_id && form.touched.parameter_id)}>Parametros</InputLabel>
                                            <Select
                                                labelId="select-parameter"
                                                name="parameter_id"
                                                value={form.values.parameter_id}
                                                onChange={(e) => {
                                                    form.handleChange(e);
                                                }}
                                                autoWidth
                                                error={!!(form.errors.parameter_id && form.touched.parameter_id)}
                                                style={{ color: '#00586B' }}
                                            >
                                                {loading || (parameters && parameters.length === 0)
                                                    ? <MenuItem disabled>{loading ? 'Carregando...' : 'Sem parametros cadastrados'}</MenuItem>
                                                    : parameters?.map((item) => (
                                                        <MenuItem key={item.id} value={item.id} style={{ color: '#00586B' }}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            <Styles.ErrorMessageSelect>
                                                <ErrorMessage name="parameter" />
                                            </Styles.ErrorMessageSelect>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Styles.TextField
                                            component={TextField}
                                            name="value"
                                            label={form?.values?.parameter_id && parameters_map[form.values.parameter_id].treshold || 'Valor'}
                                            onBlur={(e) => {
                                                form.setFieldTouched('value', true);
                                                if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('value', parseFloat(e.target.value).toString());
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Styles.TextField
                                            component={TextField}
                                            type="date"
                                            name="start_date"
                                            label="Data de inicio"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Styles.TextField
                                            component={TextField}
                                            type="date"
                                            name="end_date"
                                            label="Data fim"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Styles.Footer>
                                    <Styles.Button
                                        type="submit"
                                        style={{ marginTop: '1rem' }}
                                    >
                                        Adicionar
                                    </Styles.Button>
                                </Styles.Footer>
                            </Form>
                        </CardGraph>
                        {parameterInfo.length > 0
                            && (
                                <CardGraph style={{ margin: '5rem 0' }} className="card" title="Parametros Adicionados" svgPath={IconPeoples}>

                                    <Styles.Table>
                                        <thead>
                                            <tr>
                                                <th>Parâmetro</th>
                                                <th>Valor</th>
                                                <th>Data de inicio</th>
                                                <th>Data fim</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {parameterInfo.map((parameter, index) => (
                                                <tr key={index}>
                                                    <td>{parameters?.find((element) => parseInt(element.id) === parameter.parameter_id)?.name}</td>
                                                    <td>{parameter.value}</td>
                                                    <td>{parameter.start_date}</td>
                                                    <td>{parameter.end_date}</td>
                                                    <td>
                                                        <ButtonAction
                                                            className="action delete"
                                                            onClick={() => handleDeleteParameter(index)}
                                                            iconName="clear"
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Styles.Table>

                                </CardGraph>
                            )}
                        {!selected_area_id && (
                            <CardGraph style={{ margin: '5rem 0' }} className="card" title="Selecione as áreas" svgPath={IconPeoples}>
                                <Table
                                    columns={columns}
                                    data={farms}
                                    renderRowSubComponent={
                                        ({ row }) => (
                                            <Table
                                                columns={[
                                                    {
                                                        Header: () => null,
                                                        id: 'check',
                                                        Cell: ({ cell }) => (
                                                            <Checkbox
                                                                checked={selectedAreas.includes(parseInt(cell.original.id))}
                                                                onClick={() => handleCheck(parseInt(cell.original.id))}
                                                            />
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Image',
                                                        id: 'image',
                                                        Cell: ({ cell }) => (
                                                            <img alt="area" style={{ objectFit: 'cover' }} width="100px" height="100px" src={cell?.original?.image_file} />
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Id',
                                                        accessor: 'id',
                                                    },
                                                    {
                                                        Header: 'Name',
                                                        accessor: 'name',
                                                    },
                                                ]}
                                                data={row?.original?.areas || []}
                                            />
                                        )
                                    }
                                />
                            </CardGraph>
                        )}
                    </Styles.Wrapper>
                )}
            </Formik>
            <Styles.Footer style={{ paddingRight: '1rem', paddingTop: '1rem' }}>
                <Styles.LinkButton type="button" className="add" onClick={handleClose}>Cancelar</Styles.LinkButton>
                <Styles.Button disabled={loadingSave} className="confirm" type="button" onClick={() => handleSave()}>Salvar</Styles.Button>
            </Styles.Footer>
        </>
    );
};
export default ModalParameters;
