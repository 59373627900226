import React from 'react';
import * as Styles from './styles';

const Header = () => (
    <Styles.Container>
        <Styles.Logo>Smart Agri - Sistema Integrado de Manejo</Styles.Logo>
    </Styles.Container>
);

export default Header;
