import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import {
    InputLabel, MenuItem, FormControl, Select, TextField,
} from '@material-ui/core';

import * as Styles from './styles';
import { areaRequestCustomer, requestCalculateBh } from '../../../../store/modules/area/actions';

export const HeaderPlot = ({ changeStartValue, changeEndValue }) => {
    const dispatch = useDispatch();
    const { data: areas, loading } = useSelector((state) => state.area);
    const { data: user } = useSelector((state) => state.user);

    const [selectedArea, setSelectedArea] = useState(null);

    const [displayInitialValue, setDisplayInitialValue] = useState('');
    const [valueInitial, setValueInitial] = useState(new Date(new Date() - (7 * 24 * 60 * 60 * 1000))); // 7 days ago

    const [displayEndValue, setDisplayEndValue] = useState('');
    const [valueEnd, setValueEnd] = useState(new Date());

    const [area, setArea] = useState('');

    useEffect(() => {
        changeStartValue(new Date(new Date() - (7 * 24 * 60 * 60 * 1000)));
        changeEndValue(new Date());
    }, []);

    const labelLoading = loading ? 'Carregando' : 'Selecione uma área';

    useEffect(() => {
        if (!areas.length) {
            dispatch(areaRequestCustomer(user.id));
        }
    }, [dispatch, user.id]);

    useEffect(() => {
        if (selectedArea && valueInitial && valueEnd) {
            dispatch(requestCalculateBh(selectedArea?.id, valueInitial, valueEnd));
        }
    }, [valueInitial, selectedArea?.id, valueEnd]);

    const handleChangeCalendarInital = (date) => {
        const formattedDate = new Date((date.target.value).replace(/-/g, '/'));
        setDisplayInitialValue(formattedDate.toISOString().split('T')[0]);

        setValueInitial(formattedDate);
        changeStartValue(formattedDate);
    };

    const handleChangeCalendarEnd = (date) => {
        const formattedDate = new Date((date.target.value).replace(/-/g, '/'));
        setDisplayEndValue(formattedDate.toISOString().split('T')[0]);

        setValueEnd(formattedDate);
        changeEndValue(formattedDate);
    };

    const handleSelectedArea = async (event) => {
        const areaId = event.target.value;
        setArea(areaId);
        const filteredArea = areas.filter((area) => area.id === areaId)[0];
        await setSelectedArea(filteredArea);
        if (displayInitialValue.length === 0) {
            setDisplayInitialValue((new Date(new Date() - (7 * 24 * 60 * 60 * 1000))).toISOString().split('T')[0]);
            setDisplayEndValue(new Date().toISOString().split('T')[0]);
        }
    };

    return (
        <Styles.Container>
            <Styles.HeaderContent>
                <FormControl>
                    <InputLabel>Área:</InputLabel>
                    <Select
                        onChange={handleSelectedArea}
                        labelId="select-parameter"
                        value={area}
                        autoWidth
                    >
                        {<MenuItem style={{ fontSize: '1.4rem' }}>{labelLoading}</MenuItem>}
                        {areas.map((area) => <MenuItem key={area.id} value={area.id} style={{ fontSize: '1.4rem' }}>{area.name}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl>
                    <Styles.GroupCalendar>
                        <TextField
                            type="date"
                            label="Data Inicial:"
                            value={displayInitialValue}
                            onChange={handleChangeCalendarInital}
                        />
                    </Styles.GroupCalendar>
                </FormControl>

                <FormControl>
                    <Styles.GroupCalendar>
                        <TextField
                            type="date"
                            label="Data Final:"
                            value={displayEndValue}
                            onChange={handleChangeCalendarEnd}
                        />
                    </Styles.GroupCalendar>
                </FormControl>
            </Styles.HeaderContent>

            <Styles.Footer>
                <Styles.GroupData>
                    <span>Cultura: </span>
                    <span>{!selectedArea ? 'Selecione uma area' : selectedArea?.area_crops?.crop?.culture_name}</span>
                </Styles.GroupData>

                <Styles.GroupData>
                    <span>Data de plantio: </span>
                    <span>{!selectedArea ? 'Selecione uma area' : format(new Date(selectedArea?.area_crops?.sowing_date), 'dd/MM/yyyy')}</span>

                </Styles.GroupData>

                <Styles.GroupData>
                    <span>Inicio do monitoramento: </span>
                    <span>{!selectedArea ? 'Selecione uma area' : format(new Date(selectedArea?.created_at), 'dd/MM/yyyy')}</span>

                </Styles.GroupData>
            </Styles.Footer>

        </Styles.Container>
    );
};
