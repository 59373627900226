import styled, { css } from 'styled-components';
import soil from '../../assets/images/soil.png';

export const ImageBackground = styled.div`
    ${({ notFound, width, isCard }) => css`
        width: ${!isCard && width ? `${width}px` : 'auto'};
        height:${notFound ? '20rem' : 'auto'};
        background:url(${soil}) repeat-x;
        background-size:contain;
    `}
`;

export const WrapperLegend = styled.div`
    ${({ theme }) => css`
        display:flex;
        flex-direction:row;
        justify-content:center;
        margin-top:${theme.spacings.small};

        div {
            margin-right:${theme.spacings.xsmall};
        }
    `}
`;

export const NotFound = styled.div`
    ${({ theme }) => css`
        background:${theme.colors.white};
        border-radius:1rem;
        box-shadow:2px 2px 3px rgba(0,0,0,0.1);
        padding:${theme.spacings.xxsmall};
        position:relative;
        top:10rem;
        left:.5rem;
        width:50rem;
        margin:0 auto;
        h2{
            color:${theme.colors.gray};
            font-size:${theme.font.sizes.xsmall};
            text-align:center;
        }
    `}
`;

export const GraphWrapper = styled.div`
    ${({ isCard }) => css`
        width: 100%;
        height: auto;
        overflow-x: ${isCard ? 'hidden' : 'scroll'};
        overflow-y: hidden;
    `}
`;
