import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    width: 100%;
`;

export const Container = styled.div`
    ${({ theme }) => css`

        display:flex;
        justify-content:center;

        span {
            max-width: 1280px;
            width: calc(100% - 2rem);
            margin-top: 22px;
            span {
                width: 100%;
            }
        }

        label {
            display:flex;
            justify-content:center;
            font-size:${theme.font.sizes.xsmall};
            margin-bottom:${theme.spacings.small};
            color:${theme.colors.black};
            font-weight:bold;
            text-align:center;
        }
    `}
`;
