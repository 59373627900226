/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';
import * as AREA from '../../helpers/area';
import { lastDays } from '../../helpers/dateUtils';

export const COLOR_INFO = '#444';
export const COLOR_EXTRA = '#62EDFF';
export const COLOR_IDEAL = '#55F15C';
export const COLOR_ATENCAO = '#F7CA18';
export const COLOR_DEFICIT = '#F44336';

export const calculateAreaSize = (areas) => {
    const totalSize = areas.reduce((total, currentValue) => total + Number(currentValue.size), 0);
    return `≅ ${(totalSize * 100).toFixed(2).replace('.', ',')} ha`;
};

export const areaDeficitAmount = (areas) => {
    let deficit = 0;
    let extra = 0;
    let ideal = 0;
    let attention = 0;
    for (const area of areas) {
        if (area.bh_today) {
            const { water_deficit } = area.bh_today;
            switch (water_deficit) {
            case 'attention':
                attention++;
                break;

            case 'ideal':
                ideal++;
                break;

            case 'extra':
                extra++;
                break;

            case 'deficit':
                deficit++;
                break;

            default:
                break;
            }
        }
    }
    return {
        extra,
        ideal,
        attention,
        deficit,
    };
};

const formatTempAverage = ({ tempAverage, _createdAt }) => ({
    tempAverage: parseFloat(tempAverage),
    date: moment.utc(_createdAt).format('DD/MM'),
});

const formatCadRoot = (bh, sowingDate) => {
    let blue;
    let white;

    const date = moment.utc(sowingDate);
    date.add(bh?.sowingdays, 'days');

    if (bh?.ATD !== null) {
        blue = bh?.ATD;
        white = bh?.cadRoot - bh?.ATD;
    }

    const cadRoot = parseFloat(bh?.cadRoot);

    blue = typeof blue !== 'number' || Number.isNaN(blue) ? 0 : blue;
    white = typeof white !== 'number' || Number.isNaN(white) ? 0 : white;

    let percentageBlue = Math.floor((blue * parseFloat(100)) / cadRoot);
    percentageBlue = percentageBlue < 15 ? 15 : percentageBlue;

    let percentageWhite = Math.floor((white * parseFloat(100)) / cadRoot);
    const total = percentageBlue + percentageWhite;

    if (total < 100 || total > 100) {
        percentageWhite += (100 - total);
    }

    percentageBlue = typeof percentageBlue !== 'number' || Number.isNaN(percentageBlue) ? 20 : percentageBlue;
    percentageWhite = typeof percentageWhite !== 'number' || Number.isNaN(percentageWhite) ? 60 : percentageWhite;

    return {
        blue,
        white,
        percentageBlue,
        percentageWhite,
        cadRoot: !Number.isNaN(cadRoot) ? cadRoot : 0,
        date: date.format('DD/MM').toString(),
        irrigate: bh?.complementarWater?.toFixed(0)?.replace('.', ','),
        percentimeter: bh?.percentimeter?.toFixed(0)?.replace('.', ','),
        atd_percentage: (bh?.atd_percentage)?.toFixed(0),
    };
};

const formattedBh = (bh, sowingDate) => {
    const date = moment.utc(sowingDate);
    date.add(bh?.sowingdays, 'days');

    return {
        ...bh,
        date: date.format('DD/MM').toString(),
    };
};

const formatIrrigation = ({ irrigation, rain, date }) => {
    const dateFormat = moment.utc(date);
    return {
        irrigation,
        rain,
        date: dateFormat.format('DD/MM').toString(),
    };
};

export const getLastsCadRoot = (bhs, sowingDate, limit = 5) => {
    const lastDates = lastDays(limit, true);
    const date = moment.utc(sowingDate);

    if (limit === 1) {
        const yesterday = moment().endOf('days');
        const formattedCadRoot = [];
        formattedCadRoot.push(formatCadRoot(bhs[0], yesterday));
        return formattedCadRoot;
    }

    if (!bhs?.length) {
        return {
            blue: 0,
            yellow: 0,
            white: 0,
            percentageBlue: (25),
            percentageYellow: (25),
            percentageWhite: (25),
            cadRoot: 0,
            date: date.format('DD/MM').toString(),
            percentimeter: 0,
            irrigation: 0,
            cad: 0,
        };
    }

    const formattedCadRootList = _.map(bhs || [], (item) => formatCadRoot(item, sowingDate));

    const finalList = lastDates.map((date) => {
        const findDate = formattedCadRootList.find((x) => x.date === date);

        if (findDate) {
            return findDate;
        }

        return {
            blue: 0,
            yellow: 0,
            white: 0,
            percentageBlue: (20),
            percentageYellow: (20),
            percentageWhite: (60),
            cadRoot: 0,
            date,
        };
    });

    return finalList;
};

export const getMetCondition = ({
    etc, irrigation, effectiveRain, sowingdays,
}, sowingDate) => {
    const date = moment.utc(sowingDate);
    date.add(sowingdays || 0, 'days');
    return {
        etc: etc || 0.0,
        irrigation: irrigation || 0.0,
        effectiveRain: effectiveRain || 0.0,
        date: date.format('DD/MM'),
    };
};

export const getLastBhMetConditions = (bhs, sowingDate, limit = 30) => {
    const lastDates = lastDays(limit, true);
    const formattedBhList = (bhs || []).filter((item) => item?.sowingdays).map((item) => getMetCondition(item, sowingDate));

    const finalList = lastDates.map((date) => {
        const findDate = formattedBhList.find((x) => x.date === date);
        if (findDate) {
            return findDate;
        }
        return {
            etc: 0.0,
            irrigation: 0.0,
            effectiveRain: 0.0,
            date,
        };
    });
    return finalList;
};

export const getLastTempAverages = (data, limit = 30) => {
    const lastDates = lastDays(limit);
    const formattedDataList = _.map(data || [], (item) => formatTempAverage(item));

    const finalList = lastDates.map((date) => {
        const findDate = formattedDataList.find((x) => x.date === date);
        if (findDate) {
            return findDate;
        }
        return {
            tempAverage: 0.0,
            date,
        };
    });

    return finalList;
};

export const getLastBh = (bhs, sowingDate) => {
    const lastDates = lastDays(1, true);
    const formattedDataList = _.map(bhs || [], (item) => formattedBh(item, sowingDate));
    const finalList = lastDates.map((date) => {
        const findDate = formattedDataList.find((x) => x.date === date);
        if (findDate) {
            return findDate;
        }
        return undefined;
    });
    return finalList && finalList.length > 0 ? finalList[0] : undefined;
};

export const getLastIrrigations = (irrigations) => {
    const lastDates = lastDays(30, true);
    const formattedIrrigation = irrigations?.map((item) => formatIrrigation(item));
    const finalList = lastDates.map((date) => {
        const findDate = formattedIrrigation?.find((x) => x.date === date);
        if (findDate) {
            return findDate;
        }
        return {
            irrigation: 0,
            rain: 0,
            date,
        };
    });
    return finalList;
};

export const getLastComputedIrrigationToLabel = (bh) => {
    if ((bh?.length >= 2 && bh[1]?.complementarWater) || bh != null) {
        const last = bh.length ? bh[1]?.complementarWater : bh;
        return {
            irrigation: last.toFixed(3),
            _createdAt: new Date(last?._createdAt).toLocaleDateString('pt-BR', { dateStyle: 'short' }),
        };
    }

    return {
        irrigation: 0,
        _createdAt: new Date(),
    };
};

export const getCultureIcon = (type) => {
    const annual = AREA.cultures.annual.find(
        (culture) => culture.type === type,
    );
    const perennial = AREA.cultures.perennial.find(
        (culture) => culture.type === type,
    );

    return annual || perennial;
};

export const getSoilIcon = (type) => AREA.soil.find((soil) => soil.type === type);

export const getTimeToIrrigate = (bh) => bh[1]?.irrigation_time;

export const getCropId = (tab, type) => {
    let find;
    if (tab === 'Anual') {
        find = AREA.annual.find((culture) => culture.type === type);
    } else {
        find = AREA.perennial.find((culture) => culture.type === type);
    }

    return Number(find?.id);
};

export const getIrrigationTypeId = (value) => {
    const find = AREA.irrigationType.find((irrigation) => irrigation.type === value);

    if (find) return Number(find?.id);

    return null;
};

export const getSoilTypeId = (value) => {
    const find = AREA.soil.find((soil) => soil.type === value);
    return Number(find?.id);
};

export const irrigationCondition = (waterDeficit) => {
    switch (waterDeficit) {
    case 'extra':
        return {
            bgColor: COLOR_EXTRA,
            label: 'Extra',
            type: 'extra',
            percent: 100,
            color: 'black',
        };

    case 'ideal':
        return {
            bgColor: COLOR_IDEAL,
            label: 'Ideal',
            type: 'ideal',
            percent: 75,
            color: 'black',
        };

    case 'attention':
        return {
            bgColor: COLOR_ATENCAO,
            label: 'Atenção',
            type: 'atencao',
            percent: 50,
            color: 'black',
        };

    case 'deficit':
        return {
            bgColor: COLOR_DEFICIT,
            label: 'Déficit',
            type: 'deficit',
            percent: 25,
            color: 'white',
        };

    default:
        return {
            bgColor: COLOR_INFO,
            label: 'Sem Info',
            type: 'info',
            percent: 0,
            color: 'white',
        };
    }
};
