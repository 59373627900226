import client from '../../../graphql/client';
import LIST_PARAMETERS from '../../../graphql/queries/parameters/listParameters';

import { handleSuccess, handleError } from '../../../utils/functions';

export async function requestParameters() {
    try {
        const data = await client.request(LIST_PARAMETERS);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}
