import React, { useState } from 'react';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

import * as Styles from './styles';

import CardGraph from '../../CardGraph';
import Selector from '../../Selector';

import * as AREA from '../../../utils/helpers/area';
import * as MASK from '../../../utils/helpers/mask';

import iconSprout from '../../../assets/svgs/icon_sprout.svg';

const Cultivate = ({ form }) => {
    const [, setCultureType] = useState('');
    const today = new Date();
    const maxDate = moment(today).toISOString().split('T')[0];
    const classes = Styles.useStyles();

    const sortCulturesByLang = (cultures) => cultures.map((culture) => ({
        ...culture,
        translation: culture.label,
    })).sort((a, b) => {
        if (a.translation < b.translation) { return -1; }
        if (a.translation > b.translation) { return 1; }
        return 0;
    });

    const [culture, setCultures] = useState({
        cultures: sortCulturesByLang(AREA.cultures[form.values.culture === 'Anual' ? 'annual' : 'perennial']),
    });

    const handlePeriod = (period) => {
        form.setFieldValue('culture', period);
        setCultures({
            cultures: sortCulturesByLang(AREA.cultures[period === 'Anual' ? 'annual' : 'perennial']),
        });

        if (period === 'Perene') {
            form.setFieldValue('cycleDays', '360');
        } else {
            form.setFieldValue('cycleDays', null);
            form.setFieldValue('street', null);
            form.setFieldValue('age', null);
            form.setFieldValue('population', null);
            if (form.values.irrigationType === 'Gotejamento') {
                form.setFieldValue('irrigationType', '');
                form.setFieldValue('drippingSpacing', null);
                form.setFieldValue('drippingFlow', null);
                form.setFieldValue('pas', null);
                form.setFieldValue('pam', null);
                form.setFieldValue('kl', null);
                form.setFieldValue('leaf', null);
            } else if (form.values.irrigationType === 'Pivô central (LEPA)') {
                form.setFieldValue('irrigationType', '');
                form.setFieldValue('pas', null);
                form.setFieldValue('pam', null);
                form.setFieldValue('kl', null);
                form.setFieldValue('leaf', null);
            }
        }
        setCultureType('');
        form.setFieldValue('cultureType', '');
    };

    const handleCultureType = (value, isAvailable) => {
        if (isAvailable) {
            form.setFieldValue('cultureType', value);
            setCultureType(value);
        }
    };

    const calculatePopulation = () => {
        if (!form.values.spacing || !form.values.street) {
            form.values.population = '';
            return form.values.population;
        }
        const result = Math.round(10000 / parseFloat(form.values.spacing.toString().replace(',', '.'))
            / parseFloat(form.values.street.toString().replace(',', '.')) * 100) / 100;
        if (Number.isFinite(result)) {
            form.values.population = result.toString();
            return form.values.population;
        }
        form.values.population = '';
        return form.values.population;
    };

    return (
        <CardGraph
            title="Cultivo"
            svgPath={iconSprout}
        >
            <Styles.Wrapper>
                <Styles.Menu>
                    <li className={form.values.culture === 'Anual' ? 'active' : ''} onClick={() => handlePeriod('Anual')}>Anual</li>
                    <li className={form.values.culture === 'Perene' ? 'active' : ''} onClick={() => handlePeriod('Perene')}>Perene</li>
                </Styles.Menu>

                <Styles.SelectCultivation>
                    <span>Selecione o cultivo</span>
                    <Styles.Options>
                        {
                            culture.cultures.map((item, index) => {
                                const isAvailable = (item.id === '14' || item.id === '2' || item.id === '3');
                                return (
                                    <Selector key={index} on={item.type === form.values.cultureType && (isAvailable)} onClick={() => handleCultureType(item.type, isAvailable)}>
                                        {isAvailable
                                            ? (
                                                <>
                                                    <img src={item.icon} alt={item.label} />
                                                </>
                                            ) : (
                                                <>
                                                    <Tooltip
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        placement="top"
                                                        title="Cultivo não disponível no momento"
                                                        arrow
                                                        classes={{
                                                            tooltip: classes.customTooltip,
                                                            arrow: classes.customArrow,
                                                        }}
                                                    >
                                                        <img
                                                            src={item.icon}
                                                            alt={item.label}
                                                            style={{ opacity: 0.2 }}
                                                        />
                                                    </Tooltip>
                                                </>
                                            )}
                                        <span style={{ opacity: (isAvailable) ? 1 : 0.2 }}>{item.label}</span>
                                    </Selector>
                                );
                            })
                        }
                    </Styles.Options>
                </Styles.SelectCultivation>

                <Styles.WrapperInput>
                    <Grid container spacing={2}>
                        {form.values.culture === 'Anual'
                        && (
                            <>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="cycleDays"
                                        label="Ciclo de dias"
                                        onBlur={(e) => {
                                            form.setFieldTouched('cycleDays', true);
                                            if (Number.isFinite(parseInt(e.target.value))) form.setFieldValue('cycleDays', parseInt(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskCycleDays,
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="spacing"
                                        label="Espaçamento na linha"
                                        onBlur={(e) => {
                                            form.setFieldTouched('spacing', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('spacing', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskSpacing,
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="population"
                                        label="População"
                                        onBlur={(e) => {
                                            form.setFieldTouched('population', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('population', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskPopulation,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        type="date"
                                        name="sowingDate"
                                        label="Data de plantio"
                                        max={maxDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                            </>
                        )}

                        {form.values.culture === 'Perene'
                        && (
                            <>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="age"
                                        label="Idade (meses)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('age', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('age', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskAge,
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="spacing"
                                        label="Espaçamento na linha"
                                        onBlur={(e) => {
                                            form.setFieldTouched('spacing', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('spacing', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskSpacing,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="street"
                                        label="Espaçamento entre ruas"
                                        onBlur={(e) => {
                                            form.setFieldTouched('street', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('street', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskSpacing,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        value={calculatePopulation() ? `${calculatePopulation()} plantas/ha` : form.values.population}
                                        name="population"
                                        label="População"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        type="date"
                                        name="sowingDate"
                                        label="Data da última colheita"
                                        max={maxDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Styles.WrapperInput>
            </Styles.Wrapper>
        </CardGraph>
    );
};

export default Cultivate;
