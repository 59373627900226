const GET_AREA_PARAMETERS = `
query GET_AREA_PARAMETERS($id:Int){
  areaParameters(filters: {area_id: $id}) {
    id
    area {
      id
      name
    }
    parameter {
      id
      name
      key
    }
    value
    start_date
    end_date
  }
}
`;

export default GET_AREA_PARAMETERS;
