import React from 'react';
import { Formik, Field, Form } from 'formik';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import * as Yup from 'yup';
import {
    one_second, textToSeconds, excelToSeconds, secondsToExcel,
} from '../../utils/excelTime';

import { FormContainer, Errors, TimeContainer } from './styles';

export default ({
    handleOnSubmit, handleOnCancel, type, rele, time_to_one_hundred,
}) => {
    const time = time_to_one_hundred * one_second; // seconds to excel time

    const today = new Date();
    const maxDate = moment(today).add(7, 'days').toISOString().split('T')[0]; // até 1 semana à frente

    const numberMask = createNumberMask({
        prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' mm', thousandsSeparatorSymbol: '',
    });

    const percentMask = createNumberMask({
        prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' %', thousandsSeparatorSymbol: '',
    });

    const validationSchemaFields = Yup.object().shape({
        mm: Yup.string().nullable().required('Campo obrigatório')
            .test(
                'interval',
                `Somente números entre 0 e ${type === 'Chuva' ? 150 : 100} são permitidos`,
                (val) => parseFloat(val) >= 0 && parseFloat(val) <= (type === 'Chuva' ? 150 : 100),
            ),
        date: Yup.string().required('Campo obrigatório'),
        hour: Yup.number().min(0, 'somente numeros positivos'),
        minute: Yup.number().min(0, 'somente numeros positivos').max(59, 'não podemos informar um valor maior que 59 minutos'),
    });

    const handleChangeMM = (e, setFieldValue) => {
        // mm
        const value = parseFloat(e.target.value || 0);
        setFieldValue('mm', `${e.target.value} mm`);

        // rele
        const releValue = (!value || value === 0) ? value : parseFloat(rele * 100 / value).toFixed(2);
        setFieldValue('rele', `${releValue} %`);

        // time
        const excelSeconds = rele === 0 ? 0 : value * time / rele;
        const [hours, minutes] = excelToSeconds(excelSeconds);

        setFieldValue('hour', hours);
        setFieldValue('minute', minutes);
    };

    const handleChangeRele = (e, setFieldValue) => {
        // rele
        const value = parseFloat(e.target.value || 0);
        setFieldValue('rele', e.target.value);

        // mm
        const mmValue = (!value || value === 0) ? value : parseFloat(rele / value * 100).toFixed(3);
        setFieldValue('mm', mmValue);

        // time
        const excelSeconds = value === 0 ? 0 : time / (value / 100);

        const [hours, minutes] = excelToSeconds(excelSeconds);

        setFieldValue('hour', hours);
        setFieldValue('minute', minutes);
    };

    const handleChangeTime = (e, values, setFieldValue) => {
        const { value, name } = e.target;
        const timeHash = { hour: values.hour || 0, minute: values.minute || 0, second: values.second || 0 };
        timeHash[name] = value;

        const excelTime = secondsToExcel(textToSeconds(timeHash.hour, timeHash.minute, timeHash.second));

        // time
        setFieldValue(name, value);

        // mm
        const mm = time === 0 ? 0 : (rele * excelTime) / time;
        setFieldValue('mm', mm.toFixed(3));

        // rele
        const releValue = excelTime === 0 ? 0 : (time * 100) / excelTime;

        setFieldValue('rele', `${releValue.toFixed(2)} %`);
    };

    return (
        <FormContainer>
            <Formik
                initialValues={{ mm: '', date: '', rele: '' }}
                onSubmit={handleOnSubmit}
                validationSchema={validationSchemaFields}
            >
                {({
                    errors, touched, handleChange, setFieldValue, values,
                }) => (
                    <Form>
                        <MaskedInput name="mm" id="input" onChange={(e) => handleChangeMM(e, setFieldValue)} value={values.mm} mask={numberMask} placeholder={`${type} (mm)`} />
                        {errors.mm && touched.mm
                            ? <Errors>{errors.mm}</Errors>
                            : null}

                        {rele && type === 'Irrigação' && <MaskedInput name="rele" id="input" style={{ marginBottom: '2rem' }} onChange={(e) => handleChangeRele(e, setFieldValue)} value={values.rele} mask={percentMask} placeholder="Relé (%)" />}
                        {errors.rele && touched.rele && type === 'Irrigação'
                            ? <Errors>{errors.rele}</Errors>
                            : null}

                        {rele && time_to_one_hundred && type === 'Irrigação' && (
                            <>
                                <p>Tempo de Volta (h)</p>
                                <TimeContainer>
                                    <div>
                                        <Field style={{ marginTop: '0' }} placeholder="hora" type="number" name="hour" onChange={(e) => handleChangeTime(e, values, setFieldValue)} min={0} />
                                        {errors.hour && touched.hour
                                            ? <Errors>{errors.hour}</Errors>
                                            : null}
                                    </div>
                                    <div>
                                        <Field style={{ marginTop: '0' }} placeholder="minuto" type="number" name="minute" onChange={(e) => handleChangeTime(e, values, setFieldValue)} min={0} />
                                        {errors.minute && touched.minute
                                            ? <Errors>{errors.minute}</Errors>
                                            : null}
                                    </div>
                                </TimeContainer>
                            </>
                        )}

                        <Field id="datepicker" type="date" name="date" onChange={handleChange} max={maxDate} />
                        {errors.date && touched.date
                            ? <Errors>{errors.date}</Errors>
                            : <Errors />}
                        <div className="buttons-container">
                            <button className="cancel" type="button" onClick={handleOnCancel}>Cancelar</button>
                            <button className="confirm" type="submit">Confirmar</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
};
