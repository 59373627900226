export function parametersRequest() {
    return {
        type: '@parameters/PARAMETERS_LIST_REQUEST',
    };
}

export function parametersSuccess(data) {
    return {
        type: '@parameters/PARAMETERS_LIST_SUCCESS',
        payload: { data },
    };
}

export function parametersError(message) {
    return {
        type: '@parameters/PARAMETERS_LIST_ERROR',
        payload: {
            message,
        },
    };
}
