import React, { useState, useEffect, useMemo } from 'react';
import {
    VictoryBar,
    VictoryLine,
    VictoryChart,
    VictoryGroup,
    VictoryAxis,
    VictoryLabel,
} from 'victory';
import _ from 'lodash';
import * as Styles from './styles';
import { lastDays } from '../../../utils/helpers/dateUtils';
import ButtonChart from '../Button';
import LegendChart, { Bullet } from '../Legend';
import { WrapperLegend } from '../../StackChart/styles';

const moment = require('moment');

const CombinedChart = ({
    bh, irrigations, datesHashmap, area_irrigation_system_efficiency,
}) => {
    const [sevenDaysActive, setSevenDaysActive] = useState(true);
    const [fifteenDaysActive, setFifteenDaysActive] = useState(false);
    const [thirtyDaysActive, setThirtyDaysActive] = useState(false);

    const [etc, setEtc] = useState(null);
    const [sumEtc, setSumEtc] = useState(0);

    const [effectiveRain, setEffectiveRain] = useState(null);
    const [sumAccumulatedRain, setSumAccumulatedRain] = useState(0);

    const [sumEffectiveRain, setSumEffectiveRain] = useState(0);

    const [irrigation, setIrrigation] = useState(null);
    const [sumAccumulatedIrrigation, setSumAccumulatedIrrigation] = useState(0);

    const [sumEffectiveIrrigation, setSumEffectiveIrrigation] = useState(0);

    const [dates, setDates] = useState(null);

    const [numberDays, setNumberDays] = useState(10);

    useEffect(() => {
        const irrigationsLimited = _.takeRight(irrigations, numberDays);
        const bhLimited = _.takeRight(bh, numberDays);

        const newEtc = _.map(bhLimited, ({ date, etc }) => ({
            x: date,
            y: parseFloat(etc),
        }));

        const newEffectiveRain = _.map(irrigationsLimited, ({ date, rain }) => ({
            x: date,
            y: parseFloat(rain),
        }));

        setEtc(newEtc);

        setSumEtc(
            newEtc
                .reduce((acumulator, actual) => acumulator + actual.y, 0)
                .toFixed(1),
        );

        const sumAccumulatedRain = newEffectiveRain
            .reduce((acumulator, actual) => acumulator + actual.y, 0.0);

        setEffectiveRain(newEffectiveRain);
        setSumAccumulatedRain(sumAccumulatedRain.toFixed(1));

        const finalEffectiveRain = sumAccumulatedRain * area_irrigation_system_efficiency;

        setSumEffectiveRain(finalEffectiveRain.toFixed(1));

        const newIrrigation = _.map(irrigationsLimited, ({ date, irrigation }) => ({
            x: date,
            y: parseFloat(irrigation),
        }));

        const sumAccumulatedIrrigation = newIrrigation
            .reduce((acumulator, actual) => acumulator + actual.y, 0.0);

        setIrrigation(newIrrigation);
        setSumAccumulatedIrrigation(sumAccumulatedIrrigation.toFixed(1));

        const finalEffectiveIrrigation = sumAccumulatedIrrigation * area_irrigation_system_efficiency;

        setSumEffectiveIrrigation(finalEffectiveIrrigation.toFixed(1));

        const newDates = lastDays(numberDays, true);

        setDates(newDates);
    }, [numberDays, bh, irrigations]);

    const handleDaysSelected = (daysSelected) => {
        switch (daysSelected) {
        case 10:
            setSevenDaysActive(true);
            setFifteenDaysActive(false);
            setThirtyDaysActive(false);
            break;
        case 15:
            setSevenDaysActive(false);
            setFifteenDaysActive(true);
            setThirtyDaysActive(false);
            break;
        case 30:
            setSevenDaysActive(false);
            setFifteenDaysActive(false);
            setThirtyDaysActive(true);
            break;
        default:
            break;
        }

        setNumberDays(daysSelected);
    };

    const victoryAxisStyle = { tickLabels: { fontSize: 12 }, axisLabel: { padding: 34, fontSize: 14 } };

    const memoBarWidth = useMemo(() => (numberDays !== 30 ? 10 : 4), [numberDays]);
    const memoDomainPaddingBars = useMemo(() => (numberDays !== 30 ? 11 : 5), [numberDays]);
    const memoOffsetBars = useMemo(() => (numberDays !== 30 ? -4 : -2), [numberDays]);
    const memoDomainPaddingDates = useMemo(() => (numberDays !== 30 ? 11 : 5), [numberDays]);
    const memoMaxDomain = useMemo(() => {
        if (effectiveRain && irrigation && etc) {
            const hasEffectiveRain = effectiveRain.filter((value) => value.y !== 0).length === 0;
            const hasIrrigation = irrigation.filter((value) => value.y !== 0).length === 0;
            const hasETC = etc.filter((value) => value.y !== 0).length === 0;
            const showLabel = !(hasEffectiveRain && hasIrrigation && hasETC);
            if (showLabel) return {};
        }
        return { y: 1 };
    }, [effectiveRain, irrigation, etc]);

    return (
        <Styles.Container>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ButtonChart
                    active={sevenDaysActive}
                    title="10 dias"
                    setActive={() => handleDaysSelected(10)}
                />
                <ButtonChart
                    active={fifteenDaysActive}
                    title="15 dias"
                    setActive={() => handleDaysSelected(15)}
                />
                <ButtonChart
                    active={thirtyDaysActive}
                    title="30 dias"
                    setActive={() => handleDaysSelected(30)}
                />
            </div>
            <VictoryChart
                maxDomain={memoMaxDomain}
            >
                <VictoryAxis
                    label="Irrigação (mm)"
                    dependentAxis
                    orientation="left"
                    style={victoryAxisStyle}
                />

                <VictoryAxis
                    label="ETc (mm) e Tar (°C)"
                    dependentAxis
                    orientation="right"
                    style={victoryAxisStyle}
                />

                <VictoryGroup offset={memoOffsetBars} domainPadding={memoDomainPaddingBars}>
                    <VictoryBar
                        style={{ data: { fill: '#6395D6' } }}
                        alignment="middle"
                        barWidth={memoBarWidth}
                        data={effectiveRain}
                    />
                    <VictoryBar
                        style={{ data: { fill: '#99F3F1' } }}
                        alignment="middle"
                        barWidth={memoBarWidth}
                        data={irrigation}
                    />
                </VictoryGroup>

                <VictoryGroup>
                    <VictoryLine
                        style={{ data: { stroke: '#26D61A' } }}
                        data={etc}
                    />
                </VictoryGroup>

                <VictoryGroup domainPadding={memoDomainPaddingDates}>
                    <VictoryAxis
                        tickValues={dates}
                        tickLabelComponent={(
                            <VictoryLabel
                                angle={90}
                                textAnchor="start"
                                dy={-8}
                                style={{
                                    fill: ({ text }) => {
                                        const color = {
                                            today: '#45A04B',
                                            old: 'black',
                                            future: '#4B9DCE',
                                        };
                                        const today = moment().startOf('day');
                                        const tickDate = moment(datesHashmap[text], 'DD/MM/YYYY');
                                        const diff = today.diff(tickDate, 'days');
                                        let currentColor = color.old;

                                        if (diff === 0) {
                                            currentColor = color.today;
                                        } else if (diff >= 1) {
                                            currentColor = color.old;
                                        } else {
                                            currentColor = color.future;
                                        }

                                        return (currentColor);
                                    },
                                }}
                            />
                        )}
                        tickFormat={
                            (_, y) => {
                                if (dates && dates[y]) {
                                    if (dates.length <= 15) return dates[y];
                                    if (y % 2 !== 0) return dates[y];
                                }
                                return '';
                            }
                        }
                    />
                </VictoryGroup>
            </VictoryChart>

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto auto',
                    gridTemplateRows: 'auto auto',
                    gap: '10px',
                    marginTop: '1.8rem',
                }}
            >
                <LegendChart
                    color="#26D61A"
                    title={`ETc acumulada - ${sumEtc}mm`.replace('.', ',')}
                />
                <LegendChart
                    color="#6395D6"
                    title={`Chuva Acumulada - ${String(sumAccumulatedRain).replace('.', ',')}mm`}
                />

                <LegendChart
                    color="#99F3F1"
                    title={`Irrigação Acumulada - ${String(sumAccumulatedIrrigation).replace('.', ',')}mm`}
                />
                <div />
                <LegendChart
                    color="#6395D6"
                    title={`Chuva Efetiva - ${String(sumEffectiveRain).replace('.', ',')}mm`}
                />
                <LegendChart
                    color="#99F3F1"
                    title={`Irrigação Efetiva - ${String(sumEffectiveIrrigation).replace('.', ',')}mm`}
                />

            </div>

            <WrapperLegend>
                <Bullet rounded color="black">Histórico</Bullet>
                <Bullet rounded color="#45A04B">Hoje</Bullet>
                <Bullet rounded color="#4B9DCE">Previsão</Bullet>
            </WrapperLegend>
        </Styles.Container>
    );
};

export default CombinedChart;
