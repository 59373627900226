import { FRAGMENT_GET_AREA } from '../fragments/area';

const GET_AREA_WITH_PAGINATION = /* GraphQL */ `
query GET_FILTERED_AREA($id:ID, $filters:UserFilterInput){
    users(id:$id, filters:$filters)
    {
        areas {
            ${FRAGMENT_GET_AREA}
        }
    }
}
`;

export default GET_AREA_WITH_PAGINATION;
