import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import 'chart.js/auto';
import {
    format, intervalToDuration, addDays, getDaysInMonth,
} from 'date-fns';
import moment from 'moment-timezone';
import { Line, Bar } from 'react-chartjs-2';
import { RiFullscreenLine } from 'react-icons/ri';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { HeaderPlot } from '../PlotTemplate/components/HeaderPlot';
import 'moment/locale/pt-br';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import * as Styles from './styles';

const TableReport = () => {
    const { dataGraph } = useSelector((state) => state.area);
    const [widthGraph, setWidthGraph] = useState();
    const [heightGraph, setHeightGraph] = useState();

    const { width } = useWindowDimensions();

    const [valueStart, setValueStart] = useState('');
    const [valueEnd, setValueEnd] = useState('');
    const [arrayIntervalDates, setArrayIntervalDates] = useState([]);

    const [dataEtC, setDataEtC] = useState([]);
    const [sumEtC, setSumEtC] = useState(0);
    const [sumAccumulatedRain, setSumAccumulatedRain] = useState(0);
    const [sumEffectiveRain, setSumEffectiveRain] = useState(0);
    const [sumAccumulatedIrrigation, setSumAccumulatedIrrigation] = useState(0);
    const [sumEffectiveIrrigation, setSumEffectiveIrrigation] = useState(0);

    const [soilHumidity, setSoilHumidity] = useState([]);

    const [dataTemp, setDataTemp] = useState([]);

    const [dataIrrigation, setDataIrrigation] = useState([]);
    const [dataRain, setDataRain] = useState([]);
    const containerRef = useRef();

    const lineChartFullScreen = useFullScreenHandle();
    const barChartFullScreen = useFullScreenHandle();

    useEffect(() => {
        if (containerRef.current) {
            setWidthGraph(
                containerRef?.current?.offsetWidth > containerRef?.current?.offsetHeight
                    ? (containerRef?.current?.offsetWidth > 1280 ? 1280 : containerRef?.current?.offsetWidth)
                    : containerRef?.current?.offsetHeight,
            );
            setHeightGraph(
                containerRef?.current?.offsetWidth > containerRef?.current?.offsetHeight
                    ? ((containerRef?.current?.offsetWidth / 2) > 700 ? 700 : containerRef?.current?.offsetWidth / 2)
                    : (containerRef?.current?.offsetWidth > 600 ? 500 : containerRef?.current?.offsetWidth),
            );
        }
    }, [width]);

    const formatedArrayIntervalDates = (start, end) => {
        const monthOfDays = getDaysInMonth(end);
        const duration = intervalToDuration({ start, end });
        const dates = [];

        if (duration.months > 0) {
            duration.days = monthOfDays * duration.months + duration.days;
        }

        if (duration.years > 0) {
            duration.days = 371 * duration.years + duration.days;
        }

        for (let d = 0; d < duration.days; d++) {
            const date = addDays(start, d);
            dates.push(date);
        }
        return dates;
    };

    useEffect(() => {
        if (valueStart && valueEnd) {
            const arrayOfDates = formatedArrayIntervalDates(valueStart, valueEnd);
            if (arrayOfDates.length) {
                setArrayIntervalDates(
                    arrayOfDates.map((date) => format(date, 'dd/MM/yyyy')),
                );
            }
        }
    }, [valueStart, valueEnd]);

    useEffect(() => {
        if (arrayIntervalDates.length && dataGraph) {
            // Array com datas e valores
            const dataBhWithinInterval = arrayIntervalDates.map(
                (item) => dataGraph.bh.filter((bh) => {
                    // Formatar o date do Bh igual o date de intervalos
                    const dateFormated = moment
                        .tz(bh._date, 'YYYY-M-D', 'America/Sao_Paulo')
                        .format('DD/MM/YYYY');

                    // Se um intervalo for igual ao valor de dateFormated
                    if (dateFormated === item) {
                        return {
                            ...item,
                        };
                    }
                })[0] ?? null,
            );

            // Array com datas e valores
            if (dataGraph.weather.length) {
                const dataWeatherWithinInterval = arrayIntervalDates.map(
                    (item) => dataGraph.weather.filter((weather) => {
                        const dateFormated = moment
                            .tz(weather.date, 'YYYY-M-D', 'America/Sao_Paulo')
                            .format('DD/MM/YYYY');
                        if (dateFormated === item) {
                            return {
                                ...item,
                            };
                        }
                    })[0] ?? [],
                );
                setDataTemp(dataWeatherWithinInterval);
            }

            setDataEtC(dataBhWithinInterval?.map((bh) => bh?.etc) ?? 0);
            setSoilHumidity(
                dataBhWithinInterval.map((bh) => (bh?.ATD / bh?.cadRoot) * 100) ?? 0,
            );

            setDataIrrigation(dataBhWithinInterval.map((bh) => bh?.irrigation ?? 0));
            setDataRain(dataBhWithinInterval.map((bh) => bh?.effectiveRain ?? 0));

            const sumEtcAndRainAndIrrigationFormated = dataBhWithinInterval.map(
                (item) => {
                    if (!item) {
                        return {
                            etc: 0,
                            irrigation: 0,
                            effectiveRain: 0,
                        };
                    }
                    return {
                        ...item,
                    };
                },
            );

            setSumEtC(
                sumEtcAndRainAndIrrigationFormated
                    .reduce((acumulator, actual) => acumulator + actual?.etc, 0)
                    .toFixed(1),
            );

            const totalAccumulatedRain = sumEtcAndRainAndIrrigationFormated.reduce(
                (acumulator, actual) => acumulator + actual?.effectiveRain,
                0,
            );

            const totalEffectiveRain = totalAccumulatedRain * dataGraph.area_irrigation_system.efficiency;

            setSumAccumulatedRain(totalAccumulatedRain.toFixed(1));
            setSumEffectiveRain(totalEffectiveRain.toFixed(1));

            const totalAccumulatedIrrigation = sumEtcAndRainAndIrrigationFormated.reduce(
                (acumulator, actual) => acumulator + actual?.irrigation,
                0,
            );

            const totalEffectiveIrrigation = totalAccumulatedIrrigation
                * dataGraph.area_irrigation_system.efficiency;

            setSumAccumulatedIrrigation(totalAccumulatedIrrigation.toFixed(1));
            setSumEffectiveIrrigation(totalEffectiveIrrigation.toFixed(1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGraph, arrayIntervalDates]);

    const lineChart = {
        labels: arrayIntervalDates,
        datasets: [
            {
                label: `Etc acumulado ${sumEtC}mm`,
                data: dataEtC.map((item) => {
                    if (item > 10) {
                        return 10;
                    }
                    return item;
                }),
                borderColor: 'green',
                pointRadius: 6,
                pointHoverRadius: 12,
                yAxisID: 'y-axis-2',
            },
            {
                label: 'Umidade do solo',
                data: soilHumidity.map((item) => {
                    if (item > 100) {
                        return 100;
                    }
                    return item;
                }),
                borderColor: 'black',
                pointRadius: 6,
                pointHoverRadius: 12,
            },
            {
                label: 'Tmax',
                data: dataTemp.length
                    ? dataTemp.map((temp) => temp.temp_max)
                    : arrayIntervalDates.map(() => 0),
                borderColor: 'red',
                pointRadius: 6,
                pointHoverRadius: 12,
            },
            {
                label: 'Tmin',
                data: dataTemp.length
                    ? dataTemp.map((temp) => temp.temp_min)
                    : arrayIntervalDates.map(() => 0),
                borderColor: 'orange',
                pointRadius: 6,
                pointHoverRadius: 12,
            },
        ],
    };

    const options = {
        scales: {
            'y-axis-1': {
                type: 'linear',
                display: true,
                position: 'left',
            },
            'y-axis-2': {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    const barChart = {
        labels: arrayIntervalDates,
        datasets: [
            {
                label: `Irrigação Acumulada - ${sumAccumulatedIrrigation}mm & Irrigação Efetiva - ${sumEffectiveIrrigation}mm`,
                data: dataIrrigation.map((item) => {
                    if (item > 20) return 20;
                    return item;
                }),
                backgroundColor: 'rgb(48, 149, 227)',
            },
            {
                label: `Chuva Acumulada - ${sumAccumulatedRain}mm & Chuva Efetiva - ${sumEffectiveRain}mm`,
                data: dataRain.map((item) => {
                    if (item > 125) return 125;
                    return item;
                }),
                backgroundColor: 'rgb(8, 1, 110)',
                yAxisID: 'y-axis-2',
            },
        ],
    };

    const toggleFullScreen = (handler) => {
        switch (handler) {
        case 'lineChart': if (!lineChartFullScreen.active) {
            lineChartFullScreen.enter();
        } else {
            lineChartFullScreen.exit();
        }
            break;
        case 'barChart': if (!barChartFullScreen.active) {
            barChartFullScreen.enter();
        } else {
            barChartFullScreen.exit();
        }
            break;
        default:
        }
    };

    return (
        <Styles.Container ref={containerRef} width={widthGraph} height={heightGraph}>
            <HeaderPlot
                changeStartValue={setValueStart}
                changeEndValue={setValueEnd}
            />

            <FullScreen handle={lineChartFullScreen}>
                <Styles.Wrapper fullScreen={lineChartFullScreen.active}>
                    <Styles.TableOverflow fullScreen={lineChartFullScreen.active}>
                        {dataGraph
                        && (
                            <Styles.TableContainer fullScreen={lineChartFullScreen.active} width={widthGraph} height={heightGraph}>
                                <Line width={widthGraph} height={heightGraph} data={lineChart} options={options} />
                            </Styles.TableContainer>
                        )}
                    </Styles.TableOverflow>
                    {dataGraph && (
                        <RiFullscreenLine onClick={() => toggleFullScreen('lineChart')} />
                    )}
                </Styles.Wrapper>
            </FullScreen>

            <FullScreen handle={barChartFullScreen}>
                <Styles.Wrapper fullScreen={barChartFullScreen.active}>
                    <Styles.TableOverflow fullScreen={barChartFullScreen.active}>
                        {dataGraph
                        && (
                            <Styles.TableContainer fullScreen={barChartFullScreen.active} width={widthGraph} height={heightGraph}>
                                <Bar width={widthGraph} height={heightGraph} data={barChart} options={options} />
                            </Styles.TableContainer>
                        )}
                    </Styles.TableOverflow>
                    {dataGraph && <RiFullscreenLine onClick={() => toggleFullScreen('barChart')} />}
                </Styles.Wrapper>
            </FullScreen>
        </Styles.Container>
    );
};

export default TableReport;
