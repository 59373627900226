import { differenceInCalendarDays } from 'date-fns';
import {
    ATD, ATDCalculate, ATDFixed, ATDPercentage, ATDPercentageCalculate, ATDPercentageFixed, Add, AddMax, CadDif, Balance, CAD, CadRoot, ETR, ETc, EffectiveIrrigation, EffectiveIrrigationMilimiter, EffectiveIrrigationPercentage, EffectiveRain, EtrAdj,
    Kc, Kred, MeteoBlueFromDay, RootDepth, containsDefaultParameter, dayWater, defaultParameterOrCalculation, defaultParameterOrEnvVariable,
} from './calculationFunctions';

export const bhGenerator = (
    area,
    day_to_calculate,
    last_bh,
    default_area_parameters,
) => {
    const {
        area_crop: area_crops, soil, weather, waters, area_irrigation_system,
    } = area;
    const {
        efficiency,
        leaf,
    } = area_irrigation_system;

    const { sowing_date } = area_crops;
    const {
        tetaCC: teta_cc,
        tetaPMP: teta_pmp,
        soil_density,
        soil_humidity,
    } = soil;

    const water = dayWater(day_to_calculate, waters);
    const { rain, day_irrigation } = water;

    const soil_moisture_change = containsDefaultParameter(
        default_area_parameters,
        day_to_calculate,
        'SOIL_MOISTURE_CHANGE',
    );
    const meteo_blue = MeteoBlueFromDay(day_to_calculate, weather);
    const { eto_pm } = meteo_blue;
    const sowing_days = differenceInCalendarDays(
        day_to_calculate, new Date(sowing_date),
    );
    const kc = defaultParameterOrCalculation(
        default_area_parameters,
        day_to_calculate,
        'KC',
        Kc,
        { area_crops, sowing_days },
    );
    const root_depth = defaultParameterOrCalculation(
        default_area_parameters,
        day_to_calculate,
        'ROOT_DEPTH',
        RootDepth,
        { area_crops, sowing_days },
    );
    const irrigation_percentimeter = 0;
    const efficiency_irrigation_system = containsDefaultParameter(
        default_area_parameters,
        day_to_calculate,
        'IRRIGATION_SYSTEM_EFFICIENCY',
    ) || efficiency;
    const adjustment_coefficient = defaultParameterOrEnvVariable(
        default_area_parameters,
        day_to_calculate,
        'ADJUSTMENT_COEFFICIENT',
    );
    const effective_irrigation_milimiter = EffectiveIrrigationMilimiter(
        day_irrigation,
        efficiency_irrigation_system,
    );
    const effective_irrigation_percentage = EffectiveIrrigationPercentage(
        irrigation_percentimeter,
        leaf,
        efficiency_irrigation_system,
    );
    const effective_irrigation = EffectiveIrrigation(
        day_irrigation,
        effective_irrigation_milimiter,
        effective_irrigation_percentage,
    );
    const eto_predicted = parseFloat(3);
    const etc = ETc(eto_pm, kc, eto_predicted);

    const cad = CAD(teta_cc, teta_pmp, soil_density);
    const cad_root = CadRoot(cad, root_depth);

    const atd_calculate = ATDCalculate(cad_root, soil_humidity, last_bh);
    const atd_fixed = ATDFixed(cad_root, soil_moisture_change);
    const atd_percentage_calculate = ATDPercentageCalculate(
        cad_root,
        atd_calculate,
    );
    const atd_percentage_fixed = ATDPercentageFixed(soil_moisture_change);
    const atd_percentage = ATDPercentage(
        atd_percentage_calculate,
        atd_percentage_fixed,
    );
    const atd = ATD(atd_calculate, atd_fixed);
    const add_max = AddMax(cad_root);
    const cad_dif = CadDif(cad_root, atd_calculate);
    const add = Add(add_max, atd);
    const kred = Kred(add, add_max);

    const etr = ETR(etc, kred);

    const etr_adj = EtrAdj(etr, adjustment_coefficient);
    const effective_rain = EffectiveRain(rain);

    const balance = Balance(effective_rain, effective_irrigation, etr_adj);

    return {
        atd,
        atd_percentage,
        balance,
        cad,
        cad_dif,
        cad_root,
        etr,
        kc,
        sowing_days,
    };
};
