import { Field } from 'formik';
import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${() => css`
    `}
`;

export const HeaderContent = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:75%;
        margin-bottom:${theme.spacings.small};

        .MuiFormControl-root {
            min-width: 200px;
            width: 100%;
            font-size: 1.4rem;
            margin-right: 1rem;

            label {
                font-size: 1.4rem;
            }

            .MuiInputBase-root {
                color: #00586B;
            }

            .MuiSelect-root {
                font-size: 1.4rem;
                min-height: 16px;
            }
        }

        @media(max-width: 767px) {
            flex-wrap: wrap;
            gap: 1rem;
            width: 100%;

            .MuiFormControl-root {
                min-width: unset;
                width: 100%;
            }
        }

        @media(max-width: 600px) {
            flex-wrap: wrap;
            gap: 1rem;
        }
    `}
`;

export const GroupCalendar = styled.div`
        ${() => css`
        position: relative;
        width:100%;

        .MuiInputBase-root {
            width: 100%;
        }

        input {
            font-size: 1.4rem;
            display: flex;
            justify-content: right;
        }
    `}
`;

export const Footer = styled.div`
    ${() => css`
        display:flex;
        align-items: center;
        justify-content: space-between;
        width:75%;

        div {
            width: 100%;
            min-width: 200px;
            margin-right: 1rem;
        }

        @media(max-width: 767px) {
            flex-wrap: wrap;
            gap: 1rem;
            width: 100%;

            div {
                min-width: unset;
                width: 100%;
            }
        }
    `}
`;
export const GroupData = styled.div`
    ${({ theme }) => css`
        color:${theme.colors.black};
        font-size:1.4rem;

        span {
            &:first-child {
                font-weight:bold;
            }
        }
    `}
`;

export const TextField = styled(Field)`
        width: 97.5%;
        font-size: 1.5rem !important;
`;
