import React from 'react';
import * as Styles from './styles';

const Footer = () => (
    <Styles.Container>
        <Styles.Menus>
            <Styles.Menu copyright>© 2023, by Smart Agri - Sistema Integrado de Manejo</Styles.Menu>
        </Styles.Menus>
    </Styles.Container>
);

export default Footer;
