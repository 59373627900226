import { gql } from 'graphql-request';
import { FRAGMENT_GET_AREA } from '../../fragments/area';

const AREA_ADD = gql`
    mutation areaAdd(
        $name: String!
        $farm_id: ID
        $soil: SoilInput
        $area_irrigation_system: AreaIrrigationSystemInput
        $area_crops: AreaCropsInput
        $kl: Float
        $ks: Float
        $pas: Float
        $pam: Float
        $boundary: [CoordinateInput]!
        $size: Float
        $time_back_to_one_hundred_percent: Float){
        areaAdd(
            name: $name
            farm_id: $farm_id
            soil: $soil
            area_irrigation_system: $area_irrigation_system
            area_crops: $area_crops
            kl: $kl
            ks: $ks
            pas: $pas
            pam: $pam
            boundary: $boundary
            size: $size
            time_back_to_one_hundred_percent: $time_back_to_one_hundred_percent
        ){
            ${FRAGMENT_GET_AREA}

        }
    }
`;

export default AREA_ADD;
