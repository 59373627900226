import produce from 'immer';
import {
    areaDeficitAmount,
    calculateAreaSize,
} from '../../../utils/functions/area';

const INITIAL_STATE = {
    data: [],
    dataMap: [],
    customers: [],
    dataCrops: [],
    dataArea: null,
    dataGraph: null,
    message: '',
    error: false,
    filter: 'all',
    loading: false,
    loadingMap: false,
    loadingCrops: false,
    loadingGraph: false,
    loadingArea: false,
    loadingAreaBh: false,
    loadingAverage: false,
    loadingAdd: false,
    areaSummary: {
        loadingSummary: false,
        areaAmount: 0,
        deficit: 0,
        extra: 0,
        ideal: 0,
        attention: 0,
        totalAreaSize: 0,
    },
    pagedArea: {
        loadingArea: false,
        hasMorePage: true,
        currentPage: 1,
        areas: [],
    },
    pagedAreaWithFilter: {
        loadingFirstPage: false,
        loadingArea: false,
        hasMorePage: true,
        currentPage: 1,
        currentFilter: {},
        currentOrdination: {},
        areas: [],
    },
    area_system_efficiency: null,
    pagedBHReportsArea: {
        loadingBHData: false,
        hasMorePage: true,
        bh: [],
        water: [],
    },
};

export default function area(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@area/AREA_CONSULTANT_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
            draft.loadingArea = true;
        });
    case '@area/AREA_CUSTOMER_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
            draft.loadingArea = true;
        });
    case '@area/AREA_SUMMARY_BY_USER_ID':
        return produce(state, (draft) => {
            draft.areaSummary.loadingSummary = true;
        });
    case '@area/SUCCESS_AREA_SUMMARY_BY_USER_ID':
        return produce(state, (draft) => {
            draft.areaSummary.loadingSummary = false;
            const { areas } = action.payload;
            draft.areaSummary.areaAmount = areas.length;
            draft.areaSummary.totalAreaSize = calculateAreaSize(areas);
            const areaDeficit = areaDeficitAmount(areas);
            draft.areaSummary.deficit = areaDeficit.deficit;
            draft.areaSummary.attention = areaDeficit.attention;
            draft.areaSummary.ideal = areaDeficit.ideal;
            draft.areaSummary.extra = areaDeficit.extra;
        });
    case '@area/PAGED_AREA_BY_USER_REQUEST':
        return produce(state, (draft) => {
            draft.pagedArea.loadingArea = true;
        });
    case '@area/PAGED_AREA_WITH_FILTER_BY_USER_REQUEST':
        return produce(state, (draft) => {
            if (action.payload.newFilter) {
                draft.pagedAreaWithFilter.loadingFirstPage = true;
            }
            draft.pagedAreaWithFilter.loadingArea = true;
        });
    case '@area/PAGED_AREA_WITH_FILTER_BY_USER_SUCCESS':
        return produce(state, (draft) => {
            draft.pagedAreaWithFilter.loadingArea = false;
            const {
                dataArea, newFilter, filter, ordination,
            } = action.payload;

            // reset if have new filters
            if (newFilter) {
                draft.pagedAreaWithFilter.areas = [];
                const reset_to_initial_page = 1;
                draft.pagedAreaWithFilter.currentPage = reset_to_initial_page;
                draft.pagedAreaWithFilter.currentFilter = filter;
                draft.pagedAreaWithFilter.currentOrdination = ordination;
                draft.pagedAreaWithFilter.areas = dataArea;
                draft.pagedAreaWithFilter.hasMorePage = true;
                draft.pagedAreaWithFilter.loadingFirstPage = false;
                return;
            }

            if (dataArea.length) {
                draft.pagedAreaWithFilter.currentPage = state.pagedAreaWithFilter.currentPage + 1;
                draft.pagedAreaWithFilter.areas = state.pagedAreaWithFilter.areas.concat(dataArea);
            } else {
                draft.pagedAreaWithFilter.hasMorePage = false;
            }
        });
    case '@area/PAGED_AREA_BY_USER_SUCCESS':
        return produce(state, (draft) => {
            draft.pagedArea.loadingArea = false;
            const { dataArea } = action.payload;
            if (dataArea.length) {
                draft.pagedArea.currentPage = state.pagedArea.currentPage + 1;
                draft.pagedArea.areas = state.pagedArea.areas.concat(dataArea);
            } else {
                draft.pagedArea.hasMorePage = false;
            }
        });
    case '@area/AREA_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.message = '';
            draft.loadingArea = false;
            draft.data = action.payload.data.areas;
            draft.customers = action.payload.data.customers;
        });
    case '@area/AREA_ADD':
        return produce(state, (draft) => {
            draft.loadingAdd = true;
        });
    case '@area/AREA_ADD_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingAdd = false;
            draft.data = [...state.data, action.payload.areaAdd];
        });
    case '@area/AREA_UPDATE':
        return produce(state, (draft) => {
            draft.loadingAdd = true;
        });
    case '@area/AREA_UPDATE_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingAdd = false;
        });

    case '@area/AREA_REMOVE_BY_ID_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
            const { id } = action.payload;
            const filterData = state.data.filter((area) => area.id !== id);
            draft.data = filterData;
        });
    case '@area/AREA_ARCHIVED_BY_ID_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
            const { id } = action.payload;
            const data = state.data.map((area) => {
                const _area = { ...area };
                if (area.id === id) _area.active = false;
                return _area;
            });
            draft.data = data;
        });
    case '@area/AREA_BY_ID_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingArea = false;
            draft.dataArea = action.payload.area;
            draft.error = false;
        });

    case '@area/AREA_BY_ID_NDVI_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingArea = false;
            draft.dataArea = { ...state.dataArea, ndvi: action.payload.ndvi };
            draft.error = false;
        });

    case '@area/AREA_BY_ID_BH_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingArea = false;
            draft.loadingAreaBh = false;
            draft.dataArea = {
                ...state.dataArea,
                bh: action.payload.dataBh.bh,
                bh_today: action.payload.dataBh.bh_today,
            };
        });
    case '@area/AREA_BY_ID_AVERAGE_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingAverage = false;
            draft.dataArea = {
                ...state.dataArea,
                average_temp: action.payload.averageTemp,
                average_temp_since: action.payload.averageTempSince,
            };
        });
    case '@area/AREA_REFRESH_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingArea = false;
            draft.dataArea = { ...state.dataArea, ...action.payload.area };
        });
    case '@area/CLEAR_DATA_AREA':
        return produce(state, (draft) => {
            draft.dataArea = null;
            draft.error = false;
        });
    case '@area/AREAS_FILTER':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.filter = action.payload.filter;
        });
    case '@area/AREA_START_REQUEST_BY_ID':
        return produce(state, (draft) => {
            draft.error = false;
            draft.loadingArea = true;
            draft.loadingAreaBh = true;
            draft.loadingAverage = true;
            draft.dataArea = state.dataArea;
        });
    case '@area/AREA_BY_ID_END_REQUEST':
        return produce(state, (draft) => {
            draft.loadingArea = false;
        });
    case '@area/AREA_START_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
            draft.loadingArea = true;
            draft.dataArea = state.dataArea;
            draft.data = [];
        });
    case '@area/AREA_START_REQUEST_MAP':
        return produce(state, (draft) => {
            draft.loadingMap = true;
            draft.data = [];
        });

    case '@area/AREAS_MAP_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingMap = false;
            draft.dataMap = action.payload.data.areas;
        });

    case '@area/AREA_END_REQUEST':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.loadingArea = false;
        });
    case '@area/AREA_END_REQUEST_MAP':
        return produce(state, (draft) => {
            draft.loadingMap = false;
            draft.data = [];
        });
    case '@area/AREA_ERROR':
        return produce(state, (draft) => {
            draft.loadingArea = false;
            draft.loadingAdd = false;
            draft.dataArea = null;
            draft.error = true;
            draft.message = action.payload.message;
        });

    case '@area/AREA_IRRIGATION_MESSAGE':
        return produce(state, (draft) => {
            draft.message = action.payload.message.message;
            draft.loadingAdd = action.payload.message.loadingAdd;
            draft.loading = action.payload.message.loading;
        });

    case '@area/REQUEST_CALCULATE_ABH':
        return produce(state, (draft) => {
            draft.dataGraph = null;
            draft.loadingGraph = true;
        });

    case '@area/REQUEST_CALCULATE_ABH_SUCCESS':
        return produce(state, (draft) => {
            draft.dataGraph = action.payload.data;
            draft.loadingGraph = false;
        });

    case '@area/REQUEST_CALCULATE_ABH_ERROR':
        return produce(state, (draft) => {
            draft.dataGraph = null;
            draft.loadingGraph = false;
            draft.message = action.payload.message;
        });

    case '@area/AREA_CROPS_START_REQUEST':
        return produce(state, (draft) => {
            draft.loadingCrops = true;
        });

    case '@area/AREA_CROPS_REQUEST_SUCCESS':
        return produce(state, (draft) => {
            draft.loadingCrops = false;
            draft.dataCrops = action.payload.data;
        });

    case '@area/AREA_CROPS_REQUEST_ERROR':
        return produce(state, (draft) => {
            draft.loadingCrops = false;
        });

    case '@area/AREA_CROPS_END_REQUEST':
        return produce(state, (draft) => {
            draft.loadingCrops = false;
        });
    case '@area/SUCCESS_CUSTOMER_BY_USER':
        return produce(state, (draft) => {
            const { customers } = action.payload;
            draft.customers = customers;
        });

    case '@area/AREA_BH_REPORTS_BY_ID_SUCCESS':
        return produce(state, (draft) => {
            const { BHData } = action.payload;

            draft.pagedBHReportsArea.loadingBHData = false;

            if (BHData.bh.length) {
                draft.pagedBHReportsArea.bh = state.pagedBHReportsArea.bh.concat(BHData.bh);
                draft.pagedBHReportsArea.water = state.pagedBHReportsArea.water.concat(BHData.water);
            } else {
                draft.pagedBHReportsArea.hasMorePage = false;
            }
        });

    case '@area/SET_PAGED_BH_REPORTS_AREA_STATES':
        return produce(state, (draft) => {
            draft.pagedBHReportsArea.hasMorePage = true;
            draft.pagedBHReportsArea.bh = [];
            draft.pagedBHReportsArea.water = [];
        });

    case '@area/SET_LOADING_BH_DATA':
        return produce(state, (draft) => {
            draft.pagedBHReportsArea.loadingBHData = action.payload;
        });

    default:
        return state;
    }
}
